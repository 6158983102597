{
	"1": "1",
	"2": "2",
	"3": "3",
	"404": "Error - 404",
	"blockchain": "Blockchain",
	"login": "Login",
	"logout": "Logout",
	"loading": "Loading",
	"redirecting_login": "Redirecting to login page",
	"product_label_version": "Fabric Operations Console version",
	"product_label": "Fabric Operations Console",
	"fabric": "Fabric",
	"peers": "Peers",
	"add_peer": "Add peer",
	"list_view": "List View",
	"grid_view": "Grid View",
	"name": "Display name",
	"location": "Location",
	"status": "Status",
	"running": "Running",
	"running_partial": "Your ordering service may be down",
	"running_partial_desc": "The console has lost contact with one or more of the nodes in this ordering service. This does not necessarily mean that the node is down, but it is often the reason. Check the “ordering nodes” tab to make sure the console is in contact with a majority of nodes in the ordering service. Note that if there are nodes in the ordering service that have not been imported into this console, you should do so now.",
	"initialized": "Initialized",
	"ready": "Ready",
	"containersready": "Containers ready",
	"podscheduled": "Pod scheduled",
	"readOnlyTxt": "read only",
	"stopped": "Stopped",
	"unknown": "Status unknown",
	"status_undetected": "Status undetectable",
	"items": "Items",
	"page_number": "Page number, of {{n}} pages",
	"prev_pg": "Previous page",
	"next_pg": "Next page",
	"channels": "Channels",
	"chaincode": "Smart contracts",
	"version": "Version",
	"install_chaincode": "Install smart contract",
	"instantiate_not_allowed_20": "Instantiating a legacy smart contract is not allowed on a channel with application capability 2.x. Go to the \"Channels\" page to use the 2.0 Lifecycle",
	"peer_details_title": "{{peerName}}",
	"orderer_details_title": "{{ordererName}}",
	"ca_details_title": "{{caName}}",
	"storage": "Storage",
	"last_transaction": "Last transaction",
	"start_peer": "Start peer",
	"stop_peer": "Stop peer",
	"used_of_total": "{{used}} of {{total}}",
	"open_cc_profile": "Open connection profile",
	"id": "ID",
	"created": "Created",
	"created_on": "Created {{date}}",
	"system_channel": "System Channel",
	"unjoin_channel_title": "Unjoin orderer from channel",
	"join_osn_title": "Join another orderer to channel",
	"channel_info_title": "View channel info",
	"block_height": "Block height",
	"join_channel": "Join channel",
	"import_peer_config": "Add peer",
	"import_peer_desc_1": "Peers host smart contracts and store your ledger. They allow your organization to transact on the network. Each organization that joins a consortium should deploy at least one peer.",
	"import_peer_desc_2": "Use this panel to add a peer to the console by creating a new peer or by importing a peer you have already deployed. Note: The peer's organization should have its own CA and you will need to use the certificates from that CA to create an MSP definition for the peer's organization.",
	"upload_peer_config": "Upload Peer Configuration",
	"upload_peer_config_info": "only JSON files",
	"add_files": "Add files",
	"add_file": "Add file",
	"clear_file": "Clear",
	"cancel": "Cancel",
	"submit": "Submit",
	"remove_peer": "Remove peer",
	"delete_peer": "Delete peer",
	"remove_msp": "Remove MSP",
	"remove_org": "Remove organization",
	"remove_adminmsp_from_orderer": "Remove MSP admin from ordering service",
	"remove_msp_from_consortium": "Remove MSP from consortium",
	"export_node_config": "Export",
	"actions": "Actions",
	"nodes": "Nodes",
	"nodes_lc": "nodes",
	"node_lc": "node",
	"view_nodes": "View Nodes",
	"view_channels": "View Channels",
	"view_chaincode": "View Smart Contracts",
	"orderers": "Ordering services",
	"orderer_nodes": "Ordering Nodes",
	"remove_orderer": "Remove ordering service",
	"delete_orderer": "Delete ordering service",
	"add_orderer": "Add ordering service",
	"ca": "Certificate Authority",
	"cas": "Certificate Authorities",
	"ca_abbrev": "CA",
	"cas_abbrev": "CA's",
	"remove_ca": "Remove Certificate Authority",
	"delete_ca": "Delete Certificate Authority",
	"add_ca": "Add Certificate Authority",
	"import_orderer_config": "Import ordering service configuration",
	"import_orderer_desc": "Use this panel to import an existing ordering service from another console.",
	"upload_orderer_config": "Upload ordering service configuration",
	"upload_orderer_config_info": "only JSON files",
	"import_ca_config": "Import Certificate Authority Configuration",
	"import_ca_desc": "A  Certificate Authority (CA) creates identities for the nodes that belong to your organization and identities for its admins. Use the CA to create identities and an MSP definition of your organization before you deploy peers and ordering services.",
	"upload_ca_config": "Upload Certificate Authority Configuration",
	"upload_ca_config_info": "only JSON files",
	"welcome_to_optools": "Welcome to the Fabric Operations Console Setup",
	"authorization_url_label": "Authorization URL",
	"authorization_url_placeholder": "Authorization URL here",
	"authorization_url_tooltip": "The authorization url is where the console will redirect a user for login",
	"token_url_label": "Token URL",
	"token_url_placeholder": "Token URL here",
	"token_url_tooltip": "The token url is used by the console to exchange authorization codes for authorization tokens",
	"client_id_label": "Client ID",
	"client_id_placeholder": "Enter client ID here",
	"client_id_tooltip": "The client id helps authorize the console with the OAuth service",
	"client_secret_label": "Client secret",
	"client_secret_placeholder": "Enter client secret here",
	"client_secret_tooltip": "The client secret helps authorize the console with the OAuth service",
	"scope_label": "Scope",
	"scope_placeholder": "Enter scope here",
	"scope_tooltip": "The scope defines what is returned during the OAuth2.0 communication. It is highly recommended to use a value of 'openid email profile'.",
	"grant_label": "Grant",
	"grant_tooltip": "The only supported grant is \"authorization_code\".",
	"response_type_label": "Response type",
	"response_type_tooltip": "The only supported response type is \"code\".",
	"msp_admin_type_tooltip": "These are identities in your wallet that are also admins on this channel",
	"msp_admin_type_label": "Known channel admins",
	"debug_label": "User login debug logs",
	"debug_logs_tooltip": "Extra console server logs during user login can help debug OAuth2.0 setup issues, but for maximum user privacy this setting should be off once login problems are resolved.",
	"default_password_placeholder": "Enter a password here",
	"default_password_tooltip": "Share this password with each new user so that they can login to the console. They will be required to change it the first time they log in.",
	"default_password_label": "Default password for new users",
	"confirm_default_password_label": "Confirm default password for new users",
	"allow_default_keep_label": "Allow users to keep the default password",
	"allow_default_keep_tooltip": "Allowing users to keep using the default password is helpful when you are setting up and tearing down test environments. It is not recommended for any other purpose.",
	"oauth_desc_txt": "The OAuth2.0 authentication method requires an external OAuth2 service. Users can be managed (such as adding & removing) by contacting your administrator of the OAuth2 service. User roles (permissions) can be managed by using the \"Access\" tab of your console and the \"Users\" table.",
	"couchdb_desc_txt": "The CouchDB authentication method will securely store usernames and passwords in the console's CouchDB database. Users can be managed (such as adding & removing) by using the \"Access\" tab of your console and the \"Users\" table.",
	"oauth_warning_txt": "This authentication method requires the following settings. These settings can normally be obtained from a dashboard specific to your OAuth2.0 service. For more help [[see the docs]](https://github.com/hyperledger-labs/fabric-operations-console/blob/main/packages/athena/docs/_oauth.md).",
	"couchdb_warning_txt": "This authentication method requires the following settings.",
	"callback_warning_title": "Register the callback URL",
	"callback_warning_desc": "It is typical for OAuth services to require registration of callback URLs. If this applies to your service you will need to register the callback url below. Contact your OAuth administrator for assistance.\n\n Callback URL: {{CALLBACK}}",
	"oauth_warning_title": "Supported services",
	"oauth_warning_desc": "The console only supports OAuth2.0 using a grant of \"authorization_code\" and a response type of \"code\". OAuth services that do not allow this configuration will not work. Contact your OAuth administrator for assistance.",
	"oauth_user_warning_title": "Check username!",
	"oauth_user_warning_desc": "[Important] When you login with your OAuth2.0 service the console will use the email address that is associated with your oauth service login, as the username. Therefore make sure that this username (email) is already listed in the \"Users\" table AND that it has the \"Manager\" role.",
	"oauth_server_url": "OAuth Server URL",
	"oauth_server_url_placeholder": "Enter OAuth Server URL here",
	"secret": "Secret",
	"secret_placeholder": "Enter secret here",
	"tenant_id": "Tenant ID",
	"tenant_id_placeholder": "Enter tenant Id here",
	"admin_user_list": "List of Admin Users who can access the application",
	"non_admin_user_list": "List of Non-Admin Users who can access the application",
	"members": "Members",
	"users": "Users",
	"access": "Access",
	"access_title": "Access",
	"user_info": "User information",
	"add_member": "Add member",
	"add_user": "Add user",
	"type": "Type",
	"register_user_type_tooltip": "Use the peer identity type when registering the identity of a peer and orderer when registering an ordering node. Use the admin identity type when registering an admin. Use client if you are registering an application.",
	"affiliation": "Affiliation",
	"register_user_affiliation_tooltip": "(Advanced option) Affiliations provide a way to associate an identity with an organizational unit (OU) hierarchy. If no affiliation is specified during registration, the identity is assigned the same affiliation as the identity used to register this user.",
	"generate_cert": "Enroll identity",
	"user_selection_desc": "Use a registered user to generate a certificate and private key by selecting an enroll id and providing the enroll secret for that user.",
	"generate_cert_desc": "These keys were generated for your registered user. You must <strong>save them</strong> them by exporting them to your local file system and adding them to your Wallet. They are not stored by the console or in your cluster.",
	"add_identity_to_wallet": "Add identity to Wallet",
	"certificate": "Certificate",
	"private_key": "Private key",
	"private_key_desc": "It is important to put your private key in a safe place. Do not share it openly.",
	"close": "Close",
	"smart_contracts": "Smart contracts",
	"consortia": "Consortia",
	"applications": "Applications",
	"settings": "Settings",
	"enroll_id": "Enroll ID",
	"enroll_id_placeholder": "Enter an ID",
	"peer_enroll_id_placeholder": "Enter an ID",
	"peer_enroll_secret_placeholder": "Enter a secret",
	"enroll_secret": "Enroll secret",
	"enroll_secret_placeholder": "Enter a secret",
	"affiliation_placeholder": "Enter an affiliation",
	"max_enrollments": "Maximum enrollments",
	"max_enrollments_placeholder": "Optional",
	"rest_api_error_not_completed_title": "Request could not be completed",
	"rest_api_error_not_completed_details": "The {{method}} request to {{url}} could not be completed.  Try again.",
	"rest_api_error_invalid_response_title": "Request succeeded with invalid response",
	"rest_api_error_invalid_response_details": "The {{method}} request to {{url}} completed successfully, but the response from the server could not be understood.",
	"rest_api_error_error_in_response_title": "Request failed",
	"rest_api_error_error_in_response_details": "The {{method}} request to {{url}} failed.",
	"client": "Client",
	"peer": "Peer",
	"no_json_file": "Don't have a JSON file?",
	"json_entry": "Return to JSON entry",
	"msp_id": "MSP ID",
	"msp_id_placeholder": "Enter the MSP ID",
	"name_placeholder": "Enter a name",
	"private_key_placeholder": "Upload a file that contains the private key (in base64 or PEM format)",
	"certificate_placeholder": "Enter the certificate",
	"url": "URL",
	"url_placeholder": "Enter the URL",
	"input_error_invalid_url": "The value is not a valid address.",
	"input_error_invalid_email": "The value is not a valid email address.",
	"input_error_required": "A value is required.",
	"input_error_invalid": "The value is not valid.",
	"input_error_invalid_pkcs11proxy": "HSM proxy endpoint should begin with tcp://. ",
	"ca_name": "Certificate Authority name",
	"ca_url": "Certificate Authority endpoint URL",
	"ca_name_placeholder": "Enter the Certificate Authority name",
	"peer_display_name_placeholder": "Enter the Peer name",
	"ca_url_placeholder": "For ex, https://example.blockchain.ibm.com:7051/",
	"error_peer_not_found": "The peer '{{peerId}}' was not found.",
	"error_orderer_not_found": "The ordering service '{{ordererId}}' was not found.",
	"error_ca_not_found": "The Certificate Authority '{{caId}}' was not found.",
	"error_peer_channels": "Unable to retrieve channel information from <strong>{{peerName}}</strong>.",
	"error_peer_channels_access": "Invalid certificate.",
	"error_peers": "Unable to retrieve the list of peers.",
	"error_chaincode": "Unable to retrieve the smart contracts.",
	"error_chaincode2": "The identity associated with selected certificate is not an admin cert on peer <strong>{{peerName}}</strong>.  Associate an identity with <strong>{{peerName}}</strong> that has admin privileges",
	"error_cas": "Unable to retrieve the list of Certificate Authorities.",
	"error_orderers": "Unable to retrieve the list of ordering services.",
	"error_ca_users": "Unable to retrieve the list of users.",
	"error_start_peer": "Unable to start the peer.",
	"error_stop_peer": "Unable to stop the peer.",
	"empty_ca_message": "Get started by creating or importing a CA.",
	"empty_orderer_message": "Get started by creating or importing an ordering service.",
	"empty_peer_message": "Get started by creating or importing a peer.",
	"empty_peer_channels_message": "It looks like your peer has not joined any channels yet. \nClick Join channel to add your peer to an existing channel.",
	"empty_msp_message": "No MSPs added.",
	"join_requires_orderer": "An ordering service is required to join a channel.",
	"join_requires_peer": "A peer is required to join a channel",
	"join_channel_desc": "Each transaction is executed on a channel, where each party must be authenticated and authorized to transact on that channel.",
	"orderer": "Ordering service",
	"orderer_joined": "Already Joined Orderer",
	"channel": "Channel",
	"channel_placeholder": "Enter the channel name",
	"editing_auth_message": "Edit your Auth settings",
	"copy_json_placeholder": "Paste the JSON information from your App ID service here.",
	"error_join_failed": "{{peerName}} could not join the channel.",
	"error_join_failed1": "{{peerName}} could not join the channel. The selected certificate is not a peer admin certificate.",
	"error_join_failed_no_response": "{{peerName}} did not respond to the join channel request.",
	"error_generate_cert": "The certificate could not be generated.",
	"error_ca_add_user": "The user could not be added.",
	"error_import_orderer": "The ordering service could not be imported.",
	"error_json_file": "The specified JSON file is not valid",
	"channel_members": "Channel members",
	"orderer_msps": "Orderer members",
	"channel_acls": "Access control list",
	"channel_anchor_peers": "Anchor peers",
	"org": "Organization",
	"orgs": "Organizations",
	"resource": "Resource",
	"policy_ref": "Policy Definition",
	"peer_url": "Peer",
	"peer_name": "Name",
	"channel_details_title": "View channel activity and configurations.",
	"error_getting_channel_details": "An error occurred while getting the channel details.",
	"error_getting_peer_details": "An error occurred while getting the peer details.",
	"cannot_access_channel_details": "Cannot access channel details as you are not member of this channel.",
	"error_channel_details_not_found": "Channel details not found",
	"no_acl_for_channel": "No access control list found for this channel.",
	"no_anchor_for_channel": "No anchor peers found for this channel.",
	"request_access_header": "403 - Request access",
	"request_access_button": "Request access",
	"request_access_details": "You do not have access to this application.",
	"request_pending_txt": "Your request is pending. Contact a console user with the \"Manager\" role to approve your request.",
	"identities": "Identities",
	"templates": "Templates",
	"template_str": "Template",
	"template": "Template",
	"template_lib": "Template Library",
	"empty_identities_message": "No identities have been created.",
	"add_identity": "Add identity",
	"error_identities": "Unable to read identity information.",
	"error_add_identity": "Unable to add identity.",
	"upload_identity": "Upload Identity files",
	"upload_identity_info": "only JSON files",
	"remove": "Remove",
	"delete": "Delete",
	"remove_certificate": "Remove certificate",
	"remove_private_key": "Remove private key",
	"error_remove_identity": "Unable to remove identity",
	"error_required_field": "Required field ({{field}}) missing.",
	"error_certificate": "Certificate format is not valid.",
	"error_private_key": "Private key format is not valid.",
	"error_email": "Email format is not valid.",
	"error_url": "URL format is not valid.",
	"upload_json": "JSON information",
	"upload_json_description": "Use the add file to import from another console",
	"upload_json_info": "Only JSON files are supported",
	"cert": "Certificate",
	"cert_placeholder": "Upload a file that contains the signing certificate (in base64 or PEM format).",
	"pem": "TLS Certificate",
	"pem_placeholder": "Upload a file that contains the TLS certificate (in base64 or PEM format).",
	"peer_not_associated": "An identity has not been associated with this peer",
	"system_channel_error": "Unable to get system channel. If you associated an identity without administrative privilege on the ordering service node, you will not be able to view or manage ordering service details",
	"add_node_error": "Unable to add node to ordering service.",
	"add_node_system_channel_access_denied": "Unable to update system channel. Ensure that the current MSP is added as an ordering service administrator on the ordering service and the correct certificates are used.",
	"channel_forbidden": "Unable to get channel information from {{nodeName}}. The associated identity does not have administrative privilege. Ensure that the associated identity has administrative privilege.",
	"orderer_tls_error": "Unable to get TLS certificates from orderer",
	"error_block_decode": "Unable to decode channel.",
	"error_block_encode": "Unable to encode channel.",
	"associate_identity": "Associate identity",
	"identity": "Identity",
	"identity_placeholder": "Enter identity name",
	"error_associate_identity": "Unable to associate the identity",
	"ca_admin_identity": "Certificate Authority administrator identity",
	"orderer_admin_identity": "Orderer administrator identity",
	"peer_admin_identity": "Peer administrator identity",
	"select_wallet_identity": "Please select an identity from your wallet",
	"step_n_of_x": "Step {{step}} of {{total}}",
	"select_peer_type": "Select to create or import a peer.",
	"select_ca_type": "Select to create or import a Certificate Authority.",
	"choose_peer_location": "Choose your peer location",
	"cloud": "IBM Cloud",
	"icp": "IBM Cloud Private",
	"aws": "Amazon Web Services",
	"openshift": "Red Hat OpenShift",
	"ibmcloud": "IBM Cloud",
	"k8s": "IBM Cloud",
	"saas": "IBM Cloud",
	"ibm_saas": "IBM Cloud",
	"kubernetes": "Kubernetes",
	"azure": "Microsoft Azure",
	"next": "Next",
	"import_peer_json": "Import your peer either through exporting a JSON file with your information from that deployment or enter them manually below.",
	"enter_peer_info": "Enter peer information",
	"enter_msp_info": "Enter MSP information",
	"json_upload": "Upload JSON",
	"manual_entry": "Manual entry",
	"error_duplicate_names": "The name must be unique.",
	"export_certs": "Export identity",
	"tooltip_peer_name": "Enter a peer name. This field is only used to identify the peer in this console.",
	"tooltip_msp_id": "This is the organization MSP ID that was specified when the peer was deployed. Get this value from the peer administrator.",
	"tooltip_proxy_url": "The gRPC web proxy URL for the peer.",
	"only_manager_title": "You are the only Manager",
	"only_manager_desc": "As You are the only Manager for this console, you are not allowed to modify your roles",
	"edit_tooltip_peer_name": "Modify the name you want to use to refer to this peer in this console.",
	"edit_tooltip_msp_id": "Modify the organization MSP ID. This value was specified when the peer was deployed.",
	"edit_tooltip_proxy_url": "The gRPC web proxy URL for the peer.",
	"setup_otline": "The following steps outline how to successfully setup your console.",
	"authentication": "Authentication",
	"authentication_desc1": "The IBM Cloud App ID service is used to authenticate users to this user interface using e-mail based sign-up and sign-in including Cloud Directory or social login with Google or Facebook.",
	"authentication_desc2": "If you haven't already, please follow the steps to create an instance of the App ID service in the IBM Cloud by clicking the Proceed to App ID button below.",
	"authentication_desc3": "Once you have created an instance of the App ID service, come back and click Continue to complete the setup.",
	"proceed_to_appid": "Proceed to App ID",
	"proceed_to_configuration": "If you have already created an instance of the App ID service, proceed to Configuration by clicking the Continue button below.",
	"continue": "Continue",
	"configuration": "Configuration",
	"configuration_desc1": "Before using the Remote Peer user interface, you need to provide the Service Credentials of your App ID service instance running in the IBM Cloud. Copy the JSON service credentials or manually enter the information.",
	"configuration_desc2": "To find the JSON service credentials, go to the Service Credentials tab in your App ID service instance in the IBM Cloud. Click New Credential and then copy the entire JSON information that appears when you click View Credentials. Paste that JSON information into the form below.",
	"enter_manually": "Or manually input your information",
	"enter_json": "Or paste in your JSON information",
	"add_users": "Add Users",
	"add_users_desc": "The administrator will be the first user and will have the roles of Manager, Writer and Reader. All new access requests are sent to the administrator.",
	"admin_contact_email": "Contact email",
	"admin_contact_email_placeholder": "Email that will contacted when users need to request access",
	"success": "Success",
	"copy_text": "Copy \"{{copyText}}\"",
	"auth_setup_success_1": "Thank you for submitting your configuration form.",
	"auth_setup_success_3": "Setup is complete, you may proceed to login to the application.",
	"auth_setup_success_4": "{{adminContactEmail}} user can add additional users. This user will be contacted if a new user would like to be added to the tooling.",
	"proceed_to_login": "Proceed to Login",
	"error_auth_setup": "An error occurred while setting up the authentication.",
	"error_auth_config_json": "The JSON is not in proper format.",
	"error_auth_clientId": "Client ID is a required.",
	"error_auth_oauthServerUrl": "OAuth Server URL is required.",
	"error_auth_oauthServerUrl_invalid": "OAuth Server URL is not in proper URL format.",
	"error_auth_secret": "Secret is required.",
	"error_auth_tenantId": "Tenant ID is required.",
	"installed_chaincode": "Installed smart contracts",
	"installed_chaincode_legacy": "Installed smart contracts - Fabric v1.4 (legacy)",
	"empty_instantiated_message": "No instantiated smart contracts",
	"empty_installed_message": "No installed smart contracts",
	"instantiated_chaincode": "Instantiated smart contracts",
	"instantiated_chaincode_legacy": "Instantiated smart contracts - Fabric v1.4 (legacy)",
	"peer_created": "Peer created {{createdDate}}",
	"contract_name": "Contract name",
	"error_getting_chaincodes": "An error occurred while getting smart contracts for {{peerName}}.",
	"error_getting_v2_chaincodes": "An error occurred while getting v2 smart contracts for {{peerName}}.",
	"error_getting_chaincodes1": "An error occurred while getting smart contracts. The certificate is not an admin cert on the peer for {{peerName}}.",
	"install_chaincode_desc": "Until a peer installs a smart contract, it will not be able to access or update data on the ledger. Note that to create data created using a smart contract, the peer must have that smart contract installed on it.",
	"install": "Install",
	"step_x_of_x": "Step {{currentStep}} of {{totalSteps}}",
	"find_more_here": "Find out more here",
	"upload_package": "Upload package",
	"only_cds_packages": "Package file must be in .cds format. {{cc_limit}} MB max file size. If you want to install a .tgz file for Fabric v2.x lifecycle, you can do so from either the peer or channel details page.",
	"only_cds_or_tgz_packages": "Package file must be in .cds, .tar.gz, or .tgz format. {{cc_limit}} MB max file size",
	"only_json": "File must be in JSON format",
	"error_install_peer": "An error occurred when installing smart contract on peer.",
	"select_peers": "Select peers",
	"install_chaincode_successful": "{{name}} {{version}} has been successfully installed.",
	"block": "Block",
	"blocks": "Blocks",
	"node": "Node",
	"orderer_url": "Ordering service URL",
	"associate_identity_desc_orderer": "By associating an identity with an ordering service, you are making the identity an admin of the ordering service.",
	"associate_identity_desc": "Select your organization admin from your console Wallet.",
	"associate_identity_ca_desc": "An identity is required to operate this CA. You can use either the enroll ID and secret of your CA admin and add it to the Wallet by clicking Associate identity or select an existing identity from your Wallet.",
	"add_identity_desc": "Import the public and private key of an identity created by your Certificate Authority that you will use to operate your network nodes from the console.",
	"peers_to_associate": "Select an identity to be used by the following peers:",
	"error_single_input": "Only one item from the JSON file may be used.",
	"error_single_input_box": "You have selected a file with too many items. If the item you intended to use is on this list, you can remove the other items until only one remains. If this was a mistake, remove all the items and select another file.",
	"use_existing_identity": "Existing identity",
	"use_new_identity": "New identity",
	"use_no_identity": "Do not associate at this time",
	"back": "Back",
	"joined_channels": "Peer channels",
	"pending_osn_channels": "Pending orderer channels",
	"osn_channels": "Orderer channels",
	"import_peer_associate": "Associate identity",
	"import_orderer_associate": "Associate identity",
	"import_ca_associate": "Associate identity",
	"available_channels": "Available channels",
	"error_already_joined": "{{peerName}} is already part of the channel.",
	"attributes": "Attributes",
	"attributes_tooltip": "(Advanced option) Attributes can be used to create role-based access control over resources. For example, an attribute of NOTARY PUBLIC paired with a value of TRUE could be referenced in a policy or Access Control List mandating that only a user with the attribute NOTARY PUBLIC=TRUE can perform a particular task.",
	"attribute_name_placeholder": "Enter attribute name",
	"attribute_value_placeholder": "Enter attribute value",
	"add": "Add",
	"authentication_services": "User authentication method",
	"access_gear_title": "Change auth settings",
	"auth_scheme_label": " Authentication method",
	"user_table_header": "Users",
	"apikey_table_header": "API keys",
	"no_api_keys_msg": "There are no API keys yet",
	"registered_users": "Registered users",
	"email_address": "Email address",
	"user_account": "User account",
	"role": "Role",
	"date_added": "Date added",
	"add_new_users": "Add new users",
	"edit_user_header": "Edit user role",
	"save": "Save",
	"save_changes": "Save changes",
	"save_settings_success": "Changes are being saved",
	"save_settings_details": "This may take a few moments to restart the process.",
	"authentication_settings": "Authentication Settings",
	"edit_authentication_settings_desc": "Use this panel to update the contact email address for requests to access this console.",
	"couch_edit_authentication_settings_desc": "Use this panel to update the contact email address for requests to access this console or the default password that is assigned to all new users.",
	"edit_auth_scheme_desc": "Select how the console should authenticate users (this controls how and which users can login to the console).",
	"error_auth_settings_edit": "An error occurred while updating the authentication settings.",
	"error_console_settings_edit": "An error occurred while updating the console settings.",
	"add_new_users_desc": "General users have access to the tooling, while admin users have the ability to add and remove other admin and general users to the tooling.",
	"user_type": "User type",
	"user_type_tooltip": "Admin users have the ability to add users to this console. General users do not. Both have the same rights to operate the console.",
	"admin": "Admin",
	"administrator": "Administrator",
	"general": "General",
	"general_user": "General user",
	"error_getting_auth_details": "An error occurred while getting authentication details",
	"users_placeholder": "Enter emails separated by commas or spaces",
	"users_placeholder_couch": "Enter user IDs separated by commas or spaces",
	"join_channel_title": "Join a peer to channel",
	"join_osn_channel_title": "Join orderers to an active channel",
	"join_osn_desc": "To join an orderer to a channel we will need to use another orderer that has already joined.",
	"select_osn": "Select node from an available OS",
	"select_osn_desc": "\n\nFirst select an orderer that is already joined to the channel. We will use this orderer to get the channel's config block. \n\nThen select the channel for the new join.",
	"select_osn_placeholder": "Select an orderer",
	"select_ch_placeholder": "Select a channel",
	"general_join_fail_title": "Joining Orderer Failure",
	"general_join_failure": "One or more orderers failed to join the channel. Check that the orderers are running/online and that they have \"Channel Participation\" features enabled.",
	"find_out_more": "Find out more here",
	"select_peer_to_join": "Choose from available peers",
	"select_orderer": "Select from available ordering services",
	"select_channel": "Channel name",
	"instantiate": "Instantiate",
	"delete_users": "Delete users",
	"delete_confirmation": "Confirm delete",
	"delete_user_desc": "Deleting users is a permanent action. Confirm that the usernames below should be deleted.",
	"delete_key_desc": "Deleting API keys is a permanent action. Confirm that the keys below should be deleted.",
	"delete_key_summary_desc": "Deleting {{count}} key(s)",
	"delete_user_summary_desc": "Deleting {{count}} user(s)",
	"admin_list": "Admin",
	"access_list": "General",
	"user_removed_successful": "User {{email}} has been successfully removed.",
	"users_removed_successful": "Users {{email}} have been successfully removed.",
	"user_add_successful": "User {{email}} has been successfully added.",
	"users_add_successful": "Users {{email}} have been successfully added.",
	"apikey_removed_successful": "The selected API key has been successfully removed.",
	"apikeys_removed_successful": "The selected API keys have been successfully removed.",
	"of": "of",
	"of_n_items": "of {{n}} Items",
	"of_1_item": "of 1 Item",
	"breadcrumb_name": "{{name}}",
	"installed_on": "Installed on",
	"instantiate_chaincode_successful": "{{name}} {{version}} has been successfully instantiated on channel {{channel}}.",
	"instantiate_smc": "Instantiate smart contract",
	"upgrade_smc": "Upgrade smart contract",
	"upgrade": "Upgrade",
	"instantiate_chaincode_desc": "After a smart contract is instantiated on a channel, peers on the channel that have the smart contract installed can access data associated with that smart contract. When a peer's organization is listed in the endorsement policy of the smart contract, the peer might be asked to approve proposals for updates to data associated with the smart contract.",
	"upgrade_chaincode_desc_1": "You are upgrading <strong>{{name}}</strong> on channel <strong>{{channel}}</strong>.",
	"upgrade_chaincode_desc_2": "<strong>Important: Before upgrading the smart contract on the channel, it is strongly recommended that you install the latest version of this smart contract on all peers on <strong>{{channel}}</strong>. Otherwise they will not be able to access data associated with the smart contract.</strong>",
	"upgrade_chaincode_desc_3": " The upgrade cannot be undone after you click the Upgrade button.",
	"setup_endorsement_policy": "Setup endorsement policy",
	"setup_endorsement_policy_desc": "Select the member(s) that you want to endorse transactions.",
	"setup_pdc": "Setup private data collection",
	"setup_pdc_desc": "(Optional) Data privacy between organizations on a channel is achieved through the use of private data collections.",
	"setup_pdc_upgrade_desc": "Data privacy between organizations on a channel is achieved through the use of private data collections.",
	"select_channel_title": "Select channel",
	"simple": "Simple",
	"advanced": "Advanced",
	"enter_arguments": "Enter Arguments (Optional)",
	"enter_arguments_desc": "If you want to run a function in the smart contract to initialize it, enter the function name and an array of string arguments to pass to it.",
	"selectedArguments": "Arguments",
	"arguments_placeholder": "For example: a, 200, b, 250",
	"out_of_x": "out of {{totalCount}} selected members need to endorse transactions",
	"selectedEndorsementCount": "",
	"advanced_policy_reference": "[[Click here]](_ENDORSE_LINK) for more information about the endorsement policy format.",
	"cert_renew": "[[Click here]](_CERT_RENEW_LINK) for more information about certificate renewal.",
	"_CERT_RENEW_LINK": "{{DOC_PREFIX}}?topic=console-managing-certificates#cert-mgmt-enroll-tls-peer",
	"contact_support_2": "Before contacting support, check out the latest [[Troubleshooting]](troubleshooting_link) information to see if a solution to your problem is already available.",
	"contact_support_3": "Before contacting support, check out our [[docs]](hlfoc_doc_link) to see if a solution to your problem is already available.",
	"_ENDORSE_LINK": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"selectedChannel": "Channel",
	"selectedOrderer": "Orderer cluster",
	"one_member_endorses": "One member needs to endorse transactions.",
	"error_occurred_during_instantiation": "An error occurred during instantiation.",
	"error_occurred_during_upgrade": "An error occurred during upgrade.",
	"transaction_overview": "Transaction overview",
	"channel_details": "Channel details",
	"error_channel_not_found": "The channel '{{channelId}}' was not found.",
	"block_history": "Block history",
	"transactions": "Transactions",
	"block_hash": "Block hash",
	"block_title": "Block {{number}}",
	"error_block": "An error occurred getting the block information.",
	"transaction_id": "Transaction ID",
	"block_created": "Block created {{date}}",
	"empty_transactions": "There are no transactions for this block.",
	"transaction": "Transaction",
	"chaincode_id": "Smart contract ID",
	"chaincode_management": "Smart contract management",
	"chaincode_definitions": "Smart contract definitions",
	"chaincode_definitions_desc": "Description text needed here.",
	"chaincode_propose": "Propose smart contract definition",
	"chaincode_empty_title": "Run a smart contract on this channel",
	"chaincode_empty_desc": "This channel doesn't currently have any smart contract definitions. You can understand how to run a smart contract on this channel by reading the quick 5 minute \"How-to topic\" or get started by proposing a smart contract definition.",
	"chaincode_how_to": "How-to topic",
	"chaincode_how_to_link": "{{DOC_PREFIX}}?topic=how-developing-smart-contracts-applications",
	"chaincode_proposed": "Proposed",
	"chaincode_ready": "Ready to commit",
	"chaincode_committed": "Committed",
	"select_your_org": "Select your organization",
	"propose_chaincode_desc_1": "Smart contracts on a channel must be approved by the required number of organizations in the lifecycle endorsement policy. Use this panel to propose a smart contract definition to those channel members.",
	"propose_chaincode_desc_2": "Because you can manage multiple organizations from a single console, you must select the organization that you are acting as when you propose this smart contract definition.",
	"propose_org": "Organization submitting proposal",
	"select_org": "Select an organization",
	"propose_org_tooltip": "Select the organization that is proposing this definition.",
	"propose_identity": "Organization administrator identity",
	"propose_identity_tooltip": "The identity must be the admin for the organization MSP.",
	"propose": "Propose",
	"propose_chaincode_pkg_desc": "Select the smart contract package that you want to include in this proposal by uploading a new package or selecting an existing package.",
	"existing_package": "Existing package",
	"chaincode_package": "Smart contract package",
	"chaincode_package_desc": "Files must be of type .tgz or .tar.gz. Maximum file size is 25 MB.",
	"no_existing_packages": "There are currently no existing packages available.",
	"propose_parsing_error_title": "There is a problem with the package file",
	"propose_parsing_error_txt": "The package name and version information could not be located in the selected file.",
	"propose_package_info": "Use this panel to specify a name and version for this smart contract proposal. Specify a new name if another smart contract proposal already exists with the same name.",
	"chaincode_details": "Smart contract details",
	"pkg_id": "Smart contract name",
	"pkg_version": "Smart contract version",
	"pkg_id_placeholder": "Enter the identifier",
	"pkg_version_placeholder": "Enter the version",
	"pkg_id_tooltip": "Specify any name that you want to use for the smart contract definition. Be careful how you name your proposal. If a proposal with the same name already exists on the channel, this proposal replaces it.",
	"pkg_version_tooltip": "<strong>Tip:</strong> Use <strong>n.n</strong>, (for example 1.0 or 1.1) when the version requires approval across organizations in the channel. Use <strong>n.n.n</strong> (for example 1.0.1 or 1.1.2) when the version does not require approval from other organizations and does not introduce any modifications the to read/write set that could cause transaction endorsement to fail.",
	"propose_install_desc": "The selected package will be installed on one or more peers in your organization. Use this panel to install it on all your peers or select individual peers.",
	"propose_install_important": "Your identity must have the admin role for the peer organization in order to install smart contract packages.",
	"propose_install_important_link": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"propose_install_all_peers": "Install smart contract on all peers from your organization that have joined this channel?",
	"install_on": "Install on",
	"propose_policy": "Smart contract endorsement policy",
	"propose_policy_desc": "Select which organizations can endorse a transaction for this smart contract and how many endorsements are required before it can be committed to the ledger.",
	"propose_policy_important": "This policy controls who can commit a transaction to the ledger and is different than the lifecycle endorsement policy which specifies who can approve a smart contract definition and how many approvals are required.",
	"propose_policy_important_link": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"init_required": "Does smart contract require invoking 'Init'?",
	"implicit": "Implicit",
	"explicit": "Explicit",
	"transaction_endorsement_policy": "Smart contract endorsement policy",
	"majority_orgs_must_endorse": "Majority of organizations must endorse",
	"all_orgs_must_endorse": "All organizations must endorse",
	"any_orgs_must_endorse": "Any organizations must endorse",
	"num_orgs_must_endorse": "Number of organizations that must endorse",
	"org_must_endorse": "{{org}} must endorse",
	"n_orgs_of_must_endorse": "{{n}} of {{orgs_list}} must endorse",
	"select_organizations": "Select organizations",
	"existing_pkgs_installed": "Existing packages installed on peers",
	"choose_a_pkg": "Choose a package",
	"manual_policy": "Manual endorsement policy",
	"manual_policy_placeholder": "Enter policy JSON here",
	"manual_policy_reference": "[[Click here]](_MANUAL_POLICY_LINK) for more information about the endorsement policy format.",
	"_MANUAL_ENDORSE_LINK": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"error_manual_policy_json": "The JSON is not in proper format.",
	"propose_private_data": "Additional private data collection",
	"propose_private_data_desc": "If your smart contract package includes private data collections, use this panel to upload the private data collection configuration file. Private data requires cross organizational gossip in order to work. Therefore you need to ensure each organization that uses this private data collection has at least one anchor peer configured in the channel.",
	"private_data_json": "Collections configuration file",
	"proposal_will_be_sent": "This proposal will be sent to:",
	"this_proposal": "This proposal will approve this definition for ",
	"lifecycle_endorsement_policy": "Lifecycle endorsement policy",
	"policy_custom": "Custom policy",
	"policy_majority": "Majority must approve",
	"policy_all": "All must approve",
	"policy_any": "Any must approve",
	"policy_n": "{{n}} must approve",
	"use_default_policy": "Use default endorsement policy",
	"use_default_policy_majority": "Use default endorsement policy (majority of organizations need to approve)",
	"use_default_policy_all": "Use default endorsement policy (all organizations need to approve)",
	"use_default_policy_any": "Use default endorsement policy (any organization can approve)",
	"chaincode_proposal": "Smart contract proposal",
	"chaincode_proposal_text": "Smart contract <strong>{{id}} {{version}}</strong> for channel <strong>{{channel}}</strong> proposed by <strong>{{org}}</strong>",
	"view_chaincode_proposal": "View smart contract proposal",
	"propose_existing_important": "A smart contract proposal for <strong>{{chaincode_id}}</strong> is currently pending for version <strong>{{chaincode_version}}</strong>.  If you continue with this proposal, it will replace the currently pending proposal.",
	"chaincode_definition": "Smart contract definition",
	"approve": "Approve",
	"commit": "Commit",
	"proposed_with_policy": "Proposed ({{n}} must approve)",
	"approved": "Approved",
	"pending_approval": "Pending approval",
	"peers_installed_on": "Currently installed on peers",
	"approve_chaincode_desc_1": "The lifecycle endorsement policy describes how many organizations are required to approve this proposal before the smart contract definition can become active on the channel. Select the organization that you are acting as when you approve this smart contract definition.",
	"approve_chaincode_desc_2": "Due to the Fabric Operations Console allowing you to manage multiple organizations, we must first define the organization which you are going to approve this smart contract definition as.",
	"approve_org": "Organization approving proposal",
	"approve_org_tooltip": "TBD",
	"approve_identity": "Organization administrator identity",
	"approve_identity_tooltip": "The identity must be the admin for the organization MSP.",
	"install_options": "Installation options",
	"install_none": "Do not install a smart contract package at this time",
	"install_same": "Install the current smart contract package on additional peers",
	"install_different": "Install a different smart contract package",
	"install_new": "Install a smart contract package",
	"commit_proposal": "Commit smart contract",
	"commit_proposal_desc": "Select the organization that will be used to commit the smart contract proposal.",
	"commit_org": "Organization committing proposal",
	"commit_org_tooltip": "TBD",
	"commit_identity": "Organization administrator identity",
	"commit_identity_tooltip": "The identity must be the admin for the organization MSP.",
	"approve_proposal": "Approve smart contract",
	"update_proposal": "Update package details",
	"update_chaincode_desc": "You may update the proposal to change the smart contract package that will be used for your organization or to install the smart contract package on addition peers.",
	"propose_no_peers": "The selected organization does not have any peers joined to the channel.  Please select an organization with at least one peer joined to the channel.",
	"approve_no_peers": "At least one peer from the organization must be joined to the channel.",
	"begin_commit": "Begin commit process",
	"begin_approve": "Begin approval process",
	"approve_with_install": "Approve definition with a smart contract package",
	"approve_install_desc": "Select the smart contract package that you want to install on your peers by uploading a new package or selecting an existing package from another peer in your organization.",
	"approve_install_desc_2": "Or, if you prefer that your peers cannot endorse transactions for this smart contract definition, simply select No for the toggle. You can install the package on your peers later if you change your mind.",
	"not_all_approved": "Some organizations that are part of the channel have not approved this proposal.  If the proposal is committed, these organizations will still need to approve the proposal in order to participate in transactions for this smart contract.",
	"input": "Input",
	"output": "Output",
	"transaction_no_inputs": "There are no inputs for the smart contract.",
	"transaction_no_outputs": "There are no outputs for the smart contract.",
	"transaction_delete": "DELETE",
	"transaction_write": "WRITE",
	"transaction_assignment": "=",
	"no_blocks": "There are no blocks for the channel.",
	"error_sync_channel": "Certificate is not a admin cert on the channel for the MSP.",
	"policy": "Policy",
	"endorsement_policy": "{{count}} out of {{total}} members need to endorse transactions",
	"duplicate_user_not_allowed": "User already exists.",
	"upgradable": "",
	"upgrade_available": "Upgrade Available",
	"select_chaincode_version": "Select upgrade version",
	"instantiated": "Instantiated",
	"selectedVersion": "",
	"parsedArguments": "Arguments to be passed",
	"error_parsing_json": "Error parsing JSON",
	"zip_or_json": "Choose a Zip or JSON file",
	"upgrade_chaincode_successful": "{{name}} has successfully been upgraded from {{old_version}} to {{new_version}} on {{channel}}. You can now start transacting with it.",
	"add_peer_successful": "Peer added",
	"add_peers_successful": "Peers added",
	"add_peer_successful_description": "Congratulations! You have successfully created '{{peerName}}' from {{peerCloud}}. It will take a few minutes for the status to become green while it is deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"add_peers_successful_description": "Congratulations! You have successfully created peers. It will take a few minutes for the status to become green while they are deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"instantiated_chaincode_empty": "No instantiated smart contracts found for this channel.",
	"select_orderer_title": "Select ordering service",
	"json_configuration_tooltip": "Ensure the JSON content has the required fields: clientId, oauthServerUrl, secret and tenantId. Otherwise use the link below to manually specify these values.",
	"invalid_email": "Email is invalid.",
	"certificate_keypair": "Certificate key pair",
	"update": "Update",
	"update_peer": "Update peer",
	"identity_details": "Identity details",
	"update_identity": "Update identity",
	"error_update_identity": "An error occurred during the identity update.",
	"connected_to": "Connected to",
	"remove_identity": "Remove identity",
	"remove_identity_desc": "This action cannot be undone. This will permanently remove {{name}} from the Fabric Operations Console.",
	"remove_identity_confirm": "Please type the name of the identity to confirm:",
	"confirm_identity_name": "Identity name",
	"confirm_peer_name": "Peer Name",
	"confirm_msp_name": "MSP Name",
	"confirm_orderer_name": "Ordering service name",
	"confirm_orderer_channel_name": "Channel name",
	"confirm_ca_name": "Certificate Authority name",
	"confirm_identity_name_placeholder": "Type here",
	"confirm_peer_name_placeholder": "Type here",
	"confirm_orderer_name_placeholder": "Type here",
	"confirm_orderer_channel_name_placeholder": "Type here",
	"confirm_ca_name_placeholder": "Type here",
	"confirm_msp_name_placeholder": "Type here",
	"wallet": "Wallet",
	"orderer_settings": "Ordering service settings",
	"advanced_channel_maintenance": "Maintenance",
	"system_channel_maintenance": "System Channel Maintenance",
	"remove_system_channel_important_link": "https://hyperledger-fabric.readthedocs.io/en/release-2.4/whatsnew.html#orderer-channel-management-without-a-system-channel",
	"remove_system_channel_1_text": "For easier maintenance Hyperledger Fabric now offers operation of orderer without system channel.",
	"remove_system_channel_2_title": "System channel not in maintenance mode",
	"remove_system_channel_2_text": "You are about to remove system channel. It is recommended that you put the system channel in maintenance mode so that new channels cannot be created using the system channel.",
	"channel_maintenance_desc1": "Set the system channel in maintenance mode. This is action is necessary before removing system channel from the ordering nodes.",
	"maintenance_mode": "Maintenance Mode",
	"orderer_type": "Orderer Type",
	"system_channel_maintenance_warning": "When system channel is in maintenance mode you will not be able to create new channels",
	"orderer_node_settings": "Ordering node settings",
	"remove_orderer_channel_desc": "This action will remove {{name}} from the selected ordering nodes.",
	"remove_orderer_channel_confirm": "Please type the name of the channel to confirm:",
	"remove_orderer_desc": "This action will remove {{name}} from the Fabric Operations Console. Its containers will remain intact.",
	"delete_orderer_desc": "This action will permanently delete {{name}} in your Kubernetes cluster and cannot be undone. Consider this action carefully. If this ordering service contains channels that peers are transacting on, all subsequent transactions will fail.",
	"remove_orderer_confirm": "Please type the name of the ordering service to confirm:",
	"error_update_orderer": "An error occurred during the ordering service update.",
	"error_update_peer": "An error occurred during the peer update.",
	"error_update_ca": "An error occurred during the Certificate Authority update.",
	"peer_settings": "Peer settings",
	"remove_peer_desc": "This action cannot be undone. This will permanently remove {{name}} from the Fabric Operations Console. If the peer was created using this console, it will be deleted in the IBM Kubernetes service as well. If it was created elsewhere and imported into this console, it will only be removed from the UI. Its containers will remain intact.",
	"delete_peer_desc": "This action will permanently delete {{name}} in your Kubernetes cluster and cannot be undone. Consider this action carefully.",
	"remove_msp_desc": "This action cannot be undone.  This will permanently remove {{name}} from the Fabric Operations Console.",
	"remove_adminmsp_from_orderer_desc": "This action will remove {{name}} from the ordering service but not from the console. This organization MSP will no longer be able to manage this ordering service. You can add it back at a later time if needed.",
	"remove_msp_from_consortium_desc": "This action will remove {{name}} from the consortium but not from the console. Organizations must be part of a consortium before they can be added to a channel.",
	"remove_peer_confirm": "Please type the name of the peer to confirm:",
	"remove_msp_confirm": "Please type the name of the organization MSP to confirm.",
	"associate": "Associate",
	"ca_details": "Certificate Authority settings",
	"remove_ca_desc": "This action cannot be undone. This will permanently remove {{name}} from the Fabric Operations Console. If the CA was created using this console, it will be deleted in the IBM Kubernetes service as well. If it was created elsewhere and imported into this console, it will only be removed from the UI. Its containers will remain intact.",
	"delete_ca_desc": "This action will permanently delete {{name}} in your Kubernetes cluster and cannot be undone. Consider this action carefully.",
	"remove_ca_confirm": "Please type the name of the Certificate Authority to confirm:",
	"remove_consenter_system_channel_desc": "This action will remove the consenter {{name}} from the ordering service system channel.",
	"remove_consenter_application_channel_desc": "This action will remove the consenter {{name}} from channel {{channelId}}.",
	"remove_consenter_confirm_channel": "Please type the name of the consenter to confirm:",
	"remove_consenter_confirm": "Please type the URL of the consenter to confirm:",
	"confirm_consenter_url": "Consenter URL",
	"confirm_consenter_url_placeholder": "Enter the URL of the consenter to be removed",
	"add_ca_successful": "Certificate Authority added",
	"add_cas_successful": "Certificate Authorities added",
	"add_ca_successful_description": "Congratulations! You have successfully created '{{caName}}'. It will take a few minutes for the status to become green while it is deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"add_cas_successful_description": "Congratulations! You have successfully created Certificate Authorities. It will take a few minutes for the status to become green while they are deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"add_orderer_successful": "Ordering service added",
	"add_orderers_successful": "Ordering service added",
	"add_orderer_successful_description": "Congratulations! You have successfully created '{{ordererName}}'. It will take a few minutes for the status to become green while it is deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"add_orderers_successful_description": "Congratulations! You have successfully created ordering services. It will take a few minutes for the status to become green while they are deploying to your cluster in the background. Feel free to carry on with your other activities in the console while that happens.",
	"empty": "",
	"connect_with_sdk": "Connect with SDK",
	"sdk_connect_info": "Use this panel to generate the connection profile that you will use to connect to your network from your client application using the Fabric SDK. Select the certificate authority and organization MSP definition to be added to the connection profile.",
	"read_more": "Read more here",
	"authenticate_users": "Authenticated users",
	"register_user": "Register user",
	"register": "Register",
	"add_attribute": "Add attribute",
	"connection_profile": "Connection profile",
	"_CREATE_APP_INVOKE_LINK": "{{DOC_PREFIX}}?topic=tutorials-developing-applications#ibm-hlfsupport-console-app-profile",
	"connection_profile_subtext": "This connection profile is used by your client application to connect to your network. It contains all the endpoint information of your peers, Certificate Authority, and organization MSP ID. Read more [[here]](_CREATE_APP_INVOKE_LINK).",
	"create_connection_profile": "Create connection profile",
	"connection_profile_include_ca": "Include CA for user registration and enrollment?",
	"connection_profile_include_ca_2": "Include {{ca}} for user registration and enrollment?",
	"connection_profile_include_ca_tooltip": "Select Yes if you plan to use the client application to register or enroll users against the CA associated with this MSP. Select No when the application already has the necessary certificates to transact with the network.",
	"connection_profile_peers": "Select peers to include",
	"no_peers_available": "There are currently no peers available.",
	"connection_profile_channels": "Channels this connection profile will access",
	"connection_profile_channel_warning": "The channel information for one or more of the selected peers could not be retrieved and is not included in the displayed list.",
	"connection_profile_ca_warning": "The Certificate Authority associated with the organization was not found in the console and will not be included in the connection profile.",
	"connection_profile_ca_link": "{{DOC_PREFIX}}?topic=console-managing-organizations",
	"see_more": "+ see {{count}} more",
	"see_less": "- see less",
	"download_connection_profile": "Download connection profile",
	"channel_name": "Channel name",
	"smart_contract_name": "Smart contract name",
	"example_code_snippet": "Example code snippet (JavaScript)",
	"copied": "Copied",
	"error_cds_file": "The smart contract file is not valid",
	"channel_orderer_not_found": "Ordering service not found",
	"channel_orderer_not_found_desc": "There are no ordering services available. Please add the ordering service associated with the channel.",
	"no_smart_contract_installed": "The selected smart contract is not installed on the channel peer(s).",
	"already_instantiated_on_channel": "The selected smart contract is already instantiated on this channel. Select a different channel or if you have another version of the smart contract installed, you can upgrade it from the 'instantiated smart contracts' table below",
	"welcome": "Welcome to the Fabric Operations Console",
	"welcome_description": "To get up and running easily, click Start Here and follow one of our helpful checklists. ",
	"welcome_description2": "For a more in-depth tutorial, check out our [[documentation]](_LINK2).",
	"open_channel_config": "Open Channel Config",
	"_LINK2": "{{DOC_PREFIX}}?topic=started-build-network",
	"build_network_hlfoc": "{{DOC_PREFIX}}/deployment_guide_overview.html",
	"_GABLOG": "http://ibm.biz/BdzqHc",
	"_404DOCLINK": "{{DOC_PREFIX}}",
	"documentation": "Documentation",
	"learn_more_docs": "Learn more in our documentation",
	"do_not_show_again": "Do not show again",
	"build_smart_contract": "Build a smart contract",
	"create_channel": "Create channel",
	"create_channel_desc": "Each transaction on a network is executed on a channel, a private sub-network where two or more organizations can communicate and conduct transactions only the authenticated members of the channel know about.",
	"channel_name_placeholder": "Enter a name for your channel",
	"channel_name_desc": "Enter a name meaningful to the way the channel will be used and which is unlikely to be given to another channel on the same orderer.",
	"ordering_service": "Choose from available ordering services",
	"channel_orderer_desc": "This transaction will be sent to 1 of the orderers in this cluster.",
	"channel_creator_msp": "Channel creator MSP",
	"channel_creator_msp_desc": "Because the console gives a single user the ability to own and control several organizations and identities, you must specify the organization and identity you are using when you sign the channel creation request.",
	"channel_updater_msp": "Channel updater MSP",
	"selectedChannelCreator": "Select the MSP",
	"channel_updater_msp_desc": "Because the console gives a single user the ability to own and control several organizations and identities, you must specify the organization and identity you are using when you sign the channel update request.",
	"channel_organizations": "Organizations",
	"channel_orderer_organizations": "Orderer organizations",
	"organization": "Organization",
	"organization_placeholder": "Enter the organization's MSP",
	"channel_identity_desc": "Choose from the available admin identities. To add an admin identity, you can create one as part of creating an organization MSP, register an admin, giving a type of admin, and enroll it with your organization's CA, or navigate to your Wallet, where you can import one.",
	"selectedIdentity": "Identity",
	"organization_description": "Any organization known to the ordering service you selected can be added to the channel.",
	"no_operator_error": "Every channel must have at least one operator",
	"no_admin_error": "Every channel must have at least one admin",
	"operator_needed": "Operator needed",
	"admin_needed_simple": "Admin needed",
	"orderer_needed_error": "Every channel needs at least one orderer to be the consenter. The possible consenters are computed from the selected orderer MSPs below. Therefore you must select at least one MSP that has a known orderer to continue. Add another MSP.",
	"orderer_needed_simple": "Missing a MSP with orderers",
	"duplicate_msp": "Duplicate MSP",
	"duplicate_msp_error": "Channel cannot have two organizations with same MSP ID.",
	"approval_error_no_channel": "There are no peer joined to \"{{channelName}}\". Ensure that at least one peer is joined the channel from this organization.",
	"validation_channel_name_invalid": "The channel name can contain only lowercase characters, numbers, or dashes, and should start with a letter.",
	"channel_name_required": "Channel name is required.",
	"reader": "Reader",
	"writer": "Writer",
	"operator": "Operator",
	"error_create_channel": "An error occurred when creating channel.",
	"channel_creation_request_submitted": "You have successfully initiated a request to create {{channelName}}. Join a peer to this channel by clicking the pending channel tile below.",
	"channel_creation_request_submitted_to_orderer": "You have successfully initiated a request to create {{channelName}}. This request requires the signature of an ordering service MSP. After the request has been signed and submitted, you will see a pending channel tile. Clicking on this tile will allow you to join a peer to the channel.",
	"channel_join_request_submitted": "You have successfully joined {{channelName}}.",
	"channel_error_exists": "Channel name already exists on ordering service.",
	"channel_error_nomember": "One of the selected MSP IDs is not in the consortium.",
	"set_identity": "Set identity",
	"use_enroll_id": "Enroll ID",
	"set": "Set",
	"versions": "Versions",
	"version_info": "{{product}} - {{version}}",
	"apollo": "User Interface",
	"athena": "Backend API",
	"stitch": "gRPC API",
	"transaction_data": "Transaction data",
	"hidden": "Hidden",
	"visible": "Visible",
	"error_getting_settings": "Unable to retrieve settings information.",
	"error_updating_settings": "Unable to update settings information.",
	"client_logging_tooltip": "Logs collected when commands are sent from the browser to the console.",
	"server_logging_tooltip": "Logs collected when the console sends commands to your nodes.",
	"on": "On",
	"off": "Off",
	"error_log_settings": "Unable to change log settings.",
	"error": "Error",
	"warn": "Warning",
	"info": "Information",
	"verbose": "Verbose",
	"debug": "Debug",
	"silly": "All",
	"client-level": "",
	"server-level": "",
	"log_settings": "Log settings",
	"tls_identity_not_found": "TLS Identity not found",
	"peer_tls_identity_not_found": "An identity that is enrolled with the TLS Certificate Authority that was used to create the peer is required to view or modify the log settings.",
	"orderer_tls_identity_not_found": "An identity that is enrolled with the TLS Certificate Authority that was used to create the ordering node is required to view or modify the log settings.",
	"orderer_tls_admin_identity_not_found": "This ordering cluster is not using a system channel, thus a TLS identity is needed to manage it. This is the same identity that was used to create the ordering node and was enrolled against the TLS Certificate Authority. Once this identity is in the console's wallet you will be able to view, delete and join channels.\n\nYou can use the console to enroll this identity by navigating to the CA tile and clicking the \"Enroll identity\" action on the row for the orderer's identity. Make sure to select the *TLS* CA in the drop down when enrolling.",
	"orderer_tls_admin_identity_not_found2": "This ordering cluster has \"channel participation\" features available, but the wallet does not have a TLS identity to leverage this feature. Add this identity to your wallet by enrolling the same identity that was used to create the ordering node with the *TLS* Certificate Authority. Once this identity is in the console's wallet you will be able to remove the system channel and manage channels with Fabric's preferred interface (\"channel participation\"). (.\n\nYou can use the console to enroll this identity by navigating to the CA tile and clicking the \"Enroll identity\" action on the row for the orderer's identity. Make sure to select the *TLS* CA in the drop down when enrolling.",
	"error_get_log_settings": "Unable to retrieve log settings",
	"peer_error_get_log_settings": "An error occurred while retrieving the current log settings.  Ensure that the peer is running and that the peer operations url is correct. ",
	"orderer_error_get_log_settings": "An error occurred while retrieving the current log settings.  Ensure that the ordering node is running and that the ordering node operations url is correct. ",
	"default_log_level": "Default log level",
	"logger_overrides": "Logger overrides",
	"logger_overrides_tooltip": "Override the log level for a specific logger by providing the logger name and desired log level.",
	"logger_placeholder": "Enter logger here",
	"logger_name": "Logger name",
	"log_level_override": "{{logger}} log level",
	"fatal": "Fatal",
	"panic": "Panic",
	"default": "Default",
	"log_spec": "Logging specification",
	"log_spec_placeholder": "Enter log specification string here",
	"log_spec_tooltip": "Enter the Fabric logging specification string",
	"log_settings_desc": "Use this panel to set the log level for all loggers on this node or to override the log level for specific loggers. See [[Configuring node logging]](log_settings_topic_link) for more information.",
	"log_settings_topic_link": "{{DOC_PREFIX}}?topic=console-administering-your#ibm-hlfsupport-console-manage-logger",
	"log_settings_desc_openshift": "Use this panel to set the log level for all loggers on this node or to override the log level for specific loggers. See [[Configuring node logging]](log_settings_topic_link_openshift) for more information.",
	"log_settings_topic_link_openshift": "{{DOC_PREFIX}}?topic=console-administering-your#ibm-hlfsupport-console-manage-logger",
	"product_running_on": "Running on {{platform}}",
	"create_ca_ibmcloud": "Create an IBM Cloud Certificate Authority",
	"create_ca": "Create a Certificate Authority",
	"import_ca": "Import an existing Certificate Authority",
	"signing": "Certificate signing request host names",
	"signing_placeholder": "Enter host names",
	"enter_certificate": "Enter CA admin credentials",
	"enter_certificate_tooltip": "When your CA first starts, it contains an admin identity that you use to operate your CA. Use this panel to create the CA Admin ID and Admin secret. Save these values.",
	"admin_id": "Admin ID",
	"admin_id_placeholder": "Enter the admin ID",
	"admin_secret": "Admin secret",
	"admin_secret_placeholder": "Enter the admin secret",
	"enter_advanced": "Enter advanced information",
	"summary": "Summary",
	"affiliations": "Affiliations",
	"affiliations_placeholder": "Enter affiliations",
	"role_type": "Role Type",
	"role_type_placeholder": "Enter role type",
	"role_type_value_placeholder": "ex: peer, client",
	"summary_role_type": "{{name}} = {{value}}",
	"error_create_ca": "Unable to create Certificate Authority",
	"add_msp": "Add MSP",
	"enter_msp_name": "Enter MSP name",
	"import_msp_config": "Add an MSP",
	"enter_msp_description": "Enter the MSP ID you want to add",
	"generate_msp": "Create MSP definition",
	"generate_msp_desc1": "A Membership Service Provider (MSP) uses your CA to create a formal definition of your organization and includes a certificate identifying an administrator of the organization.",
	"generate_msp_desc2": "After adding this MSP to the ordering service, you will be able to create or join channels.",
	"msp_details": "MSP details",
	"is_not_from_root": "One of the admin cert is not from the root CA. Ensure that all the admin certs are from the root CA.",
	"root_cert_details": "Root Certificate Authority details",
	"admins": "Additional administrators (optional)",
	"admin_certs": "Admin certificates",
	"admin_cert": "Admin certificate",
	"intermediate_cert_details": "Intermediate Certificate Authority details",
	"msp_name": "MSP display name",
	"msp_name_placeholder": "Enter name for the MSP",
	"admins_desc": "Every organization's MSP definition needs to include at least one administrator certificate. To generate an admin certificate you must register a user with the type of \"admin\" with your organization's CA. To enroll an admin identity, enter below the enroll ID and enroll secret that was specified when that user was registered. Ensure that you export this admin identity to avoid losing these public and private keys. Alternatively, you can also choose an admin identity that has already been created by using the Existing identity tab.",
	"hsm.pkcs11endpoint": "HSM proxy endpoint",
	"admin_needed": "For the standard MSP type, there should be one or more X.509 certificates in this list.",
	"admin_certificate": "Administrator certificate",
	"admin_certificate_placeholder": "Paste the certificate of additional admin identity (in base64 format).",
	"add_admin": "Add administrator",
	"tls_root_cert_auth_details_desc": "Your root CA is the CA you used to create your nodes, admin identities, and application identities associated with an organization.",
	"root_cert_needed": "There must be at least one Root CA X.509 certificate in an MSP",
	"generate": "Generate",
	"add_existing": "Add existing",
	"root_ca": "Root Certificate Authority",
	"root_ca_connection": "Select the CA that you used to register your application identities",
	"generate_certificate": "Generate an organization admin certificate that is added to your wallet",
	"certificates_for_msp": "Certificates for MSP",
	"root_certs": "Root certificates",
	"tls_root_certs": "TLS root certificates",
	"identity_name": "Identity name",
	"msp_to_use": "MSP for connection",
	"msp_to_use_tooltip": "Select the MSP definition that contains your organization's MSP ID",
	"identity_name_placeholder": "Enter name for the identity to be stored in your Wallet",
	"root_cert_placeholder": "Enter another root certificate",
	"tls_root_cert_placeholder": "Enter another TLS root certificate",
	"download_cert_first": "The identity was generated successfully and has been added to your Wallet. To avoid losing these public and private keys, you must export them now and store them in a safe place.",
	"generating_cert": "Generating certificate",
	"error_required_fields": "Required fields are missing.",
	"error_occurred_during_msp_generation": "An error occurred while generating MSP.",
	"msp_generated_successfully": "{{msp_id}} has been successfully generated.",
	"error_getting_root_cert": "An error occurred while getting CA root certs.",
	"error_identity_exists": "Identity with same name already exists.",
	"selectedOrg": "MSPs",
	"selected_msps": "Selected MSPs",
	"channel_title": "{{channelName}}",
	"channel_update_request_submitted": "You have successfully initiated a request to update channel {{channelName}}.",
	"update_channel": "Update channel",
	"error_update_channel": "An error occurred when updating channel.",
	"no_changes": "Channel not updated: no change detected.",
	"create": "Create",
	"msps": "MSPs",
	"error_msps": "Unable to read MSP information.",
	"error_add_msps": "Unable to add MSP.",
	"error_add_msps_already_exists": "Unable to add MSP. The requested MSP ID already exists.",
	"error_delete_msps": "Unable delete MSP '{{msp_id}}'.",
	"error_delete_msps_policy": "Unable delete MSP '{{msp_id}}'. Required signatures not met.",
	"empty_msps_message": "No MSPs have been added yet.",
	"msp_heading": "Organizations",
	"export_msp": "Export MSP",
	"orderer_admins": "Ordering administrators",
	"orderer_members": "Consortium members",
	"add_organization": "Add organization",
	"add_orderer_administrator": "When an ordering node is created, you specify the ordering service administrator MSP certificate. Use this panel to allow additional organizations to administer the node.",
	"add_orderer_organization": "Use this panel to add an organization to the consortium by selecting its MSP definition. \n \n Select an existing organization MSP ID from this console or upload a new one using the organization MSP JSON file provided to you.",
	"add_orderer_admin": "Add ordering service administrator",
	"use_existing_msp": "Existing MSP ID",
	"select_existing_msp": "Select an existing MSP",
	"upload_package_tooltip": "In order to install a smart contract onto a peer, you must first package the smart contract into a smart contract package. Smart contract packages must be in the Hyperledger Fabric Chaincode Deployment Spec (CDS) format. You can create smart contract packages using the Hyperledger Fabric CLI peer chaincode package command, or the IBM Blockchain Platform Developer Tools.",
	"upload_package_tooltip2": "In order to install a smart contract onto a peer, you must first package the smart contract into a smart contract package. Smart contract packages must be in .tgz or tar.gz format. You can create smart contract packages using the Hyperledger Fabric CLI peer chaincode package command, or by using the IBM Blockchain Platform Developer Tools.",
	"select_peers_tooltip": "Select one or more peers to install the smart contract on. Only peers visible in this console are listed.",
	"select_channel_tooltip": "Select one channel. The smart contract will become active on every peer in this channel that has this version installed.",
	"setup_endorsement_policy_tooltip": "Specifies the requirements for the smart contract transaction endorsement including the organization members who can endorse the transactions and how many endorsements are required.",
	"setup_pdc_tooltip": "If your smart contract uses private data collections, upload the associated collection configuration file.",
	"setup_pdc_upgrade_tooltip": "If your smart contract uses private data collections, upload the associated collection configuration file. This action will overwrite any existing private data collections already associated with the smart contract.",
	"installed_smart_contract_page_tooltip": "A peer needs to have the smart contract installed so it can access the ledger once the smart contract is instantiated a channel. This table shows which smart contract versions are installed on peers in this console.",
	"select_chaincode_version_tooltip": "Select the chaincode version you want to upgrade to.",
	"endorsement_policy_members": "Whenever a new member is added to a channel, the endorsement policy of the instantiated smart contract must be updated to include the new channel member.",
	"endorsement_policy_policy": "The number of members in the organization that are required to endorse transactions for this smart contract.",
	"enter_arguments_field_tooltip": "Not all smart contracts require initialization parameters.",
	"connection_profile_tooltip": "The client application passes a connection profile, which contains the necessary peers and channels, to the gateway to endorse transactions.",
	"channel_name_tooltip": "Before it can submit a transaction to a smart contract, the client application must pass the the name of the channel which the smart contract is instantiated on to the gateway.",
	"smart_contract_name_tooltip": "Used by the SDK to get the smart contract from the channel.",
	"ca_name_tooltip": "The CA name that was specified when the CA was deployed.",
	"ca_display_name_tooltip": "Enter a CA name. This field is only used to identify the CA in this console.",
	"remove_ca_name_tooltip": "Re-enter the CA name to confirm",
	"ca_url_tooltip": "The endpoint URL of the CA where it was deployed.",
	"ca_pem_tooltip": "Upload a file that contains your CA TLS cert (in base64 or PEM format).",
	"ca_enrollid_tooltip": "Enroll ID",
	"ca_enrollsecret_tooltip": "Enroll secret",
	"edit_icp_ca_display_name_tooltip": "Modify the display name of this CA. This field is only used to identify the CA in the console.",
	"edit_saas_ca_display_name_tooltip": "Modify the display name of this CA. This field is only used to identify the CA in the console.",
	"edit_icp_ca_name_tooltip": "Modify the CA name. You use this name when you to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"edit_saas_ca_name_tooltip": "Use this CA name when you to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"edit_icp_tlsca_name_tooltip": "TLS CA name",
	"edit_saas_tlsca_name_tooltip": "TLS CA name",
	"edit_icp_ca_url_tooltip": "Modify the CA URL. Use this URL to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"edit_saas_ca_url_tooltip": "Use this URL to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"edit_icp_ca_pem_tooltip": "Upload a file that contains the CA TLS certificate (in base64 or PEM format). Use this TLS certificate to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"edit_saas_ca_pem_tooltip": "Use this TLS certificate to connect to this CA from the Fabric SDKs or the Fabric CA client.",
	"orderer_url_tooltip": "The gRPC web proxy URL for the ordering service.",
	"edit_orderer_url_tooltip": "The gRPC web proxy URL for the ordering service.",
	"edit_orderer_msp_id_tooltip": "Modify the ordering service MSP ID",
	"orderer_name_tooltip": "Enter an ordering service name. This field is only used to identify the ordering service in this console.",
	"edit_orderer_name_tooltip": "Modify the name you want to use to refer to this ordering service in this console.",
	"remove_orderer_name_tooltip": "Re-enter the ordering service name to confirm",
	"new_identity_name_tooltip": "Enter a name you want to use to refer to this identity in this console. This field is only used to identify the CA admin identity in this console.",
	"peer_new_identity_name_tooltip": "Enter a name you want to use to identify the admin identity in this console.",
	"ca_new_identity_name_tooltip": "Enter the name of the identity to be added to the Wallet. This field is only used to identify the identity in the console.",
	"new_identity_cert_tooltip": "Upload a file that contains the signing certificate of this identity (in base64 or PEM format). This certificate is also referred to as your enrollment certificate or public key.",
	"ca_new_identity_cert_tooltip": "Upload a file that contains the signing certificate of this identity (in base64 or PEM format). This certificate is also referred to as your enrollment certificate or public key.",
	"new_identity_private_key_tooltip": "Upload a file that contains the private key of the identity (in base64 or PEM format).",
	"existing_identity_dropdown_tooltip": "Select an identity from the Wallet to be used to operate this node, also known as the admin of this node.",
	"ca_existing_identity_dropdown_tooltip": "Use an identity from the Wallet to register new users. You need to use a enroll ID and secret.",
	"identity_name_tooltip": "Enter the name of the identity to be added to the Wallet. This field is only used to identify the identity in the console.",
	"identity_cert_tooltip": "Upload a file that contains the signing certificate of the identity (in base64 or PEM format). This certificate is also referred to as your enrollment certificate or public key.",
	"identity_private_key_tooltip": "Upload a file that contains the private key of this identity (in base64 or PEM format).",
	"remove_peer_name_tooltip": "Re-enter the peer name to confirm",
	"remove_msp_name_tooltip": "Re-enter the MSP name to confirm",
	"enroll_id_tooltip": "Enter or select the enroll id that was specified when you registered the user with this CA. Make sure that the user type specified when registering this identity matches the role the identity will play on the network.",
	"enroll_secret_tooltip": "Enter the enroll secret that was specified when you registered the user with this CA.",
	"edit_ca_enroll_id_tooltip": "Provide the enroll ID of the CA admin.",
	"import_ca_enroll_id_tooltip": "Enter the CA admin enroll ID. The default value of this field is the CA admin enroll ID specified during the CA deployment.",
	"edit_ca_enroll_secret_tooltip": "Provide the enroll secret for the CA admin.",
	"import_ca_enroll_secret_tooltip": "Modify the identity enroll secret. The default value of this field is the CA admin secret specified during the CA deployment.",
	"server_logging": "Server logging",
	"client_logging": "Client logging",
	"peers_tooltip": "Peers host smart contracts and store your ledger. They allow your organization to transact on the network. Each organization that joins a consortium should deploy at least one peer.",
	"cas_tooltip": "CAs create identities for the nodes and admins of your organization, and for client applications. They also formally define your organization through the creation of an MSP definition. Each organization should deploy at least one CA.",
	"orderers_tooltip": "An ordering service collects transactions, orders the transactions, and bundles them into blocks. All channels have an ordering service associated with them, and an ordering service must be deployed if you are founding a consortium.",
	"installed_chaincode_tooltip": "Smart contracts are installed on peers and instantiated on channels. Use the overflow menu to the right of the contract to instantiate the smart contract.",
	"instantiated_chaincode_tooltip": "Use the options in the overflow menu of this table to upgrade the smart contract on the channel.",
	"channels_desc": "These are the channels where at least one peer has joined.",
	"channels_desc_orderer": "These are the channels where at least one orderer has joined or a pending genesis block has been created.",
	"join_channel_step1_desc": "Until a peer joins a channel the organization the peer belongs to cannot participate or learn about transactions on the channel. This channel must exist before you join peers to it. If you have not created a channel yet, you should do so now.",
	"join_channel_step2_desc": "Specify the name of the channel you want to join a peer to. You must either know the name of the channel or get it out of band (for example, from one of the organizations joined to the channel).",
	"join_channel_step3_desc": "Now that you have specified the ordering service and the name of the channel you're ready to select the peers you want to join to the <strong>{{channelName}}</strong> channel.",
	"join_channel_step3_direct_desc": "Select the peers you want to join to the <strong>{{channelName}}</strong> channel.",
	"select_orderer_desc": "All channels have an ordering service. To join a channel, you must select the ordering service associated with that channel.",
	"select_orderer_tooltip": "If you can't see the ordering service the channel is associated with you will need to get the JSON file representing the ordering service from the ordering service admin and navigate to the Nodes panel to import it into this console.",
	"select_peer_tooltip": "Joining more than one peer to a channel ensures that peers are highly available.",
	"channel_creator_msp_tooltip": "This organization must be part of the consortium of the ordering service.",
	"channel_updater_msp_tooltip": "This organization must be a member of the channel and have permission to sign this update.",
	"associate_available_identity": "Associate available identity",
	"create_channel_organization_desc": "Any organizations that are part of the consortium can be added to the channel and assigned a channel role through this panel. Organizations that are not a part of the consortium can be added later through a channel configuration update. If an organization has been added to a channel, they will see a pending tile on the channel page. By clicking on the tile, they can add a peer to the channel.",
	"update_channel_organization_desc": "Any organization, whether it is a part of the consortium or not, can be added to the channel update request through this panel and assigned a channel permission level.",
	"add_identity_name_tooltip": "Enter the name of the identity to be added to the Wallet. This field is only used to identify the identity in the console UI.",
	"add_identity_cert_tooltip": "Upload a PEM file that contains the signing certificate of the identity in base64 or PEM format. This certificate is also referred to as your enrollment certificate or public key.",
	"add_identity_private_key_tooltip": "Upload a file that contains the private key of this identity (in base64 or PEM format).",
	"edit_identity_name_tooltip": "Modify the name of the identity. This field is only used to identify the identity in this console.",
	"edit_identity_cert_tooltip": "Upload a file that contains a new signing certificate of this identity (in base64 or PEM) format. This certificate is also referred to as your enrollment certificate or public key.",
	"edit_identity_private_key_tooltip": "Upload a file that contains a new private key of this identity (in base64 or PEM format).",
	"template_name_tooltip": "The template name is a description.",
	"template_tooltip": "The template is JSON that describes your desired components/network.",
	"identities_heading_tooltip": "Identities allow you to operate your network using the console. You will need to register identities using your CA, giving them a type that matches the role the identity will play on the network, and then enroll them, either using the CA page or as part of creating an organization's MSP definition. These keys are stored in your browser storage and not on your local filesystem.",
	"create_peer": "Create a peer",
	"import_peer": "Import an existing peer",
	"saas_ca": "Certificate Authority",
	"admin_msp": "Organization MSP",
	"tls_saas_ca": "TLS Certificate Authority",
	"tls_enroll_id": "TLS enroll ID",
	"tls_enroll_id_ca": "TLS CA enroll ID",
	"tls_enroll_id_placeholder": "Enter ID",
	"tls_enroll_id_ca_placeholder": "Enter ID",
	"tls_enroll_secret": "TLS enroll secret",
	"tls_enroll_secret_ca": "TLS CA enroll secret",
	"tls_enroll_secret_placeholder": "Enter secret",
	"tls_enroll_secret_ca_placeholder": "Enter secret",
	"tls_csr_hostname": "TLS CSR hostname",
	"tls_csr_hostname_placeholder": "Enter hostname",
	"client_saas_ca": "Client TLS Certificate Authority",
	"client_enroll_id": "Client TLS enroll ID",
	"client_enroll_id_placeholder": "Enter ID",
	"client_enroll_secret": "Client TLS enroll secret",
	"client_enroll_secret_placeholder": "Enter secret",
	"error_create_peer": "Unable to create peer",
	"create_msp_definition": "Create MSP definition",
	"import_msp_definition": "Import MSP definition",
	"update_msp_definition": "Update MSP definition",
	"selectedRootCA": "Root Certificate Authority",
	"existingSelectedIdentity": "Existing identity",
	"select_ca": "Select a root Certificate Authority",
	"edit_msp_mspid_error": "The MSP ID for uploaded JSON does not match.",
	"error_occurred_during_msp_import": "An error occurred while importing MSP.",
	"error_occurred_during_msp_edit": "An error occurred while editing MSP definition.",
	"invalid_admin_certificate": "Admin certificate is invalid. Ensure that the admin certificate is valid.",
	"import_msp_definition_desc": "This will import your organization's MSP definition which can then be managed using the console. Upload the JSON file that contains the MSP information.",
	"from_ca": "CA",
	"from_tls_ca": "TLS CA",
	"update_msp_definition_desc": "Upload the JSON file that contains the updated MSP information.",
	"available_msps": "Available organizations",
	"msp_deleted": "MSP {{msp_name}} has been deleted successfully.",
	"msp_updated": "MSP {{msp_name}} has been updated successfully.",
	"msp_imported": "MSP {{msp_name}} has been imported successfully.",
	"msp_created": "MSP {{msp_name}} has been created successfully.",
	"error_create_orderer": "Unable to create ordering service",
	"choose_orderer_location": "Choose your ordering service location",
	"select_orderer_type": "Select to create or import an ordering service.",
	"create_orderer": "Create an ordering service",
	"import_orderer": "Import an existing ordering service",
	"select_orderer_node_type": "Select to create or import an ordering node.",
	"create_orderer_node": "Create an ordering node",
	"import_orderer_node": "Import an existing ordering node",
	"import_orderer_node_important": "Only ordering nodes that belong to the current ordering service may be imported.",
	"msp_actions_label_tooltip": "Click Create MSP definition to use your Certificate Authority to generate an MSP. Click Import MSP definition to add an MSP created by another organization.",
	"available_msp_tooltip": "If you are the ordering service admin, you can select from these MSPs to add organizations to the consortium, which will allow those organizations to create channels and join peers to them.",
	"generate_msp_name_tooltip": "The name of MSP to be added to the Organizations tab. This field is only used to identify the MSP in this console.",
	"generate_msp_id_tooltip": "The MSP ID is the formal name of your organization within the consortium. Remember this name for your components and applications. It should be less than 250 characters and contain only ASCII alphanumerics, dots, and dashes",
	"generate_msp_rootca_tooltip": "Select your root CA from the list of CAs managed by the console.",
	"generate_msp_rootcert_tooltip": "MSP root certificate",
	"generate_msp_tls_rootcert_tooltip": "MSP TLS root certificate",
	"generate_msp_enroll_id_tooltip": "Select the enroll ID of the organization admin you registered with the CA from the drop-down list. If you do not see a drop down, you need to associate a CA admin identity with your CA. If you have not registered an organization admin, exit and do so now.",
	"generate_msp_enroll_secret_tooltip": "Enter the enroll secret of the organization admin you registered with the CA. If you have not registered an organization admin, exit and do so now using the organization's CA.",
	"generate_msp_identity_name_tooltip": "Enter a name for the identity to be added to the wallet. This field is only used to identify the identity in this console.",
	"generate_msp_admin_tooltip": "This identity will become an admin of this organization.",
	"organizations": "Organizations",
	"select_ca_name": "Select which Certificate Authority should be used to generate the new certificate.",
	"tlsca_name": "TLS Certificate Authority name",
	"start_here": "Start here",
	"chooseNetwork": "If you plan to host a network, click Build a network. If you prefer to join a peer to an existing network, click Join a network.",
	"1buildTitle": "Create your organization and your entry point to your blockchain",
	"2buildTitle": "Create the node that orders transactions that go on the blockchain",
	"3buildTitle": "Add your organization to list of organizations that can transact",
	"4buildTitle": "Create a channel",
	"5buildTitle": "Join your peer to the channel",
	"2buildDesc": "As with Step 1, you must first create a Certificate Authority and the relevant identities for the ordering node before you can deploy it. You will perform the following tasks:",
	"3buildDesc": "Open the ordering service you created in the previous step so that you can add the peer organization you created in Step 1 to the list of organizations that can transact.",
	"4buildDesc": "Channels are required for organizations to transact on the blockchain.",
	"5buildDesc": "Before your peer can transact, it must join the channel.",
	"1buildSteps": "– Create a CA\n– Use the CA to register peer and peer admin identities \n– Create the peer's organization \n– Create a peer",
	"2buildSteps": "– Create a CA\n– Use the CA to register ordering service, ordering service admin identities\n– Create the ordering service organization\n– Create an ordering service",
	"1buildLink": "{{test__ca--add--tile}}",
	"2buildLink": "{{test__ca--add--tile}}",
	"3buildLink": "((orderers-container))",
	"4buildLink": "[[/channels]]",
	"5buildLink": "[[/channels]]",
	"1buildButton": "Add Certificate Authority",
	"2buildButton": "Add Certificate Authority",
	"3buildButton": "Show ordering service",
	"4buildButton": "Create channel",
	"5buildButton": "Join channel",
	"1joinDocs": "{{DOC_PREFIX}}?topic=started-join-network",
	"join_network_hlfoc": "{{DOC_PREFIX}}/channel_update_tutorial.html",
	"develop_vs_code_link": "{{DOC_PREFIX}}?topic=how-developing-smart-contracts-applications",
	"develop_vs_code_link2": "{{DOC_PREFIX}}/chaincode4ade.html",
	"deploy_sm_hlfoc": "{{DOC_PREFIX}}/deploy_chaincode.html",
	"2deployDocs": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"3deployDocs": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"4deployDocs": "{{DOC_PREFIX}}?topic=tutorials-deploying-smart-contracts",
	"api_docs_link": "https://developer.ibm.com/apis/catalog/fabricopsconsole--fabric-operations-console-apis/Introduction",
	"api_doc_hlfoc": "https://developer.ibm.com/apis/catalog/fabricopsconsole--fabric-operations-console-apis/Getting+Started",
	"mainDocs": "{{DOC_PREFIX}}?topic=tutorials-getting-started",
	"mainDocs2": "{{DOC_PREFIX}}",
	"tlsca_name_placeholder": "Enter the TLS Certificate Authority name",
	"selectedFunction": "Function name",
	"enter_function_placeholder": "Enter the function name. For example: init",
	"enter_function_tooltip": "The function name argument is tested by Init and Invoke methods to determine which other function to call.",
	"registered_users_tooltip": "Click the register user button to create a new identity. Make sure that the type given to this identity matches the role the identity will play on the network, either an admin, peer, orderer, or client. You can use identities listed in the table below to generate a certificate and private key by clicking the enroll button in the overflow menu.",
	"add_user_enroll_id_tooltip": "Create an enroll ID for the new identity. Do not reuse the ID you entered when creating the CA. Save this value.",
	"add_user_enroll_secret_tooltip": "Create a secret for the new identity. Save this value.",
	"add_user_max_enrollment_tooltip": "The number of times you can generate keys for this identity. If left blank, it defaults to unlimited enrollments (-1).",
	"ca_details_tooltip": "Associate an identity by clicking the settings button in order to operate your CA. Once you associate an identity, click enroll to generate keys for that identity.",
	"ca_actions_tooltip": "Click <strong>Associate identity</strong> to sign in with identity you want to enroll and use to register new users.\n\nClick <strong>Export</strong> to download the CA details in a JSON File. \n\n Click <strong>Remove</strong> to delete this CA.",
	"msp_heading_tooltip": "Organizations are defined by a Membership Services Provider (MSP). An MSP definition is created by the CA of a particular organization and includes at least one administrator certificate from that organization.",
	"ca_new_identity_upload_json": "Use the add file button if the identity was exported from another Wallet.",
	"import_ca_name_tooltip": "Enter a CA name. This field is only used to identify the CA in this console.",
	"admin_id_tootip": "Enter an enroll ID for your CA admin. Save this value. You will need it operate the CA.",
	"admin_secret_tootip": "Enter an enroll secret for your CA admin. Save this value. You will need it operate the CA.",
	"channel_acl_desc": "To specify a finer grained control over resources, you can restrict access to a resource to an organization and a role within that organization. For example, setting access to the resource \"ChaincodeExists\" to \"Application/Admins\" would mean that only the admin of an application would be able to access the ChaincodeExists resource. You can specify access control manually or in bulk by uploading a JSON file. For more information about Access Control, check out the [[Hyperledger Fabric documentation]](_ACL_LINK01).",
	"_ACL_LINK01": "https://hyperledger-fabric.readthedocs.io/en/release-2.4/access_control.html",
	"Admins": "Admins",
	"Readers": "Readers",
	"Writers": "Writers",
	"selectedACLResource": "Resource",
	"selectedACLPolicy": "Organization",
	"selectedACLRole": "Role",
	"policy_definition": "Policy definition",
	"acl_invalid": "Invalid ACL",
	"acl_invalid_desc": "ACL Policy definition {{invalidDef}} is invalid and should be removed as member {{deletedOrg}} is no longer member of this channel.",
	"validation_mspid_invalid": "The MSP ID can contain only alphanumerics characters, or dashes, and should be shorter than 64 characters.",
	"mspid_required": "MSP ID is required.",
	"error_tls_ca_users": "Unable to retrieve the list of TLS users.",
	"tls_ca": "TLS Certificate Authority",
	"saas_peer_certificate_auth_tooltip": "Select the CA that you used to register the peer identity.",
	"saas_peer_enroll_id_tooltip": "Select the enroll ID of the peer identity that you registered with the CA from the drop-down list. If you do not see a drop down, you need to associate the CA admin identity with your peer’s CA. If you have not registered a peer identity (user), exit this panel and do so now. This identity must be registered and given the peer type.",
	"saas_peer_enroll_secret_tooltip": "Enter the enroll secret of the peer identity that you registered with the CA. If you have not registered a peer identity, please do so now. Do not reuse the identity of the CA admin.",
	"saas_peer_admin_cert_tooltip": "Select the MSP definition you created for your organization. This MSP contains an organization admin certificate. If you have not created an MSP definition for this organization, please navigate to the Organizations tab and do so now. Do not reuse another MSP.",
	"saas_peer_tls_cert_auth_tooltip": "This is the CA that will provide your peer's TLS certificates. Any CA created using the console will include a TLS CA, so select the same CA name in this field as your root CA.",
	"saas_peer_tls_enroll_id_tooltip": "Select the enroll ID of the peer itself (not the CA admin or peer organization admin) from the drop-down list.",
	"saas_peer_tls_enroll_secret_tooltip": "Enter the enroll secret for the ID you used above.",
	"saas_peer_tls_csr_hostname_tooltip": "(Advanced option) TLS Certificate Signing Request (CSR) hostname. Specify a custom domain name for your node. For example, CommercialPaperNet.com.",
	"saas_peer_client_tls_ca_tooltip": "Setting up a dedicated client TLS CA is not done by default, but if you have created one and imported it, select it from the drop down list. Otherwise, select the name of your CA and then click Next at the bottom of the slider.",
	"saas_peer_client_tls_enroll_id_tooltip": "Select the enroll ID that you registered with your client TLS CA from the drop-down list. If you are using the TLS CA created during the CA creation process, leave this field blank.",
	"saas_peer_client_tls_enroll_secret_tooltip": "Enter the enroll secret registered with your client TLS CA. If you are using the TLS CA created during the CA creation process, leave this field blank.",
	"saas_orderer_display_name_tooltip": "Enter an ordering service name. This field is only used to identify the ordering service in this console.",
	"saas_orderer_ca_tooltip": "Select the CA that you used to register the ordering service identity.",
	"saas_orderer_enroll_id_tooltip": "Select the enroll ID of the ordering service identity that you registered with the CA from the drop-down list. If you do not see a drop down, you need to associate the CA admin identity with your ordering service CA. If you have not registered an ordering service identity, exit and do so now. This identity must be registered and given the orderer type",
	"saas_orderer_enroll_secret_tooltip": "Enter the enroll secret of the ordering service identity that you registered with the CA. If you have not registered an ordering service identity, exit and do so now.",
	"saas_orderer_admin_cert_tooltip": "Select the MSP definition you created for your organization. This MSP contains an organization admin certificate. If you have not created an MSP definition for this organization, please navigate to the Organizations tab and do so now. Do not reuse another MSP.",
	"saas_orderer_tls_cert_auth_tooltip": "This is the CA that will provide your ordering service's TLS certificates. Any CA created using the console will include a TLS CA, so select the same CA name in this field as your root CA.",
	"saas_orderer_tls_enroll_id_tooltip": "Select the enroll ID of the ordering service itself (not the CA admin or ordering service organization admin) from the drop-down list.",
	"saas_orderer_tls_enroll_secret_tooltip": "Enter the enroll secret for the ID you used above.",
	"saas_orderer_tls_csr_hostname_tooltip": "(Advanced option) TLS Certificate Signing Request (CSR) hostname. Specify a custom domain name for your node. For example, CommercialPaperNet.com.",
	"saas_orderer_client_tls_ca_tooltip": "Setting up a dedicated client TLS CA is not a default option, but if you have created one and imported it, select it from the drop down list. Otherwise, select the name of your CA and then click Next at the bottom of the slider.",
	"saas_orderer_client_tls_enroll_id_tooltip": "Enter the enroll ID registered with your client TLS CA. If you are using the TLS CA created during the CA creation process, leave this field blank.",
	"saas_orderer_client_tls_enroll_secret_tooltip": "Enter the enroll secret registered with your client TLS CA. If you are using the TLS CA created during the CA creation process, leave this field blank.",
	"orderer_existing_identity_tooltip": "If you have an admin identity exported to the Wallet, you can select it here.",
	"orderer_new_identity_name_tooltip": "If you want to associate a new identity and make it the admin of your ordering service, you can enter its information.",
	"orderer_new_identity_certificate_tooltip": "Upload a file that contains the certificate of the ordering service admin (in base64 or PEM format).",
	"orderer_new_identity_private_key_tooltip": "Upload a file that contains the private key of the ordering service admin (in base64 or PEM format).",
	"orderer_new_identity_upload_json": "If you want to import a JSON file with the certificate and private key of the ordering service inside of it, you can do so here.",
	"reenroll": "Re-enroll identity",
	"import_orderer_desc_1": "An ordering service collects transactions, orders the transactions, and bundles them into blocks. All channels have an ordering service associated with them, and an ordering service must be deployed if you are founding a consortium.",
	"import_orderer_desc_2": "Before creating an ordering service, you must first create a Certificate Authority (CA) and use that CA to create identities for the ordering service organization admin and for the ordering nodes themselves. Then you must create an organization definition for your ordering service organization that uses the ordering service organization admin identity.",
	"IMPORT_ORDERER_DESC_LINK": "{{DOC_PREFIX}}?topic=console-importing-nodes-msps-identities",
	"ibm_id": "IBMid",
	"oauth": "OAuth2.0",
	"ibm_cloud_desc": "Authentication is handled by IBMid",
	"ibm_cloud_perm_desc": "Authorization is handled by IBM's IAM",
	"oauth_desc": "Authentication is handled by an external service",
	"oauth_perm_desc": "Authorization is handled by the console",
	"local_desc": "Authentication is handled by the console",
	"local_perm_desc": "Authorization is handled by the console",
	"empty_msps_title": "No organizations available",
	"empty_acl_title": "No Access Control List",
	"empty_anchor_title": "No anchor peers",
	"empty_peer_channels_title": "No channels",
	"empty_msps_text": "Get started by creating an MSP definition for your organization. You will need to register an identity with a Certificate Authority first.",
	"empty_channels_title": "No channels available",
	"empty_channels_text": "You can create a channel using orderers you have provisioned or imported, or you can join a channel if you have the relevant details.",
	"empty_pending_channels_title": "No pending channels",
	"empty_pending_channels_text": "You can create a channel using orderers you have provisioned or imported.",
	"empty_cp_channels_title": "No channels available",
	"empty_cp_channels_text": "Join an orderer to a channel to view channels.",
	"empty_cp_channels_text_drilldown": "Join this orderer to a channel to view channels.",
	"empty_identities_title": "No identities available",
	"empty_identities_text": "Get started by creating a Certificate Authority and an organization MSP definition. You can then view your organization admin identity here and use it to interact with your network. You can also import identities if you have changed browsers.",
	"empty_installed_title": "No installed smart contracts",
	"empty_installed_text": "Get started by installing your smart contract on peers in your console.\nClick Install smart contract to browse to your smart contract package and install it on your peer.",
	"empty_instantiated_title": "No instantiated smart contracts",
	"empty_instantiated_text": "Get started by instantiating your smart contract on a channel in your console.\nBefore a smart contract can be instantiated, it must be installed on a peer and the peer must have joined a channel.",
	"empty_peer_chaincode_message": "It looks like you have not installed any smart contracts on this peer yet.\nClick the Smart contracts tab and install a smart contract on your peer.",
	"empty_peer_chaincode_title": "No installed smart contracts",
	"empty_tls_users_title": "Unable to retrieve the list of TLS users",
	"empty_tls_users_text": "You have entered an incorrect enroll ID or secret, or you do not have proper access to this list of users.\nClick the settings icon for this CA to properly set the identity.",
	"empty_ca_users_title": "Unable to retrieve the list of CA users",
	"empty_ca_users_text": "You have entered an incorrect enroll ID or secret, or you do not have proper access to this list of users.\nClick the settings icon for this CA to properly set the identity.",
	"error_identities_required": "The JSON should have the identities property.",
	"error_policy_required": "The JSON should have the policy property.",
	"select_msp": "Select an MSP",
	"error_add_msp_first": "No MSP definitions available. Create one using the Organizations tab.",
	"error_add_orderer_first": "No ordering services have been found. Create one or import one using the Nodes tab.",
	"error_orderer_needed": "Ordering service needed",
	"pem_tooltip": "Upload a file that contains the base64 encoded string of your TLS root certificate (in base64 or PEM format).",
	"log_to_file": "Log to file",
	"client_log_level": "Client log level",
	"server_log_level": "Server log level",
	"log_performance_warning": "Turning on 'debug' levels will impact performance.",
	"root_ca_subtext": "Your CA provides keys to your nodes and applications. Use this CA to register the node, admin and application identities that are required to deploy, operate, and interact with your network.",
	"enrolling_users": "Enrolling Users",
	"node_ou": "Node OU",
	"node_ou_param": "Node OU: {{state}}",
	"add_anchor_peer": "Add anchor peer",
	"add_anchor_peer_desc": "Select at least one peer from each organization to enable peers from different organizations to communicate automatically with each other.",
	"selectedPeers": "Choose from available peers",
	"error_adding_anchor_peers": "An error occurred when adding anchor peers.",
	"channel_anchor_peers_tooltip": "The anchor peers associated with this channel. Anchor peers are required if you plan to use private data or service discovery.",
	"channel_acls_tooltip": "The ACLs associated with this channel. Click Settings to add or update the ACLs.",
	"instantiated_chaincode_table_tooltip": "The smart contracts that have been instantiated on this channel.",
	"channel_members_tooltip": "The organizations that have joined this channel.",
	"orderer_msps_tooltip": "The organizations administering this ordering service.",
	"nodes_tooltip": "The nodes that have been associated with this channel.",
	"selectedPeers_tooltip": "For redundancy reasons, consider adding more than one peer per organization as the anchor peer.",
	"support": "Support",
	"_SUPPORT_LINK": "https://console.bluemix.net/docs/get-support/howtogetsupport.html#getting-customer-support",
	"contact_support": "Contact support",
	"ca_not_available_title": "Unable to connect to Certificate Authority (CA)",
	"ca_not_available_text": "It can take several minutes for a CA to start. If you encounter issues, check your CA logs and verify you have enough CPU, memory and storage available to the cluster.",
	"peer_not_available_title": "Unable to connect to peer",
	"peer_not_available_text": "It can take several minutes for a peer to start. If you encounter issues, check your peer logs and verify you have enough CPU, memory and storage available to the cluster.",
	"peer_not_available_logs": "Click [[here]]({{_PEER_LOGS_LINK}}) for more information about accessing the peer logs.",
	"_PEER_LOGS_LINK": "{{DOC_PREFIX}}?ibp-console-manage-console#ibp-console-manage-console-node-logs",
	"_PEER_LOGS_LINK_SW": "{{DOC_PREFIX}}?console-icp-manage#console-icp-manage-node-logs",
	"orderer_not_available_title": "Unable to connect to ordering service",
	"orderer_not_available_text": "It can take several minutes for an ordering service to start. If you encounter issues, check your ordering node logs and verify you have enough CPU, memory and storage available to the cluster.",
	"no_more_peers": "There are currently no more peers available to configure as anchor peers.",
	"smart_contract_exists": "Smart contract is already installed on the peer.",
	"error_delete_peer": "Unable to delete the peer.",
	"error_delete_ca": "Unable to delete the Certificate Authority.",
	"error_delete_orderer": "Unable to delete the ordering service",
	"input_error_invalid_char": "The value contains one or more invalid characters",
	"error_invalid_json_char": "A field ({{field}}) contains one or more invalid characters.",
	"error_invalid_field_value": "A field ({{field}}) contains an invalid value.",
	"generate_cert_title": "Use this option to generate a public and private key for a user that has already been registered with the CA. This is the second step in the process of creating a new identity that saves the identity to the Wallet and exports the identity to the file system.",
	"register_user_title": "Select \"Register user\", to register a new user with your CA, the first step in creating a new identity. During registration, an enroll ID and secret are created that can later be used by a node or an organization admin to generate a public and private key to enroll the identity. This identity must be given a type that corresponds to the role the user will play on a network. If an identity will be an admin, register it with the type of admin. If an identity will be enrolled as a peer, use the peer type.",
	"reenroll_cert_title": "Use this option to re-enroll the admin identity that is currently the associated identity for this CA if the certificate is about to expire or has been compromised.",
	"config_json": "JSON service credentials",
	"input_error_no_special_first_char": "The value may not start with a special character.",
	"input_error_max_length_30": "The value may not be more than 30 characters in length.",
	"input_error_max_length_64": "The value may not be more than 64 characters in length.",
	"input_error_no_leading_whitespace": "The value may not start with a whitespace character.",
	"input_error_no_trailing_whitespace": "The value may not end with a whitespace character.",
	"input_error_no_double_quote": "The value may not contain a double quote character.",
	"input_error_non_whitespace": "The value may not contain a whitespace character.",
	"input_error_non_numeric": "The value is not valid.",
	"input_error_non-url-encode": "The value may not contain special characters other than $-_.+!*'(),;@&",
	"json_no_special_first_char": "The value of '{{field}}' may not start with a special character.",
	"json_max_length_30": "The value of '{{field}}' may not be more than 30 characters in length.",
	"json_max_length_64": "The value of '{{field}}' may not be more than 64 characters in length.",
	"json_no_leading_whitespace": "The value of '{{field}}' may not start with a whitespace character.",
	"json_no_trailing_whitespace": "The value of '{{field}}' may not end with a whitespace character.",
	"json_non_whitespace": "The value of '{{field}}' must contain a non-whitespace character.",
	"json_non_numeric": "The value of '{{field}}' is not valid.",
	"error_join_channel_not_found": "The channel was not found.",
	"update_orderer": "Update ordering service",
	"update_orderer_node": "Update ordering node",
	"update_ca": "Update Certificate Authority",
	"add_consortium_member": "Add consortium member",
	"orderer_display_name": "Ordering service display name",
	"orderer_display_name_placeholder": "Enter an ordering service display name",
	"ca_display_name": "CA display name",
	"ca_display_name_placeholder": "Enter a CA display name",
	"peer_display_name": "Peer display name",
	"peer_enroll_id": "Peer enroll ID",
	"peer_enroll_secret": "Peer enroll secret",
	"orderer_enroll_id": "Ordering service enroll ID",
	"orderer_enroll_secret": "Ordering service enroll secret",
	"ca_admin_enroll_id": "CA administrator enroll ID",
	"ca_admin_enroll_id_placeholder": "Enter enroll ID",
	"ca_admin_enroll_secret": "CA administrator enroll secret",
	"ca_admin_enroll_secret_placeholder": "Enter enroll secret",
	"associated_identity": "Associated identity",
	"console_identities_docs": "{{DOC_PREFIX}}?topic=console-creating-managing-identities",
	"smart_contracts_docs": "{{DOC_PREFIX}}?topic=contracts-deploy-smart-fabric-v2x",
	"upgrade_smart_contract_docs": "{{DOC_PREFIX}}?topic=applications-writing-powerful-smart-contracts",
	"instantiate_smart_contract_docs": "{{DOC_PREFIX}}?topic=applications-writing-powerful-smart-contracts",
	"orderer_admins_tooltip": "The list of the MSP IDs of the organizations that are authorized to update the configuration of this ordering node.",
	"orderer_members_tooltip": "The list of MSP IDs that can be added to the channels that are managed by this ordering node.",
	"buildNetwork": "Build a network",
	"joinNetwork": "Join a network",
	"deploySmartContract": "Deploy a smart contract on the network",
	"tls_identity": "(TLS)",
	"root_ca_short": "Root CA",
	"tls_ca_short": "TLS CA",
	"set_tls_identity": "Set TLS identity",
	"needCADesc": "No CA found. Create or import one from the Nodes tab.",
	"show_password": "Show",
	"hide_password": "Hide",
	"hide": "Hide",
	"select_orderer_description": "All channels have an ordering service. To update the channel, you must select the ordering service associated with that channel.",
	"select_orderer_2": "Select an ordering service",
	"json_upload_description": "Upload the JSON file that contains the updated MSP information.",
	"json_upload_tooltip": "Updating an MSP allows a channel member to change their name of the MSP, root certificate (from the root CA), and the certificate representing the organization's admin",
	"update_channel_msp_desc": "The definition of an organization, as expressed through the MSP, can be updated by uploading a new definition.",
	"no_msp_changes": "An MSP definition can only be updated if there are changes to the definition.",
	"msp_def_updated": "The MSP definition for {{msp_name}} has been updated successfully.",
	"orderer_msp_def_update_proposed": "The proposal for updating MSP definition for {{msp_name}} has been submitted successfully.",
	"invalid_json": "The uploaded MSP definition is not valid.",
	"select_new_def": "Select new definition",
	"select_new_def_desc": "Select an existing organization MSP ID from this console or upload a new one by updating the JSON of an MSP file you've exported.",
	"selectedMsp": "MSP",
	"select_msp_id": "Select MSP",
	"error_unexpected": "An unexpected error occurred.",
	"show_details": "Show error details",
	"hide_details": "Hide error details",
	"invalid_enroll_id_secret": "Unable to authenticate with the enroll id and secret you provided.",
	"enroll_identity_name_tooltip": "Enter the name of the identity to be added to the Wallet. This field is only used to identify the identity in the console.",
	"enroll_identity_cert_tooltip": "This is the signing certificate of the identity in base64 format. This certificate is also referred to as your enrollment certificate or public key.",
	"enroll_identity_private_key_tooltip": "This is the private key of the identity in base64 format.",
	"identity_display_name": "Identity display name",
	"advanced_desc": "Click [[here]](_CHANNEL_ADVANCED_CONFIG) for more information on updating the channel configuration.",
	"_CHANNEL_ADVANCED_CONFIG": "https://hyperledger-fabric.readthedocs.io/en/latest/config_update.html",
	"advanced_config_desc": "Use the fields on this panel to customize transaction batch size for the ordering service. These parameters control the number and size of transactions in a block. The timeout field specifies the amount of time to wait after the first transaction arrives for additional transactions before cutting a block. Modification of these values will affect the throughput and performance of your ordering service.",
	"advanced_config_docs": "https://hyperledger-fabric.readthedocs.io/en/latest/config_update.html",
	"advanced_config": "Advanced channel configuration",
	"absolute_max_bytes": "Absolute max bytes",
	"absolute_max_bytes_placeholder": "e.g., 10485760",
	"absolute_max_bytes_tooltip": "The maximum size of any block. This value must be less than {{absolute_max_bytes_max}}.",
	"absolute_max_bytes_validation_error": "The Absolute max bytes must be less than {{absolute_max_bytes_max}}",
	"block_params_warning": "When you modify these parameters, you do not affect the behavior of existing channels on the ordering service; rather, any changes you make to the configuration of the ordering service apply only to new channels you create that use this ordering service.",
	"max_message_count": "Max message count",
	"max_message_count_placeholder": "e.g., 500",
	"max_message_count_tooltip": "The maximum number of transactions that can exist in a block before a new block is cut. This must be numeric value between {{max_message_count_min}} and {{max_message_count_max}}.",
	"max_message_count_validation_error": "The Maximum message count must be numeric value between {{max_message_count_min}} and {{max_message_count_max}}",
	"preferred_max_bytes": "Preferred max bytes",
	"preferred_max_bytes_placeholder": "e.g.,2097152",
	"preferred_max_bytes_tooltip": "The preferred block size. If it is possible to construct a block under this size, then a block will be cut, and transactions larger than this size will appear in their own block. Generally, this value should be equivalent to the maximum message count * expected transaction size.",
	"preferred_max_bytes_validation_error": "The preferred max bytes must be between {{preferred_max_bytes_min}} and {{preferred_max_bytes_max}}",
	"timeout": "Timeout",
	"timeout_placeholder": "e.g., 2s",
	"timeout_tooltip": "Decreasing this value will improve latency, but decreasing it too much may decrease throughput by not allowing the block to fill to its maximum capacity. The value of the timeout must be between {{timeout_min}} to {{timeout_max}}.",
	"timeout_validation_error": "The timeout must be between {{timeout_min}} to {{timeout_max}}.",
	"update_config": "Update channel configuration",
	"error_updating_block_params": "An error occurred when updating the block parameters",
	"third_party_ca": "Use your own CA certificate and private key",
	"third_party_peer_tooltip": "Select this option if you prefer to use a certificate and private key from a CA that was not deployed by Fabric Operations Console for this peer. Before selecting this option, you need to build a JSON file that contains the certificate and private key.",
	"third_party_orderer_tooltip": "Select this option if you prefer to use a certificate and private key from a CA that was not deployed by Fabric Operations Console for this ordering service. Before selecting this option, you need to build a JSON file that contains the certificate and private key.",
	"third_party_peer_private_key_tooltip": "Upload your private key corresponding to the signed certificate from your third party CA that this peer will use. The certificate can be in PEM format or base64 encoded.",
	"third_party_orderer_private_key_tooltip": "Upload your private key corresponding to the signed certificate from your third party CA that this ordering service will use. The certificate can be in PEM format or base64 encoded.",
	"third_party_peer_cert_tooltip": "Upload a file that contains the public signing certificate from your external CA that the peer will use. The certificate can be in PEM format or base64 encoded.",
	"third_party_orderer_cert_tooltip": "Upload a file that contains the public signing certificate from your external CA that the ordering service will use. The certificate can be in PEM format or base64 encoded.",
	"third_party_msp_tooltip": "Select the organization MSP that you created using your external CA certificates and imported into the Organizations tab in the console. ",
	"third_party_orderer_tls_private_key_tooltip": "Upload a file that contains the private key corresponding to the signed certificate from your external TLS CA that will be used by this ordering service for secure communications with other members on the network. The certificate can be in PEM format or base64 encoded.",
	"third_party_peer_tls_private_key_tooltip": "Upload a file that contains the private key corresponding to the signed certificate from your external TLS CA that will be used by this peer for secure communications with other members on the network. The certificate can be in PEM format or base64 encoded.",
	"third_party_peer_tls_cert_tooltip": "Upload a file that contains the public signing certificate created by your external TLS CA that will be used by this peer. The certificate can be in PEM format or base64 encoded.",
	"third_party_orderer_tls_cert_tooltip": "Upload a a file that contains the public signing certificate created by your external TLS CA that will be used by this ordering service. The certificate can be in PEM format or base64 encoded.",
	"third_party_tls_root_tooltip": "(Optional) Upload a file that contains the root certificate of your external TLS CA. You must provide either a TLS CA certificate or a TLS intermediate CA certificate. The certificate can be in PEM format or base64 encoded.",
	"third_party_tls_intermediate_tooltip": "(Optional) If you are using an intermediate TLS CA, upload a file that contains the intermediate TLS certificate from your intermediate TLS CA. You must provide either a TLS CA certificate or an intermediate TLS CA certificate. The certificate can be in PEM format or base64 encoded.",
	"third_party_ca_docs": "{{DOC_PREFIX}}?topic=ha-building-high-availability-certificate-authority-ca",
	"tls_root_or_intermediate_required": "Either the TSL root certificate or TSL intermediate certificate must be provided.",
	"peer_identity": "Associated identity for peer",
	"peer_organization": "Peer organization",
	"upload_pem": "Upload PEM",
	"input_error_base64_required": "The value must be base 64 encoded.",
	"input_error_file_too_big": "The file is too large.",
	"input_error_file_reader": "The file could not be read.",
	"tls_private_key": "TLS private key",
	"tls_certificate": "TLS certificate",
	"root_certificate": "Root certificate",
	"tls_root_certificate": "TLS root certificate",
	"tls_intermediate_certificate": "TLS intermediate certificate",
	"pending_channel_message": "Pending Channel - join a peer",
	"pending_channel_message_orderer": "Pending Channel - join an orderer",
	"archived_channel_message_orderer": "Joined Channel - {{date}}",
	"browse_channels_tab": "Browse Orderer Channels",
	"export": "Export",
	"third_party_ca_peer_header": "Use this panel to upload files that contain the certificates for your peer that were obtained from the CA that is not managed by this console. Then select the organization that this peer belongs to from the list of MSPs.",
	"third_party_ca_orderer_header": "Use this panel to upload files that contain the certificates for your ordering service that were obtained from the CA that is not managed by this console. Then select the organization that this ordering service belongs to from the list of MSPs.",
	"third_party_ca_tls_peer_header": "Transport Layer Security (TLS) enables secure communications between all members of the network and therefore each node is required to have its own TLS certificates. Use this panel to upload files that contain the TLS certificates that will be used by this peer.",
	"third_party_ca_tls_orderer_header": "Transport Layer Security (TLS) enables secure communications between all members of the network and therefore each node is required to have its own TLS certificates. Use this panel to upload files that contain the TLS certificates that will be used by this ordering service.",
	"identity_orderer_error": "An identity has not been associated with this ordering service",
	"third_party_ca_peer_warning": "Before proceeding, you must define an organization for this peer by building an MSP definition file that includes certificates from the external CA and then import that file into the Organizations tab.",
	"third_party_ca_orderer_warning": "Before proceeding, you must define an organization for this ordering service by building an MSP definition file that includes certificates from the external CA and then import that file into the Organizations tab.",
	"_THIRD_PARTY_CA_LINK": "{{DOC_PREFIX}}?topic=ha-building-high-availability-certificate-authority-ca",
	"product_label_notes": "Fabric Operations Console release notes",
	"released": "Released: ",
	"patch_available": "Upgrade available",
	"fabric_version": "Fabric version",
	"patch_fabric_version": "Upgrade Fabric version",
	"choose_a_fabric_version": "Select a Fabric version",
	"one_node_down": "One of the orderer nodes is down. Ensure that all nodes are running before proceeding with upgrade. Otherwise you might lose quorum.",
	"down_node": "{{node}} is down. Ensure that the node is running before proceeding with upgrade. Otherwise you might lose quorum.",
	"down_nodes": "{{node}} are down. Ensure that all nodes are running before proceeding with upgrade. Otherwise you might lose quorum.",
	"fabric_version_ca_tooltip": "It is recommended to deploy a node using the highest available Fabric version. This will ensure that you have access to the latest Fabric fixes and features.",
	"fabric_version_orderer_tooltip": "It is recommended to deploy a node using the highest available Fabric version. This will ensure that you have access to the latest Fabric fixes and features.",
	"fabric_version_peer_tooltip": "It is recommended to deploy a node using the highest available Fabric version. This will ensure that you have access to the latest Fabric fixes and features. Nodes at different Fabric versions are compatible with each other.",
	"upgrade_node_desc": "This action will upgrade {{name}} to the selected Fabric version. You cannot roll back the upgrade after it has been applied.",
	"fabric_node_to_be_updated": "Fabric node to be updated",
	"new_version": "Upgrade to",
	"view_release_notes": "View Release notes",
	"release_notes_docs": "{{DOC_PREFIX}}?topic=help-patch-history",
	"node_unavailable_during_upgrade_title": "Node unavailable during upgrade",
	"node_unavailable_during_upgrade_desc": "The node is not able to process transactions during the upgrade. To avoid disruptions to your network, it is recommended to have another node available to respond to transactions or requests.",
	"peer_v1_v2_upgrade_title": "Peer state database warning",
	"peer_v1_v2_upgrade": "This action requires the peer to rebuild the state database for each channel it has joined. This can take a significant amount of time to complete depending on the block height of each channel.",
	"peer_v1_v2_upgrade_long": "This action requires the peer to rebuild the state database for each channel it has joined. This can take a significant amount of time to complete depending on the block height of each channel.",
	"confirm_patch_desc": "This action cannot be undone. This will permanently upgrade {{name}} to version {{version}}. This will stop any transactions during the upgrade, which can take up to a minute to complete.",
	"peer_breaking_upgrade_title": "Breaking upgrade",
	"peer_breaking_title": "Version Warning",
	"peer_breaking_upgrade": "The selected peer version is not always compatible with Node.js v8 chaincode (which was often used by Fabric v1.4). You should first upgrade to a peer that is compatible (such as v2.2.10). After updating your peer, update any node chaincode to be compatible with Node.js v16. Then you may upgrade your peer to the selected version.",
	"peer_breaking_upgrade_2": "The selected peer version is not compatible with Node.js v8 chaincode. You should first update all node chaincode to be compatible with Node.js v16. Then you may upgrade your peer to the selected version.",
	"peer_breaking": "The selected peer version is not always compatible with Node.js v8 chaincode (which was often used by Fabric v1.4). It's recommended to use a version that is compatible (such as v2.2.10). This warning can be ignored if all node chaincode is compatible with Node.js v16.",
	"breaking_link_text": "Fabric documentation details",
	"ignore_breaking_txt": "I understand this is a potentially breaking change. Upgrade anyway.",
	"confirm_upgrade_node_name": "Node to be upgraded",
	"confirm_upgrade_node_name_placeholder": "Type node name here",
	"identity_and_access_management": "Identity and Access Management (IAM)",
	"administrator_contact": "Contact email",
	"administrator_contact_desc": "This panel displays the email address of the console administrator. All new login requests are sent to this id.",
	"access_iam_title": "Access controlled by \nIdentity and Access Management (IAM)",
	"access_iam_desc": "Use IAM within IBM Cloud to grant new users access to this Fabric Operations Console.",
	"iam_invite_users": "{{BMIX_HOST}}/iam#/users",
	"iam_assign_access": "Assign access in IAM",
	"update_configuration": "Update authentication settings",
	"password_invalid": "Password is not valid.",
	"admin_details": "Administrator details",
	"empty_transactions_title": "No transactions yet.",
	"error_getting_templates": "An error occurred while getting templates.",
	"error_template_invalid": "The template JSON is not valid.",
	"templates_heading_tooltip": "Templates heading tooltip goes here.",
	"empty_templates_title": "No templates available",
	"empty_templates_text": "Empty templates text goes here.",
	"enroll_ids": "Enroll identities",
	"template_started": "Template '{{name}}' submitted.",
	"template_failed": "Template '{{name}}' failed.",
	"template_complete": "Template '{{name}}' complete.",
	"import_complete": "Identities imported.",
	"import_failed": "Identities not imported.",
	"manager": "Manager",
	"specify_email_address": "Enter at least one username",
	"specify_user_ids": "User IDs",
	"specify_roles": "Roles",
	"manager_role_desc": "The manager role has all the reader and writer permissions. They can also add/remove user access to the console, and edit other console settings.",
	"writer_role_desc": "The writer role has all the reader permissions. They can also deploy, delete, import, export, and configure components (CA's, peers, and orderers). They can also create, delete and enroll identities on CAs.",
	"reader_role_desc": "The reader role can view the console UI, view the channel and ledger information, and can export components (CA's, peers, and orderers).",
	"user_email_address_tooltip": "You may enter several usernames separated with a comma",
	"user_ids_tooltip": "Enter any string.",
	"user_role_tooltip": "All of the usernames entered above will have the role chosen here. If you wish to give different users different roles, you will have to invite each batch of users separately.",
	"edit_user_role_tooltip": "Pick the roles this user should have.",
	"apikey_role_tooltip": "Pick the roles this key should have.",
	"userEmail": "Username",
	"userEmail_placeholder": "",
	"pending_user_icon_txt": "New",
	"current_user_icon_txt": "You",
	"pending_user_title": "Users with the *New* label cannot view the console yet, but they were able to login with your external OAuth2 service. You may delete their request (trash bin icon), or assign them a role to grant them access.",
	"update_role": "Edit roles of user",
	"user_update_successful": "Role for {{email}} updated successfully.",
	"error_user_role_edit": "An error occurred while updating the user role.",
	"error_add_users": "An error occurred while adding new users.",
	"error_add_apikey": "An error occurred while adding the new api key.",
	"certificate_Authority_node_title": "Certificate Authority (CA)",
	"ca_identity_tls": "Associated identity for TLS CA",
	"ca_identity_root": "Associated identity for root CA",
	"ordering_service_identity": "Associated identity for ordering service",
	"ordering_service_title": "Ordering Clusters",
	"cluster_names": "Consenter Clusters",
	"consenters": "Consenters",
	"peer_location": "Node location",
	"node_location": "Node location",
	"node_version_title": "Fabric version",
	"version_not_found": "Version not found",
	"zone_not_found": "Zone not found",
	"fetching": "Fetching",
	"status_pending": "Status pending",
	"no_identity_associated": "No identity associated",
	"details": "Details",
	"comp_settings_desc": "View component settings",
	"comp_export_desc": "Export the component",
	"resource_allocation": "Resource allocation",
	"allocation": "Allocation",
	"reallocate": "Reallocate",
	"reallocate_resources": "Reallocate resources",
	"ca_container": "Certificate Authority container",
	"orderer_container": "Ordering node container",
	"peer_container": "Peer container",
	"couchdb_container": "CouchDB container",
	"proxy_container": "gRPC proxy container",
	"dind_container": "Smart contract container",
	"chaincodelauncher_container": "Smart contract launcher container",
	"ca_container_tooltip": "This container encapsulates the internal CA processes, such as registering and enrolling nodes and users, as well as storing a copy of every certificate it issues.",
	"orderer_container_tooltip": "This container encapsulates the internal ordering node processes, such as ordering transactions and processing configuration updates, as well as a copy of the blockchain (the list of transactions) for all of the channels it is a part of.",
	"peer_container_tooltip": "This container holds the blockchain (the transaction data) as well as installed smart contracts. Note that its compute and storage is shared --- it must be sufficient for all channels the peer joins.",
	"couchdb_container_tooltip": "This container includes the state database, which records the current value for all state (assets) recorded on the blockchain. Note that each channel has a distinct state database.",
	"proxy_container_tooltip": "This container contains the processes that allow the console to communicate with this node. Its CPU and memory are fixed.",
	"dind_container_tooltip": "This Docker-in-Docker container is comprised of separate containers, one for each smart contract.",
	"chaincodelauncher_container_tooltip": "This container is responsible for building and then running smart contracts by spinning up a new pod to compile or execute a smart contract.",
	"cpu": "CPU",
	"memory": "Memory",
	"ca_cpu_tooltip": "More CPU will allow identities to be enrolled and registered faster.",
	"ca_memory_tooltip": "More memory will allow identities to be enrolled and registered faster.",
	"ca_storage_tooltip": "The CA stores copies of the public certificates it issues, as well as a list of revoked certificates. Note that this value can only be changed during the creation of the CA.",
	"orderer_cpu_tooltip": "More CPU will allow increased transaction throughput.",
	"orderer_memory_tooltip": "More memory will allow increased transaction throughput.",
	"orderer_storage_tooltip": "This is where the blockchain (the lists of transactions) are stored. Note that the ordering service does not store a copy of the state database. The more channels the ordering service is a part of and the more transactions on these channels, the more storage will be needed.",
	"peer_cpu_tooltip": "A higher CPU will allow increased transaction throughput.",
	"peer_memory_tooltip": "More memory will allow increased transaction throughput.",
	"peer_storage_tooltip": "This storage holds the blockchain (the transaction history) for all channels the peer is joined to, as well as all of the smart contracts installed on the peer.",
	"couchdb_cpu_tooltip": "More CPU will allow your CouchDB container to handle a higher load of queries. Using a CouchDB index, which can be written into your smart contract, can significantly increase query performance.",
	"couchdb_memory_tooltip": "The higher this number the more efficiently your CouchDB container will handle queries. Using a CouchDB index, which can be written into your smart contract, can significantly increase query performance.",
	"couchdb_storage_tooltip": "This is where your ledger data is stored. Each channel has a distinct ledger. The more channels the peer is joined to and the more transactions on a channel, the more storage will be needed.",
	"dind_cpu_tooltip": "The more CPU the Docker-in-Docker smart contract container has, the faster smart contracts will react when invoked (for example, as part of a transaction).",
	"dind_memory_tooltip": "The more memory the Docker-in-Docker smart contract container has, the faster smart contracts will react when invoked (for example, as part of a transaction).",
	"chaincodelauncher_cpu_tooltip": "A higher CPU will allow increased transaction throughput.",
	"chaincodelauncher_memory_tooltip": "More memory will allow increased transaction throughput.",
	"important": "Important",
	"ca_storage_warning": "Storage can only be set during the creation of a Certificate Authority.  Please plan around this when sizing below.",
	"orderer_storage_warning": "Storage can only be set during the creation of an ordering service.  Please plan around this when sizing below.",
	"peer_storage_warning": "Storage can only be set during the creation of a peer.  Please plan around this when sizing below.",
	"usage": "Usage",
	"usage_info": "Info and usage",
	"not_available_for_free_cluster": "Usage information is not available for free clusters.",
	"resource_allocation_desc": "Please refer to your cluster configuration when setting these values because this console does not manage them for you.",
	"import_resource_desc": "If you are uncertain what values to set, these default values are acceptable for a standard cluster. Storage on these containers cannot be adjusted after creation using this console. The CPU and memory can be adjusted at any time as long as resources exist in your cluster.",
	"import_resource_desc_2": "For more information about these default values, how to resize your nodes, and details about storage check out our [[documentation]](_RESOURCES_LINK).",
	"_RESOURCES_LINK": "{{DOC_PREFIX}}?ibp-console-adv-deployment#ibp-console-adv-deployment-allocate-resources",
	"cpu_in_cpus": "CPU (in CPUs)",
	"memory_in_mb": "Memory (in megabytes)",
	"storage_in_gb": "Storage (in gigabytes)",
	"cpu_placeholder": "Enter CPU value here",
	"memory_placeholder": "Enter memory size here",
	"storage_placeholder": "Enter storage size here",
	"cpu_usage_total": "CPU (VPC) usage total",
	"memory_usage_total": "Memory usage total",
	"storage_usage_total": "Storage usage total",
	"usage_not_available": "Resource allocation is not available for free clusters.",
	"error_update_node": "Unable to reallocate resources.",
	"estimated_vpc": "Estimated resource use",
	"vpc": "{{vpc}} CPU (VPC)",
	"add_peer_cost": "Add peer",
	"add_ca_cost": "Add Certificate Authority",
	"add_orderer_cost": "Add ordering service",
	"identity_not_associated_peer": "Identity not associated for peer.",
	"identity_not_associated_orderer": "Identity not associated for ordering service.",
	"identity_not_associated_ca": "Identity not associated for CA.",
	"ca_tls_enroll_id": "Enroll ID for TLS CA",
	"ca_root_enroll_id": "Enroll ID for root CA",
	"patch_node": "Update node",
	"install_patch": "Install update",
	"view_docs": "View docs",
	"no_txs": "No transactions",
	"cluster_failure_message": "There is something wrong with your cluster, <strong>\"{{name}}\"</strong>.",
	"error_node_resource": "\"{{name}}\" seems to be low on resources. Please check your cluster and add more resources if necessary.",
	"use_root_affil": "Use root affiliation",
	"welcome_to_ibp": "Welcome to the Fabric Operations Console",
	"lets_get_started": "Let's get started",
	"welcome_desc_1": "The Fabric Operations Console comprises this application and a number of other elements. Hyperledger Fabric components are deployed on your Kubernetes cluster and developer tools are used to create smart contracts and client applications. Hover over each part of the diagram to learn more.",
	"infrastructure_layer": "Infrastructure Layer",
	"hyperledger_fabric": "Hyperledger Fabric",
	"app_management_console": "Applications and management console",
	"no_signature_requests": "There are currently no signature requests to display.",
	"select_notification": "Select notification",
	"notifications": "Notifications",
	"notifications_filter": "Notifications filter",
	"today": "Today",
	"yesterday": "Yesterday",
	"signature_request_submitted_by": "Signature request from {{org}}",
	"signature_request_received_by": "Signature request receive by {{org}}",
	"signatures_received": "{{signed}}/{{required}} signatures received",
	"orderer_signatures_received": "{{signed}}/{{required}} ordering service signatures received",
	"audit_log": "Approval audit trail",
	"audit_log_desc": "Actions taken by organizations on this approval request can be tracked here.",
	"event": "Event",
	"date_time": "Date/Time",
	"proposed_channel_update": "Proposed channel configuration update on <strong>{{channel}}</strong>",
	"view_channel_update": "View channel configuration updates",
	"view_channel": "View channel information",
	"signature_request_failed": "Signature request failed to send",
	"signature_request_failed_list": "Signature request failed to send to the following organizations:",
	"try_again": "Try again",
	"signature_request_approved": "Required approvals received for {{channel}}",
	"review_and_update": "Review and update channel configuration",
	"review_and_create": "Review and approve channel configuration",
	"audit_log_submitted": "{{org}} - Request submitted",
	"audit_log_signature": "{{org}} - Request signed",
	"audit_log_sent": "Sent to {{org}}",
	"audit_log_error": "Send failed to {{org}}",
	"signature_request_resending": "Resending signature request",
	"channel_policy_update": "Channel configuration update",
	"new_channel_request": "New channel created",
	"channel_policy_update_desc": "<strong>{{org}}</strong> has submitted a change to the <strong>{{channel}}</strong> channel.  The policy to update the configuration of this channel requires {{number_of_signatures}} signatures.",
	"channel_policy_update_approved": "<strong>{{org}}</strong> has submitted a change to the <strong>{{channel}}</strong> channel.",
	"new_channel_request_desc": "<strong>{{org}}</strong> created a new channel: <strong>{{channel}}</strong>",
	"new_channel_pending_request": "New channel request submitted",
	"new_channel_pending_request_desc": "<strong>{{org}}</strong> submitted request to create a new channel: <strong>{{channel}}</strong> and is waiting for the orderer signature.",
	"signature_for_msp": "Signature for {{msp}}:",
	"signature_for_msp_placeholder": "Select identity to sign with",
	"signature_for_join_placeholder_no_options": "No identities belong to this cluster's MSP.",
	"msps_to_sign": "Approval signatures",
	"msps_to_sign_desc": "Select identities to sign the request",
	"orderer_msps_to_sign": "Ordering service approval signatures",
	"orderer_msps_to_sign_desc": "Ordering service admin identities are required to sign this request",
	"msps_to_submit": "Choose submitting organization and identity",
	"msps_to_submit_desc": "Select an organization (represented by an MSP), and an identity to submit this request",
	"approve_new_channel": "Approve new channel",
	"approve_channel_update": "Approve channel update",
	"approve_channel_create": "Approve channel creation",
	"submit_new_channel": "Submit new channel",
	"submit_channel_update": "Submit channel update",
	"submit_channel_create": "Submit channel creation",
	"error_signature_failed": "Signature failed.",
	"channel_created": "New channel <strong>{{channel}}</strong> created by <strong>{{org}}</strong>",
	"signature_for_submit_msp": "MSP",
	"signature_for_submit_identity": "Identity",
	"added_to_channel_policy": "Added to channel configuration",
	"updated_in_channel_policy": "Updated in channel configuration",
	"removed_from_channel_policy": "Removed from channel configuration",
	"create_channel_prereq_message": "To create a channel you must first go through the process of creating an organization, which starts with the creation of a CA and an organization definition. An ordering service must also exist, and know about the peer organization attempting to create a channel, before the channel can be created.",
	"update_channel_prereq_message": "To update the configuration of a channel, the policy for updating a channel configuration must be satisfied. Whatever this policy is, the organization making the request must wait until enough organizations sign the request, indicating their approval, before the new configuration can take effect.",
	"create_channel_prereq_link": "{{DOC_PREFIX}}?topic=started-build-network",
	"create_channel_link": "{{DOC_PREFIX}}?topic=started-build-network#ibm-hlfsupport-console-build-network-create-channel",
	"update_channel_prereq_link": "{{DOC_PREFIX}}?ibp-console-govern#ibp-console-govern-update-channel",
	"create_channel_org_message": "Organizations will not receive a message informing them that they have been added to a channel. They will need to be sent the name of the channel out of band so they can join a peer to the channel.",
	"create_channel_org_link": "{{DOC_PREFIX}}?ibp-console-build-network#ibp-console-build-network-add-org",
	"permissions": "Permissions",
	"channel_update_policy": "Update policy",
	"channel_update_policy_desc": "Specify the number of channel admins, out of the total number in the channel, who have to sign off on future updates to the channel configuration.",
	"all": "All",
	"needs_attention": "Needs attention",
	"open": "Open",
	"closed": "Closed",
	"any": "Any",
	"majority": "Majority",
	"specific": "Specific",
	"customPolicy": "Policy",
	"channel_specific_policy": "{{count}} out of {{total}}",
	"block_cutting_params": "Block cutting parameters",
	"default_parameters": "Update block parameters",
	"sign_request": "Sign the request",
	"sign_request_desc": "",
	"channel_creator": "Creator organization",
	"channel_updator": "Updater organization",
	"orderer_signature": "Ordering service signature",
	"selectedOrdererMsp": "Ordering service MSP",
	"orderer_identity_tooltip": "Ordering service needs to sign when the block parameters are updated",
	"customPolicy_tooltip": "How many operators need to accept the request to update the channel?",
	"select_policy": "Select policy",
	"orderer_msp_tooltip": "If you are modifying any ordering service parameters, you will need to select the MSP of an ordering service organization.",
	"capabilities_orderer_msp_tooltip": "Ordering service admins will be prompted with this field if a proposed channel configuration update requires their signature. For example, an attempt to change the \"channel\" capability level.",
	"select_identity": "Select an identity",
	"missing_msp_definition": "MSP definitions not found for {{list_of_msps}}.",
	"missing_msp_definition_desc": "Please import the MSP definition in your console before updating the channel.",
	"missing_orderer_msp_definition": "MSP definition not found for ordering service {{orderer_name}}. Please import the MSP definition in your console before updating the channel.",
	"send_proposal": "Send proposal",
	"acl_resource_tooltip": "The resource that access is being changed for.",
	"acl_policy_tooltip": "This defines the channel member, whether an application or one of the organizations that are part of the channel, who will have access to the resource changed.",
	"acl_role_tooltip": "The role within the selected organization that will have access to the resource. Note that an “admins” have the rights of “writers” and “readers”, but not vice versa.",
	"acl_important_box_message": "If you restrict access to a resource to a particular organization, be aware that only that organization will be able to access the resource. If you want other organizations to be able to access the resource, you will have to add them one by one using fields below. As a result, consider your access control decisions carefully. Restricting access to certain resources in certain ways can have a highly negative effect on how your channel functions.",
	"root_check_tooltip": "For advanced users only. Uncheck this box when you need to select from a list of affiliations, useful for role-based access control.",
	"login_email": "User ID",
	"login_email_placeholder": "Enter email or user ID",
	"login_password": "Password",
	"login_password_placeholder": "Enter password",
	"current_password": "Current password",
	"current_password_placeholder": "Enter current password",
	"new_password": "New password",
	"new_password_placeholder": "Enter new password",
	"reset": "Reset",
	"reset_password": "Reset password",
	"reset_email": "Email",
	"reset_email_placeholder": "Enter email of the user",
	"reset_password_successful": "Password for '{{user}}' was reset to the default password.",
	"reset_password_failed": "Password reset failed.",
	"change_password": "Change password",
	"change_password_successful": "Password changed successfully.",
	"change_password_failed": "An error occurred when updating your password.",
	"get_started": "Get started",
	"build_network": "Build a network",
	"build_network_desc": "Deploy Hyperledger Fabric components",
	"unjoin_channel": "Unjoin",
	"unjoin_orderer": "Select the orderer nodes to unjoin the channel",
	"join_network": "Join a network",
	"join_network_desc": "Join an existing Fabric Operations Console network",
	"develop_sm": "Develop smart contracts",
	"develop_sm_desc": "Build your first smart contract and application",
	"deploy_sm": "Deploy smart contracts",
	"deploy_sm_desc": "Install your smart contract and start running it",
	"watch_video_en": "Watch video (EN)",
	"vpc_warning_message": "Altering the CPU allocation could result in additional storage charges from your multi-cloud provider.",
	"ibp_vscode_extension": "IBM Blockchain Platform extension for Visual Studio Code",
	"ibp_vscode_desc": "Developers can create, test debug smart contracts, and build applications that transact on your blockchain network. Start from scratch or use tutorials and sample code.",
	"app_management_console_desc": "Monitor, manage and govern your components in blockchain networks from the Fabric Operations Console.",
	"hyperledger_fabric_desc": "The core blockchain technology, implemented by Fabric Operations Console, Hyperledger Fabric is an open source, permissioned, distributed ledger technology that is designed for enterprise-grade use cases.",
	"infrastructure_layer_desc": "Hyperledger Fabric components are created in a Kubernetes cluster on a multi-cloud environment managed by you.",
	"ibp_client_app": "Client Applications",
	"ibp_client_app_desc": "Client applications exist outside your blockchain networks and interact with shared, distributed ledgers by submitting transactions against them. Use the IBM Blockchain Platform extension for Visual Studio Code to easily build your client applications.",
	"diagram_infra_layer": "Infrastructure layer",
	"diagram_infra_layer_desc": "Hyperledger Fabric components are created in an Kubernetes cluster on a multi-cloud environment managed by you.",
	"diagram_fabric_layer": "Hyperledger Fabric",
	"diagram_fabric_layer_desc": "The core blockchain technology, implemented by Fabric Operations Console, Hyperledger Fabric is an open source, permissioned, distributed ledger technology that is designed for enterprise-grade use cases.",
	"diagram_op_tools": "Fabric Operations Console",
	"diagram_op_tools_desc": "Monitor, manage and govern your components in blockchain networks from the Fabric Operations Console.",
	"diagram_client_app": "Client applications",
	"diagram_client_app_desc": "Client applications exist outside your blockchain networks and interact with shared, distributed ledgers by submitting transactions against them. Use the IBM Blockchain Platform extension for Visual Studio Code to easily build your client applications.",
	"diagram_vscode": "IBM Blockchain Platform extension for Visual Studio Code",
	"diagram_vscode_desc": "Developers can create, test, debug smart contracts, and build applications that transact on your blockchain network. Start from scratch or use tutorials and sample code.",
	"diagram_multicloud": "Multicloud capability",
	"diagram_multicloud_desc": "Use the Fabric Operations Console on a variety of public clouds for a seamless experience across multiple environments.",
	"ibp_ga_header_message": "IBM Blockchain Platform is now Generally Available!",
	"ibp_ga_description": "The beta period has ended and we’ve released our GA product. Here’s some important things you should know:",
	"ibp_beta_close_date_desc": "You can keep your beta instance until",
	"ibp_beta_close_date": "August 3rd, 2019",
	"learn_more_about_ga": "Learn more about how to get the GA product",
	"product_label_login": "Login to Fabric Operations Console",
	"login_unauthorized_error_message": "Incorrect credentials, please try again. \nForgot your password? Contact admin to reset your password.",
	"login_generic_error_message": "An error occurred when attempting to login.",
	"login_too_many_attempts_error_message": "Too many failed attempts. Please wait for a minute before trying again.",
	"confirm_password_label": "Confirm new password",
	"confirm_password_placeholder": "Re-enter new password",
	"change_your_password": "Change password",
	"confirm_password": "Confirm password",
	"passwords_do_not_match": "Passwords do not match",
	"password_change_error_title": "Password was not changed",
	"password_change_generic_error_message": "An error occurred when attempting to update password.",
	"password_change_incorrect_password_error_message": "Old password is invalid.",
	"password_character_limit_error_message": "Password should have at least 8 characters.",
	"password_same_as_default_error_message": "Password should be different from the default password.",
	"couch_add_user_info_message": "You must share the default password and the URL of this console with each user so they can login. New users are required to change their password on their first login.",
	"select_ca_default": "Select CA",
	"audit_trail": "Audit trail",
	"couchdb": "CouchDB",
	"reset_password_desc": "Use this panel to reset the password for the selected user to the default password. You will need to communicate the default password to the user. They will be asked to change their password on their first login.",
	"default_password_desc": "If you do not remember the default password, it can be changed by closing this panel and clicking the gear icon on the \"CouchDB\" tile.",
	"username": "User ID",
	"username_label": "Username",
	"access_not_a_manager": "Contact a user with the \"Manager\" role to modify authentication and authorization settings.",
	"defaultPassword": "Default password",
	"defaultPassword_placeholder": "Default password",
	"change_password_desc": "After you click change password you will be logged out and you will have to login with your new password.",
	"apikey_description_label": "Name",
	"apikey_label": "API key",
	"api_secret_label": "API secret",
	"apikey_name_label": "API key name",
	"apikey_name_tooltip": "Enter a brief name or description of this key.",
	"add_new_apikey": "Create an API key",
	"edit_apikey_header": "Edit API key role",
	"delete_access": "Delete access",
	"api_key_warning_txt": "Every API key will also have an \"api_secret\". These secrets are unrecoverable and cannot be shown after creation. If a secret was forgotten, delete the api key and make a new one.",
	"api_secret_reveal_txt1": "Below is your new API key and secret.",
	"api_secret_reveal_txt2": "You must save the API secret in a safe space. It cannot be recovered or shown to you again.",
	"login_req_header": "Login required",
	"login_required_txt1": "Your new auth settings will revert if you are unable to login within 2 minutes.",
	"login_required_txt2": "Please logout with the button below and then log back in to keep these settings.",
	"logout_header": "Success",
	"logout_required_txt1": "Your new auth settings are saved.",
	"logout_required_txt2": "Please logout with the button below and then log back in using the default password.",
	"grpcwp_url": "gRPC web proxy url",
	"raft_orderer_desc": "The ordering service performs important transaction validation checks and is where channels are hosted. Until you deploy or can connect to an ordering service, you cannot create a channel.",
	"external_ca_desc": "If you want to use certificates issued by a Certificate Authority not created using this console, you will have to upload a JSON file containing those certificates.",
	"number_of_nodes_important": "You will not be able to add or delete nodes from this ordering service later.",
	"number_of_ordering_nodes": "Number of ordering nodes",
	"number_of_ordering_nodes_tooltip": "One ordering node corresponds to a single node Raft ordering service, suitable for development and testing. Five ordering nodes provides Raft crash fault tolerance and is recommended for production networks.",
	"one_ordering_node": "One ordering node",
	"five_ordering_nodes": "Five ordering nodes",
	"ordering_node_prefix": "Ordering node display name prefix",
	"multi_node_external_ca": "Because you have chosen to use certificates from an external CA, use this panel to specify the certificates to use for each ordering node.",
	"multi_node_external_important": "You need to provide a single JSON file that contains all the certificates from an external CA for each ordering node. Click Add file to upload that file here.",
	"MULTI_NODE_EXTERNAL_CA_LINK": "{{DOC_PREFIX}}?topic=ha-building-high-availability-certificate-authority-ca",
	"running_in_cluster": "Running in cluster",
	"ordering_nodes": "Ordering nodes",
	"ordering_node": "Ordering node",
	"download_cc": "Download",
	"upload_external": "Upload file containing certificates for ordering nodes",
	"error_external_ca_array": "The definition is not an array",
	"error_external_ca_array_size": "The definition array is an incorrect length",
	"understand": "Understand",
	"i_understand": "I understand",
	"typical_arch": "Typical architecture",
	"arch_desc": "See how the Fabric Operations Console fits together",
	"fluentd_container": "Log Collector",
	"import_ca_location": "Certificate Authority location",
	"import_ca_location_tooltip": "Select the platform where the CA was originally deployed and the associated JSON file was exported from.",
	"import_orderer_location": "Ordering service location",
	"import_orderer_location_tooltip": "Select the platform where the ordering service was originally deployed and the associated JSON file was exported from. ",
	"import_peer_location": "Peer location",
	"import_peer_location_tooltip": "Select the platform where the peer was originally deployed and the associated JSON file was exported from.",
	"third_party_ca_peer_title": "Please note",
	"import_ca_file_tooltip": "Export a JSON file that represents the CA from the platform where it was deployed and add the generated file here.",
	"import_orderer_file_tooltip": "Export a JSON file that represents the ordering service from the platform where it was deployed and add the generated file here. If this is a five node Raft ordering service, the single file will contain five ordering nodes.",
	"import_peer_file_tooltip": "Export a JSON file that represents the peer from the platform where it was deployed and add the generated file here.",
	"fluentd_container_tooltip": "This container pipes the logs from the smart contract container to the peer container. Its CPU and memory are fixed.",
	"fluentd_cpu_tooltip": "This is the recommended value and should not be changed.",
	"fluentd_memory_tooltip": "This is the recommended value and should not be changed.",
	"no_approval_notifications": "No approval notifications",
	"no_approvals_desc": "Approval notifications will be received whenever a signature is required. For example, if the signature of an organization you own is needed to approve of a channel configuration update.",
	"input_error_invalid_number": "The value is not a valid number.",
	"too_many_requests": "Server is currently processing your request, please wait.",
	"orderer_unavailable_title": "The selected orderer is unavailable.",
	"orderer_unavailable_desc": "If it has just been deployed, you may need to wait a few minutes and retry again after the provisioning completes.",
	"import_msp_admin_identity_link": "{{DOC_PREFIX}}?topic=console-importing-nodes-msps-identities",
	"select_peer": "Select peer",
	"select_peer_desc_instantiate": "Select the peer to approve proposals for instantiating the smart contract.",
	"select_peer_desc_upgrade": "Select the peer to approve proposals for upgrading the smart contract.",
	"select_peer_tooltip_instantiate": "Select a peer from an organization that is a member of the channel.",
	"select_peer_tooltip_upgrade": "Select a peer from an organization that was a member of the channel before the smart contract was last instantiated on the channel.",
	"selected_peer": "Peer",
	"404_desc_1": "That page is off the chain!",
	"404_desc_2": "Unfortunately, we could not find that page. Here are some helpful pages instead.",
	"audit_list": "Audit list",
	"please_note": "Please note",
	"console_update_msp_docs": "{{DOC_PREFIX}}?topic=console-importing-nodes-msps-identities",
	"adv_policy_json": "Advanced policy json",
	"still_loading_peer": "Still loading information from {{peerName}}, please wait...",
	"success_loading_peer": "Successfully loaded {{peerName}}",
	"missing_cert": "The admin identity for {{peerName}} is missing from your wallet in this browser. Open the peer and ensure the admin identity has been associated.",
	"deploy": "Deploy",
	"deploy_a_template": "Deploy a starter kit",
	"deploy_desc": "Deploy a bundle of Hyperledger Fabric components automatically",
	"choose_getting_started": "Choose how you get started",
	"getting_started_desc": "Choose below how you would like to get started using the Fabric Operations Console, you can build a network from scratch or use a template to get started quickly.",
	"deploy_component_template": "Deploy a starter kit",
	"deploy_component_desc": "Start with a templated bundle of nodes, organizations and channels from the pre-created examples below. You can further modify these after deployment.",
	"build_network_template_desc": "Deploy Fabric nodes in the way you choose, this is the suggested route for advanced users who understand Hyperledger Fabric and have built networks in the past.",
	"moderate": "Moderate",
	"complex": "Complex",
	"component_template_desc": "Deploy a templated set of nodes, organizations and channels from a set of pre-created examples below. You can further modify these after deployment.",
	"deploy_template": "Deploy kit",
	"select_template": "Select a kit",
	"starter_network": "Starter network",
	"developer_template": "Developer kit",
	"starter_network_desc": "This component kit is perfect fot getting started. With this you will get two Peers, two Certificate Authorities, one ordering service (with one ordering node), two Organizations and a Channel.",
	"developer_template_desc": "The developer template is ideal for taking a local smart contract development environment running through the IBM Blockchain Platform VSCode extension and mirroring it on a cloud platform. It contains the bare minimum to get a network running.",
	"component": "Component",
	"vpc_usage": "VPC usage",
	"please_ensure_you_have": "Please ensure you have at least",
	"vpc_abbrev": "VPC",
	"gb": "GB",
	"see_usage_breakdown": "See usage breakdown",
	"delete_anchor_peers": "Delete anchor peers",
	"error_deleting_anchor_peers": "An error occurred when deleting anchor peers.",
	"channel_anchor_peer_deleted": "Anchor peers deleted successfully",
	"channel_anchor_peer_no_orderer_found": "Unable to delete anchor peers, please import the ordering service for this channel.",
	"export_cert_as_pem": "Export certificate as .pem file",
	"export_private_key_as_pem": "Export private key as .pem file",
	"logout_successful": "Logout Successful",
	"logout_modal": "You have been logged out.  If you would like to log back in, <a href=\"/nodes?ibp={{bust}}\">click here</a>.",
	"error_log_settings_server_restart": "Server is restarting. Please wait a few moments before attempting to change log settings.",
	"authenticated_users_tooltip_ibp": "Click the Assign access in IAM button to add users to this console and edit their permissions.",
	"authenticated_users_tooltip_icp": "This table contains the list of users who have access to this console and their permission level.",
	"authentication_services_tooltip_ibp": "You can use IBM Cloud Identity and Access Management (IAM) to add/remove users to this console.",
	"authorize_services_tooltip_ibp": "You can use IBM Cloud Identity and Access Management (IAM) grant users permission to the console.",
	"authentication_services_tooltip_oauth": "You can add/remove users to this console by contacting the administrator of your OAuth2.0 service.",
	"authorize_services_tooltip_oauth": "You can grant users permission to the console by using the table below.",
	"authentication_services_tooltip_local": "User login is handled by referencing local usernames/passwords stored securely in the console's CouchDB database. You can add users by using the blue button below.",
	"authorize_services_tooltip_local": "You can grant users permission to the console by using the table below.",
	"_PDC_DOC_LINK": "{{DOC_PREFIX}}?ibp-console-smart-contracts#ibp-console-smart-contracts-private-data",
	"pdc_important_note": "For more information on how to configure private data, please visit the link below.",
	"session_timeout_label": "Your session is now inactive",
	"session_timeout_message": "Your browser session has become inactive after {{timeout}} of no activity. Refresh your browser by clicking Refresh to start a new session. You can amend the timeout duration from the Settings page.",
	"refresh": "Refresh",
	"learn_more": "Learn More",
	"friendly_ms_unknown": "? seconds",
	"friendly_ms_ms": "{{millisecs}} ms",
	"friendly_ms_secs": "{{seconds}} seconds",
	"friendly_ms_mins": "{{minutes}} minutes",
	"friendly_ms_hrs": "{{hours}} hours",
	"friendly_ms_days": "{{days}} days",
	"friendly_ms_months": "{{months}} months",
	"friendly_ms_years": "{{years}} years",
	"friendly_ms_unknown_ago": "? seconds ago",
	"friendly_ms_ms_ago": "a few seconds ago",
	"friendly_ms_secs_ago": "{{seconds}} seconds ago",
	"friendly_ms_mins_ago": "{{minutes}} minutes ago",
	"friendly_ms_hrs_ago": "{{hours}} hours ago",
	"friendly_ms_months_ago": "{{months}} months ago",
	"friendly_ms_days_ago": "{{days}} days ago",
	"friendly_ms_years_ago": "{{years}} years ago",
	"session_warning_label": "Your session will expire soon",
	"session_warning_message": "Your session will expire in {{idle_time_remaining}}.  Use the page to reset the timer.",
	"user_inactivity_settings": "Session timeout",
	"user_inactivity_tooltip": "Enable this setting if you want user console sessions to timeout and to specify how long a session can be inactive before it times out. When their session times out due to inactivity, users receive a notification and can click Refresh to start a new session.",
	"inactivity_timeout": "Enable session timeout",
	"error_inactivity_settings": "Unable to change user inactivity settings",
	"error_inactivity_settings_server_restart": "Server is restarting. Please wait a few moments before attempting to change inactivity settings.",
	"max_idle_time": "Session timeout (in seconds)",
	"max_idle_time_tooltip": "Session timeout must be at least 60 seconds and no longer than 8 hours (28800 seconds)",
	"max_idle_time_success": "Session timeout settings have been updated.  Refresh the page for the settings to take effect.",
	"access_doc_links": "Visit our documentation to find more details on user [[roles]](https://www.ibm.com/docs/en/hlf-support/1.0.0?topic=console-administering-your#console-icp-manage-users) and [[API keys]](https://www.ibm.com/docs/en/hlf-support/1.0.0?topic=console-administering-your#console-icp-manage-api-key).",
	"choose_peers_tooltip": "Only peers in running status can be selected",
	"channel_capabilities": "Channel capabilities",
	"channel_capabilities_desc1": "Every channel has three levels of capabilities to ensure that tasks are handled deterministically: one for the ordering service, one for transactions between peers, and \"channel\" capabilities for shared tasks.",
	"create_channel_capabilities_desc2": "During channel creation, it is only possible to modify application level capabilities. The ordering service and channel capabilities are inherited from the ordering system channel and can only be modified through a channel configuration update, which will require the signature of an ordering service admin.",
	"channel_capabilities_desc3": "Note that in order to use the latest features, it may be necessary to set the latest level of capabilities. For more information about capabilities, [[click here]](_CAPABILITIES_LINK01).",
	"channel_warning_20": "There are some nodes in the cluster that are not Fabric v2.x. Ensure that all nodes are updated to v2.x before updating capabilities.",
	"_CAPABILITIES_LINK01": "https://hyperledger-fabric.readthedocs.io/en/release-2.4/capabilities_concept.html",
	"update_channel_capabilities_desc2": "With the exception of the application capabilities, which under certain circumstances can be set during channel creation, these capability levels are inherited by default from the ordering system channel.",
	"create_channel_capabilities_important_box": "It is considered a best practice to upgrade your peers to the latest release. This is because a peer that is below the level of either the application or channel capability will crash upon attempting to join the channel and will have to be upgraded. While the application capability can be set here, you must contact an ordering service admin to learn the channel capability level before attempting to join a peer to the channel.",
	"update_channel_capabilities_important_box": "Nodes at the latest Fabric version are compatible with every capability level.",
	"application_capability": "Application capability",
	"application_capability_tooltip": "Defines the logic used exclusively by the peers when executing transactions. All peers that join this channel must be at least at this Fabric version.",
	"orderer_capability": "Orderer capability",
	"orderer_capability_tooltip": "Defines the logic used exclusively by the ordering service. All of the nodes in the ordering service must be at least at this Fabric version.",
	"channel_capability": "Channel capability",
	"channel_capability_tooltip": "Defines the logic used for tasks shared by the peers and the ordering service, for example channel configuration updates. All peers and ordering nodes must be at least at this Fabric version.",
	"select_capability": "Select the capability",
	"block_parameters": "Block parameters",
	"capabilities": "Capabilities",
	"system_channel_capabilities": "System channel capabilities",
	"system_channel_capabilities_desc2": "Orderer and channel capabilities are inherited from the system channel by default, while application capabilities are selected at channel creation time.",
	"system_channel_capability_docs": "https://hyperledger-fabric.readthedocs.io/en/release-2.4/capabilities_concept.html",
	"update_capabilities": "Update capabilities",
	"error_updating_channel_capabilities": "An error occurred when updating the channel capabilities",
	"system_channel_orderer_capability_tooltip": "The capability level for the orderer cluster, defining how the ordering service processes transactions. It is recommended to choose the latest available capability level. Similarly, it is recommended to have ordering nodes at the latest possible Fabric level.",
	"system_channel_channel_capability_tooltip": "The capability level for the channel group, encompassing processes shared by the ordering service and the peers. It is recommended to choose the latest available capability level. Similarly, it is recommended to have ordering nodes and peers at the latest possible Fabric level.",
	"system_channel_capabilities_warning": "Before updating capabilities, contact the consortium members to ensure that nodes owned by them are compatible with these capabilities.",
	"troubleshooting_link": "https://www.ibm.com/docs/en/hlf-support/1.0.0?topic=tutorials-getting-started",
	"hlfoc_doc_link": "https://github.com/hyperledger-labs/fabric-operations-console",
	"arrowLeft": "Arrow left",
	"arrowRight": "Arrow right",
	"arrowUp": "Arrow up",
	"api": "Channels",
	"appConsole": "App console",
	"bell": "Bell",
	"catalog": "Catalog",
	"chevronDown": "Chevron down",
	"circleFilled": "Step complete",
	"clock": "Clock",
	"copy": "Copy",
	"dataset": "Smart contracts",
	"expand": "Expand",
	"fabricNodes": "Fabric nodes",
	"download": "Download",
	"fingerprint": "Fingerprint",
	"grid": "Nodes",
	"help": "Help",
	"ibmCloud": "IBM Cloud",
	"import": "Import",
	"individualNode": "Individual node",
	"infrastructure": "Infrastructure",
	"launch": "Launch",
	"list": "List",
	"member": "Users",
	"msp": "Organizations",
	"notification": "Notification",
	"newNotification": "New notification",
	"partnership": "Partnership",
	"partyPopper": "Party popper",
	"previous": "Previous",
	"plus": "Plus",
	"restart": "Restart",
	"stepperComplete": "Step complete",
	"stepperCurrent": "Current step",
	"stepperIncomplete": "Incomplete step",
	"terminal": "Terminal",
	"trash": "Trash",
	"upload": "Upload",
	"userAvatar": "User avatar",
	"variableGrid": "Variable grid",
	"visibilityOn": "Visibility on",
	"visibilityOff": "Visibility off",
	"warning": "Warning",
	"advanced_deployment_options": "Advanced deployment options",
	"worker_zone": "Worker Zone",
	"worker_zone_label": "Select a zone where the peer will be deployed",
	"worker_zone_tooltip": "If this node is intended to be a backup to a node that you have already deployed, ensure you choose a different zone than where that node was deployed.",
	"orderer_zone": "Zone",
	"orderer_zone_label": "Select the zone where the ordering node will be deployed",
	"orderer_zone_tooltip": "If this node is intended to be a backup to a node that you have already deployed, ensure you choose a different zone than where that node was deployed.",
	"orderer_zones": "Zones",
	"orderer_zones_label": "Select the zones where the ordering nodes will be deployed",
	"orderer_zones_tooltip": "If a node is intended to be a backup to a node that you have already deployed, ensure you choose a different zone than where that node was deployed.",
	"default_zone": "Choose a zone for me",
	"default_zones": "Choose zones for me",
	"orderer_node_name": "Orderer node {{index}}",
	"orderer_node_summary": "Orderer node {{index}} - {{zone}}",
	"ca_zone": "Zone",
	"ca_zone_label": "Select the zone where the Certificate Authority will be deployed",
	"ca_zone_tooltip": "If this node is intended to be a backup to a node that you have already deployed, ensure you choose a different zone than where that node was deployed.",
	"megabyte": "MB",
	"millisecond": "ms",
	"smc_already_installed": "Smart contract is already installed on this peer",
	"msp_details_title": "{{mspName}}",
	"sync_msp_definition": "Sync MSP definition to all peers",
	"error_msp_not_found": "The msp '{{mspId}}' was not found.",
	"empty_nodes_title": "No nodes available",
	"empty_nodes_text": "Get started by selecting this MSP definition when you create a peer or ordering service.",
	"error_occurred_during_sync_certs": "An error occurred while syncing admins certs to peers.",
	"items_selected": "items selected",
	"item_selected": "item selected",
	"select_all": "Select all",
	"archive": "Archive",
	"inbox": "Inbox",
	"archived": "Archived",
	"restore": "Restore",
	"no_archived_notifications": "No archived notifications",
	"no_archived_notifications_desc": "Archiving removes notification messages from your feed. These messages can be restored to your feed by clicking the checkbox in the right hand corner of the message and click Restore in the blue bar.",
	"archive_notification_confirmation_title_inbox_single": "1 notification moved to Inbox",
	"archive_notification_confirmation_title_inbox_plural": "{{count}} notifications moved to Inbox",
	"archive_notification_confirmation_title_archive_single": "1 notification moved to Archive",
	"archive_notification_confirmation_title_archive_plural": "{{count}} notifications moved to Archive",
	"archive_notification_confirmation_subtitle": "Select 'Archive' from the dropdown menu to view",
	"inbox_notification_confirmation_subtitle": "Select 'All' from the dropdown menu to view inbox",
	"error_archiving_notification": "There was an error with your {{visibility}} request. Please try again.",
	"ca_no_identity": "Click the Associate identity button and select a CA admin identity. If you are operating this CA for the first time, select the CA administrator enroll ID and enter the enroll secret that you provided during deployment or select an existing identity from your Wallet.",
	"peer_no_identity": "Click the Associate identity button and select an organization admin identity.",
	"orderer_no_identity": "Click the Associate identity button and select an organization admin identity. If you are adding a new node to this ordering service, you do not need to associate an identity. Click the Ordering nodes tab.",
	"generate_cert_ca_tooltip": "Select the if the certificate will be generated using the Root Certificate Authority or TLS Certificate Authority.",
	"ca_database_desc": "Use this panel when high availability of the CA is required by configuring CA replica sets. In order to use replica sets, you must have already configured an instance of a PostgreSQL database. SQLite cannot be used for high availability. Upload a JSON file that contains the connection information for your PostgresSQL database and select the number of replica sets required. [[Find out more information here.]](_HA_CA_LINK1)",
	"_HA_CA_LINK1": "{{DOC_PREFIX}}?topic=ha-building-high-availability-certificate-authority-ca",
	"ca_database": "Select database for CA to use",
	"ca_database_tooltip": "Choose PostgreSQL if you plan to configure CA replica sets.",
	"sqlite3": "SQLite",
	"postgres": "PostgreSQL",
	"advanced_options": "Advanced options",
	"db_tls": "TLS",
	"db_tls_desc": "Short description of TLS and how to get these values goes here.",
	"advanced_ca_config": "Advanced CA Configuration",
	"advanced_ca_config_desc": "Select this option if you wish to specify a PostgreSQL database, alter the replica sets of the CA or change CA resource allocations",
	"database_and_replica_sets": "Database and replica sets",
	"zones_only_for_sqlite3": "Zone selection is only available when the database type is SQLite.",
	"zone_selection": "Deployment zone selection",
	"multizone_storage_warn": "Before selecting this option, ensure that your cluster supports multizone capable storage, included with Portworx based storage.",
	"multizone_storage_warn_link": "https://cloud.ibm.com/docs/containers?topic=containers-storage_planning#persistent_storage_overview",
	"default_value": "(default)",
	"across_zones": "Across all zones",
	"hsm": "Hardware Security Module (HSM)",
	"hsm_tooltip": "Select this option only when you want to use an HSM to generate and store the private keys used by your Fabric nodes. An HSM protects private keys and handles cryptographic operations, which allows your peers and ordering nodes to sign and endorse transactions without exposing their private key. Before selecting this option you should have already configured an HSM to work with the platform.",
	"ca_database_and_replica_sets_tooltip": "Select this option if you need to configure this CA for high availability by choosing to use replica sets. Before selecting this option you must have a PostgreSQL database configured and the connection information available.",
	"ca_zone_selection_tooltip": "Select this option when multiple zones are configured for your Kubernetes cluster and you prefer to choose the zone where the Certificate Authority is deployed. If you do not select this option, the platform will select the zone for you.",
	"ca_resource_allocation_tooltip": "Select this option when you do not want to use the default resource allocations for the Certificate Authority, but want to specify your own values instead.",
	"db_json": "PostgreSQL service credentials",
	"db_json_tooltip": "Upload the JSON file that contains the service credentials from your PostgreSQL database. Refer to the documentation link above for more instructions.",
	"leveldb_container": "LevelDB",
	"leveldb_container_tooltip": "The storage for the state databases of all the ledgers of all the channels this peer will join.",
	"leveldb_storage_tooltip": "This value should not be changed, therefore it is not editable.",
	"peer_state_database": "State database",
	"state_database_selection": "State database",
	"leveldb": "LevelDB",
	"peer_state_db_tooltip": "The state database maintains a list of the current value of all keys. Two database types are supported. CouchDB, which permits rich queries of data as long as the data has been modeled in the smart contract as a JSON object. And LevelDB, which does not permit rich queries but operates faster than CouchDB. If left unchecked, CouchDB is used.",
	"peer_zone_selection_tooltip": "Select this option when multiple zones are configured for your Kubernetes cluster and you prefer to choose the zone where the peer is deployed. If you do not select this option, the platform will select the zone for you.",
	"peer_resource_allocation_tooltip": "Select this option when you do not want to use the default resource allocations for the peer, but want to specify your own values instead.",
	"orderer_zone_selection_tooltip": "Select this option when multiple zones are configured for your Kubernetes cluster and you prefer to choose the zones where the ordering nodes are deployed. If you do not select this option, the platform will select the zones for you.",
	"orderer_resource_allocation_tooltip": "Select this option when you do not want to use the default resource allocations for the ordering nodes, but want to specify your own values instead.",
	"sync_msp_def_warning": "Note: This will update the admin certs on the associated nodes listed below. Each node will be restarted to reflect the update.",
	"replica_set_cnt": "Number of replicas",
	"replica_set_cnt_tooltip": "Choose 1 replica if replication is not required at this time. Choose 2 or 3 when you need to ensure that a CA replica is always available to process requests. You can update this value later as well.",
	"peer_state_database_important_note": "All peers in a channel must use the same database type. You will not be able to join a peer using a Level database to a channel where the other peers are using a Couch database, or vice versa.",
	"zone_desc": "Use this panel to designate the zone where the node is deployed. When you select “Choose a zone for me”, we attempt to deploy the node in a different zone than the other nodes owned by the same organization. Alternatively, you can select a specific zone where you want to deploy the node. If your cluster has been configured with multi-zone storage, choose “Across all zones”, to ensure node availability in case of a zone failure.",
	"zone_desc_link": "{{DOC_PREFIX}}?topic=ha-building-high-availability-certificate-authority-ca",
	"zone_desc_link2": "{{DOC_PREFIX}}?topic=hah-setting-up-multiregion-high-availability-ha-deployments-peers",
	"join_peer_imp_message": "All peers in a channel must use the same database type. Therefore, take caution when choosing which peer to join to this channel, as it  <strong>must</strong> have the same database type as the other peers in the channel. If you are unsure about the database type of the channel, contact the channel administrator. Note that if you did not select a database type when creating your peer, CouchDB is used.",
	"upload_custom_template": "Upload custom template",
	"upload_custom_template_subtext": "Drag your custom template .json file here to upload, or <span class='ibp--custom-template-browse-link'>browse</span>",
	"uploading_custom_template_subtext": "This may take a moment",
	"upload_custom_template_tile_subtext": "Custom templates allow you to replicate personalized environments easily.",
	"template_attached": "Template attached",
	"check_storage": "Check storage",
	"add_json": "Add JSON",
	"add_json_desc": "Drag and drop JSON file to the target area below for to be automatically uploaded.",
	"template_secrets_note": "Please note: Secrets are used to generate identities. This service will access, but not store secrets.",
	"error_unsupported_template_file_type": "Unsupported file type. Template file must be .json.",
	"error_incorrect_template_json": "Incorrect template JSON.",
	"error_get_instantiated": "Error getting instantiated smart contracts on channel {{channelName}} as {{peerName}}",
	"resource_estimate": "Resource estimate",
	"template_starter_desc": "Perfect for getting started and learning the basics of the Fabric Operations Console. An example structure for a Blockchain network with two organizations.",
	"template_dev_desc": "Replicate the same environment as the local Fabric instance within the IBM Blockchain Platform extension for VS Code.",
	"template_custom_desc": "Custom templates allow you to replicate personalized environments easily.",
	"template_information": "Template information",
	"change_template": "Change template",
	"custom_template_description": "Upload your custom template. To build a custom template, find out more here.",
	"join_peer_imp_link": "{{DOC_PREFIX}}?ibp-console-adv-deployment#ibp-console-adv-deployment-level-couch",
	"_JOIN_CHANNEL_LINK": "{{DOC_PREFIX}}?topic=started-join-network",
	"replica_sets_desc": "Replica sets guarantee the availability of the CA by ensuring that multiple replicas of the CA are running.",
	"no_identities": "There are no applicable identities in the wallet.",
	"no_ca_identities": "Please import your CA admin identity into your Wallet on the Wallet page.",
	"no_peer_identities": "Please import your peer admin identity into your Wallet on the Wallet page.",
	"no_orderer_identities": "Please import your Ordering service admin identity into your Wallet on the Wallet page.",
	"peer_statedb_imp_box": "Do not join a peer deployed with LevelDB to a channel with peers using CouchDB, or vice versa. Contact a channel administrator to discover the database type used by peers in channels you wish to join this peer to. Note that it is not possible to change database types after a peer has been deployed.",
	"blockchain_platform": "Blockchain Platform",
	"advanced_channel_config": "Advanced channel configuration",
	"update_advanced_config": "Update advanced channel configuration",
	"channel_consenter_set": "Ordering service consenter set",
	"consenter_empty_error": "At least one consenter is required",
	"create_channel_consenter_set_desc": "The ordering nodes that form the ordering service of a channel is known as the consenter set of that channel. While the consenter set can be comprised of all of the nodes in the ordering service, it is possible to select a subset of these nodes to form the consenter set.",
	"create_channel_consenter_set_desc2": "The ordering nodes that manage a channel are known as the consenter set of that channel. While the consenter set can be comprised of all of the nodes, it is possible to select a subset of these nodes to form the consenter set.",
	"osn-join-desc": "Select the ordering clusters that should join right now. The selected orderers from each selected cluster will join this channel. \n\nAny orderers that are not selected can still join at a later date.",
	"osn-join-desc2": "Select the orderers that should join this channel. Each orderer will join as a consenter or follower depending on the channels latest config block. \n\nAny orderers that are not selected can still join at a later date.",
	"osn-join-desc3": "Select the \"Continue\" button if you want to join orderers now.",
	"osn-join-config-block": "Success, the genesis block for the channel \"{{channel}}\" was created and stored.\nNext join orderer nodes to this channel. You can either do that right now or on a later date.",
	"osn-join-creating-block": "Creating genesis block...",
	"osn-join-loading-desc": "Loading config block...",
	"update_channel_consenter_set_desc": "The ordering nodes that form the ordering service of a channel is known as the consenter set of that channel. While the consenter set can be comprised of all of the nodes available in the ordering service, it is possible to add nodes that are a part of the ordering service to the consenter set. Before adding a node to the consenter set, make sure the organization that owns the node has been added as an administrator of the ordering service of this channel.",
	"_CHANNEL_CONSENTER_SET_LINK01": "https://hyperledger-fabric.readthedocs.io/en/release-2.4/access_control.html",
	"select_consenter": "Select consenter",
	"channel_consenter": "Consenters",
	"host": "Host",
	"port": "Port",
	"consenter_count_error": "Only one update to the consenter set is allowed at a time.",
	"genesis_consenter_set": "List of orderers at channel creation time",
	"ordering_service_consenters": "Ordering service consenters",
	"ordering_service_consenters_tooltip": "The ordering nodes that are part of the ordering service on a particular channel.",
	"update_channel_advanced_config_desc": "Contains channel configuration parameters which will not be of interest to most users. This is, for example, where you can edit the block cutting parameters, which define the circumstances under which new blocks are cut by the ordering service.",
	"create_channel_advanced_config_desc": "Contains channel configuration parameters which will not be of interest to most users.",
	"update_orderer_def_error_title": "Ordering service definition outdated",
	"update_orderer_def_error_desc": "The current ordering service definition is missing certificates that are required to update the consenter set. Contact an ordering service administrator for a new copy of the ordering service definition and import it into this console.",
	"raft_params": "Raft Parameters",
	"tick_interval": "Tick interval",
	"tick_interval_placeholder": "Tick interval placeholder goes here..",
	"tick_interval_tooltip": "Tick interval tooltip goes here..",
	"election_tick": "Election tick",
	"election_tick_placeholder": "Election tick placeholder goes here..",
	"election_tick_tooltip": "Election tick tooltip goes here..",
	"heartbeat_tick": "Heartbeat tick",
	"heartbeat_tick_placeholder": "Heartbeat tick placeholder goes here..",
	"heartbeat_tick_tooltip": "Heartbeat tick tooltip goes here..",
	"max_inflight_blocks": "Max Inflight Blocks",
	"max_inflight_blocks_placeholder": "Max Inflight Blocks placeholder goes here..",
	"max_inflight_blocks_tooltip": "Max Inflight Blocks tooltip goes here..",
	"snapshot_interval_size": "Snapshot interval size",
	"snapshot_interval_size_placeholder": "Snapshot interval size placeholder goes here..",
	"snapshot_interval_size_tooltip": "Defines the amount of space kept in the logs of the ordering nodes. These logs are used to help a node retrieve blocks it missed when it was down. The larger the Snapshot interval, the faster nodes will catch up. But this also will use more storage space.",
	"advanced_orderer_config": "Advanced config",
	"raft_parameters_desc": "Some desc for raft parameters goes here..",
	"saas_orderer_node_display_name_tooltip": "This is the name the console will use to identify this node.",
	"orderer_node_display_name": "Ordering node display name",
	"orderer_node_display_name_placeholder": "Enter an ordering node display name",
	"pending_node_message": "Pending",
	"ordering_node_title": "Ordering node",
	"import_raft_ignore": "The following nodes will not be imported because they already exist:",
	"import_raft_append": "The following node will be appended to the existing ordering service:",
	"import_raft_no_append": "The selected file does not contain any new ordering node information.",
	"import_parent_no_append": "The selected file does not contain any new ordering node information for the current ordering service.",
	"import_parent_ignore": "The following nodes will not be imported because they already exist or are not part of the current ordering service:",
	"add_orderer_nodes_successful": "Ordering service nodes were added or updated",
	"add_orderer_node": "Add another node",
	"raft_node_desc": "If you are replacing a corrupted node or are a new organization joining a network and want to contribute a node to the ordering service to share in the management and costs associated with the ordering service, you can use this process to add a node to this ordering service. Note that you will have to complete the separate step of adding this node to the consenter set of the system channel after the deployment process has completed.",
	"raft_add_node_docs": "{{DOC_PREFIX}}?topic=console-adding-removing-ordering-service-nodes",
	"remove_orderer_node": "Remove ordering node",
	"delete_orderer_node": "Delete ordering node",
	"delete_orderer_node_desc": "This action will permanently delete {{name}} from this ordering service and in your Kubernetes cluster.",
	"remove_orderer_node_confirm": "Please type the name of the ordering node to confirm:",
	"confirm_orderer_node_name": "Ordering node name",
	"confirm_orderer_node_tooltip": "Re-enter the ordering node to confirm",
	"cp_channels_tooltip": "List of channels from the ordering nodes",
	"force_delete": "Force delete",
	"force_delete_node": "Force delete node",
	"force_delete_orderer_desc": "We are unable to retrieve the system channel for the ordering service at this time. However, it is still possible to delete this ordering service through a force delete. Note that a force delete will affect both the system channel and all of the application channels hosted on this ordering service, potentially making them unusable. ",
	"force_delete_important_box": "This action cannot be reversed.",
	"consenter_exists_warning": "The following channels have more than one orderer including the one being deleted. Removing the node would potentially leave the channel to lose quorum and make it unusable. Remove the node from consenter set on the following channels and try deleting again.",
	"consenter_exists_warning_simpler": "The following channels have this orderer as a consenter. Removing the node would potentially leave the channel to lose quorum and make it unusable. To be safe remove the node from the consenter set on the following channels before deleting.",
	"safe_consenter_exists_warning": "The following channels have only the orderers that are being deleted. Ensure that the channels are not in use before deleting the ordering nodes. Deleting the orderers will make the following channels unusable",
	"ignore_del_warning": "Ignore the warning and delete anyway.",
	"orderer_node_enroll_id": "Ordering node enroll ID",
	"orderer_node_enroll_secret": "Ordering node enroll secret",
	"csr_host": "CSR Hostname",
	"csr_host_placeholder": "Enter hostname",
	"csr_host_tooltip": "Subject Alternate Names",
	"orderer_node_ca_tooltip": "If you have already deployed a CA and used it to create your own ordering service, you can select that CA here. Do not use a CA you used to create a peer.",
	"orderer_node_enroll_id_tooltip": "Select the ordering node ID you registered with the CA you selected above. If you have not registered an ordering service identity, exit and do so now. This identity must have been registered using the orderer type.",
	"orderer_node_enroll_secret_tooltip": "Enter the secret corresponding to the ordering node ID you registered with the CA you selected above.",
	"orderer_node_admin_msp_tooltip": "Select an MSP created by the CA you selected above. If you are adding a node to an ordering service created in a different console, this MSP must have been exported from this console to the console where the ordering service was created and added to the list of ordering organizations.",
	"orderer_node_tls_enroll_id_tooltip": "Select the same ordering node ID you selected on the previous panel.",
	"orderer_node_tls_enroll_secret_tooltip": "Enter the same secret corresponding to the ordering node ID you entered on the previous panel.",
	"pending_orderer_node": "Your ordering node has been created but needs to be added to your ordering service.  Click Add node to ordering service to complete this process.  If you want to delete the node, click the trash icon.",
	"add_to_system_channel": "Add node to ordering service",
	"add_to_system_channel_desc": "Text needed here.",
	"add_to_system_channel_successful": "Your ordering node has been added to the ordering service.",
	"ordering_node_empty_title": "You don't have access to this node",
	"ordering_node_empty_desc": "Only administrators of a node in the console where it was originally deployed can view configuration information.",
	"requires_attention": "Requires attention",
	"node_requires_attention": "Ordering node requires attention",
	"node_requires_attention_desc": "Your ordering node {{name}} has been created but needs to be added to your ordering service.  Click Go to node to finish setting up your node.",
	"go_to_node": "Go to node",
	"database": "Database",
	"time_to_network": "Time to network:",
	"template_est_time": "5 - 10 minutes",
	"standard_est_time": "1 hour",
	"ten_min_setup": "5-10 minutes setup",
	"review_details": "Reviewing details",
	"review_details_of": "Reviewing details of",
	"confirm_deployment": "Confirm deployment",
	"names": "Names",
	"template_desc": "Template description",
	"confirm_deployment_desc": "Please ensure that you have the appropriate resources available within your cluster. If you don't have the appropriate resources, we won't be able to deploy your component template for you.",
	"starter_template_diagram": "Starter template diagram",
	"developer_template_diagram": "Developer template diagram",
	"starter_template": "Starter kit",
	"export_template_file": "Export template file",
	"progress_will_not_save": "Progress will not be saved",
	"deploying": "Deploying",
	"learn_more_about": "Learn more about",
	"identities_in_wallet": "Identities in the wallet",
	"what_are_cas": "What are Certificate Authorities?",
	"what_are_peers": "What are peers?",
	"what_are_orderers": "What are ordering services?",
	"what_are_channels": "What are channels?",
	"what_is_chaincode": "What are smart contracts?",
	"what_are_identities": "What are identities?",
	"what_are_orgs": "What are organizations?",
	"ca_desc": "The underpinning of a blockchain network based on Fabric is identities and permissions. Identities take the form of x.509 certificates issued by a Certificate Authority (CA) and can be compared to a credit card in that they identify someone within a particular context (credit cards identify an individual in terms of banking transactions, while Fabric identities allow users and components like peers to be identified in a blockchain context). This identity can include attributes, for example linking an actor to a particular organization. These certificates are then linked to the ability to perform actions on a network through their inclusion in an organization, which is defined by Membership Service Provider (MSP) folders containing certificates that exist at the component and channel level. For more information about identities, check out the tile about identities and the wallet.",
	"peer_desc": "At a physical level, a blockchain network is comprised primarily of peer nodes (or, simply, peers). Peers are the fundamental elements of the network because they host ledgers and smart contracts (which are contained in 'chaincode'). More accurately, the peer hosts **instances** of the ledger, and **instances** of smart contracts. Because smart contracts and ledgers are used to encapsulate the shared processes and shared information in a network, respectively, these aspects of a peer make them a good starting point to understand what a Fabric network actually does.",
	"peer_desc_2": "The Fabric Operations Console allows peers to be created, joined to channels, have smart contracts installed on them, made into anchor peers, and be seamlessly upgraded.",
	"orderer_desc": "Many distributed blockchains, such as Ethereum and Bitcoin, are not permissioned, which means that any node can participate in the consensus process where transactions are ordered and bundled into blocks. Because of this fact, these systems rely on probabilistic consensus algorithms which eventually guarantee ledger consistency to a high degree of probability, but which are still vulnerable to divergent ledgers (also known as a ledger “fork”), where different participants in the network have a different view of the accepted order of transactions.",
	"orderer_desc_2": "The Fabric Operations Console works differently. It features a kind of a node called an ordering node that does this transaction ordering, which along with other nodes forms an ordering service. Because this design relies on deterministic consensus algorithms, any block a peer receives from the ordering service and validates is guaranteed to be final and correct. Ledgers cannot fork the way they do in many other distributed blockchains.",
	"orderer_desc_3": "The Fabric Operations Console uses an implementation of the Raft protocol, in which a leader node is dynamically elected among the ordering nodes in a channel (this collection of nodes is known as the “consenter set”), and that leader replicates messages to the follower nodes. Because the system can sustain the loss of nodes, including leader nodes, as long as there is a majority of ordering nodes (what’s known as a “quorum”) remaining, Raft is said to be “crash fault tolerant” (CFT). In the Console, users have the ability to select a single node ordering service or a five node ordering service, and then to add or remove nodes as needed. Ordering services can also be administered by several organizations.",
	"channel_desc": "A channel is a mechanism that provides a private layer of communication between members in the network. Multiple channels can be created between subsets of members, and because these channels are where asset transactions occur, they are sometimes called “application channels”. Data on the blockchain network is stored on a ledger, with each channel having its own separate ledger. These ledgers are hosted on the peers that have joined the channel. The Fabric Operations Console allows channels to be easily created and managed. Channel configuration updates allow the members of a channel to edit parameters as they see fit. For example, more members can be added to a channel, or the capabilities of a channel can be changed. Because changes to a channel must be approved by channel members, the Fabric Operations Console has a mechanism for the collection of necessary signatures.",
	"chaincode_desc": "From an application developer’s perspective, a smart contract, together with the ledger, form the heart of a Fabric Operations Console system. Whereas a ledger holds facts about the current and historical state of a set of business objects, a smart contract defines the executable logic that generates new facts that are added to the ledger. A chaincode is typically used by administrators to group-related smart contracts for deployment, but can also be used for low level system programming. Smart contracts are installed onto peers and instantiated to one or more channels.",
	"chaincode_desc_2": "The IBM Blockchain Platform Visual Studio (VS) Code extension provides an environment within Visual Studio Code for developing, packaging, and testing smart contracts.",
	"vs_code_extension_link": "[[Start building with the VS Code extension]](_VSCODEEXT)",
	"_VSCODEEXT": "https://marketplace.visualstudio.com/items?itemName=IBMBlockchain.ibm-blockchain-platform",
	"identity_desc": "The different actors in a blockchain network include peers, orderers, client applications, administrators and more. Each of these actors — active elements inside or outside a network able to consume services — has a digital identity encapsulated in an X.509 digital certificate. These identities really matter because they determine the exact permissions over resources and access to information that actors have in a blockchain network.",
	"identity_desc_2": "For an identity to be verifiable, it must come from a trusted authority. This is achieved with a Membership Service Authority (MSP), which defines an organization and the root CA associated with it. MSPs also define the rules that govern which identities in the organization are valid and the role of that identity in the organization. All identities can be found in a section of the console called the 'wallet', which can be located in the left navigation. ",
	"msp_desc": "On a conceptual level, a network on the Fabric Operations Console can be thought of as a collection of organizations that own and control various nodes and identities for the purpose of structuring and making transactions.",
	"msp_desc_2": "On a physical level, organizations are defined by a Membership Service Provider (MSP) which includes the root certificate of the CA issuing certificates for the entities associated with that organization, as well as the signing certificate of an admin of that organization. Organization definitions can be exported from one console into another. Organizations should be added to an ordering service, forming a consortium, the list of organizations allowed to create and join channels.",
	"what_are_applications": "What are applications?",
	"application_desc": "Client applications leverage underlying infrastructures such as APIs, SDKs, and smart contracts to allow client interactions (invokes and queries) at a higher level of abstraction. After installing smart contracts and deploying your nodes, you can use client applications to transact with other members of your network. Applications can invoke the business logic contained in smart contracts to create, transfer, or update assets on the blockchain ledger.",
	"template_contains": "This template contains",
	"error_deploying_template": "There was an error deploying your template. Please try again.",
	"step": "Step",
	"data_management": "Bulk data management",
	"data_management_tooltip": "Used to export or import multiple nodes, MSPs, and identities at the same time.",
	"export_modal_desc1": "You have the option of which components to export. Note that if you select a box, for example Peers, all of the peers will be included in the export JSON. If the console that imports these components has already imported some of the components, for example one of the peers, the additional component will show up as a duplicate.",
	"delete_header": "Danger zone",
	"delete_header_tooltip": "Be careful.",
	"delete_everything": "Delete all components",
	"delete_wallet": "Delete wallet",
	"yes_confirmation": "Yes",
	"confirmation_header_all": "Delete all components",
	"confirmation_header_wallet": "Delete wallet",
	"confirmation_desc1": "Are",
	"confirmation_desc2": "you",
	"confirmation_desc3": "sure",
	"confirmation_desc4": "?",
	"export_modal_desc2": "Identities are not selected by default for security reasons and because a single console does not permit more than one identity of the same name. If a duplicate identity is detected on import, the entire import will fail. Note that if identities and nodes are exported in the same JSON, the associations will also persist as part of the import. If identities are exported separately, identities will have to be associated with nodes separately.",
	"export_modal_desc3": "Select which data to export:",
	"export_identity_important": "Exported identities will include the private key of the identity in the export JSON. Be sure to protect this information.",
	"import_modal_desc1": "You can import a JSON representing components that were deployed in another console through this screen. Note that if you are importing several components at once, and have already imported some of them, the new components will appear as duplicates in this console. If you attempt to import a JSON that contains an identity that already exists in this console, the import will fail.",
	"import_modal_desc2": "Note that if identities and nodes are exported in the same JSON, the associations will also persist as part of the import. If identities are exported separately, identities will have to be associated with nodes separately.",
	"import_file_tooltip": "Export a JSON file that represents the Certificate Authorities, ordering services, peers, MSPs, and identities from the platform where it was deployed and add the generated file here.",
	"error_import_json_array": "The file is not an array.",
	"error_import_invalid_type": "The file contains an invalid item type.",
	"error_import_invalid_name": "The file contains an item with an invalid name",
	"error_identity_already_exists": "The file contains an identity that already exists.",
	"warning_tls_certs": "Some ordering service TLS certificates could not be retrieved at this time and will not be included in the exported information.",
	"error_add_to_system_channel": "The ordering node could not be added to the system channel at this time.",
	"make_anchor_peers": "Make anchor peer(s)",
	"yes": "Yes",
	"no": "No",
	"error_add_anchor_peer_during_join": "An error occurred while adding anchor peers",
	"join_channel_select_peers_tooltip": "Joining more than one peer to a channel ensures that peers are highly available.",
	"join_channel_make_anchor_tooltip": "Anchor peers allow peers from other organizations to discover the peers in your organization, enabling features like service discovery and private data. If you have already made a peer on this channel an anchor peer, you can leave this option unchecked.",
	"expiration_date": "Expiration date",
	"expiration": "Expiration",
	"subject": "Subject",
	"nodes_added_successfully": "You have successfully added node(s) to the channel.",
	"no_more_peers_to_join": "There are currently no more peers available to join.",
	"error_no_raft_leader": "Node deployment has not finished synchronizing with the ordering service. Please wait up to five minutes.\n Do not attempt any operations on this ordering service until this process is complete.",
	"add_node_to_channel_desc": "To add a peer to the <strong>{{channelName}}</strong> channel, select it below. You also have the option to make this peer an Anchor peer, which is recommended if you have not yet specified an anchor peer on this channel.",
	"add_node": "Join peer",
	"app_capability_version": "Application capability level",
	"os_capability_version": "Ordering service capability level",
	"channel_capability_version": "Channel capability level",
	"quick_actions": "Quick actions",
	"download_identities": "Export identities",
	"clean_up_identities": "Clean up identities",
	"template_prefix": "Kit prefix (optional)",
	"template_prefix_placeholder": "Enter kit prefix",
	"update_certs": "Update certificates",
	"renew_tls_cert": "Renew TLS certificate",
	"renew_ca_tls_cert_desc": "The TLS certificate for the Certificate Authority will be renewed and the CA will be restarted. Once the CA is available, you will need to update any associated nodes (peers and ordering nodes) with the new TLS certificate information.",
	"update_tls_cert": "Update associated nodes",
	"update_tls_cert_desc": "Select the nodes to be updated with the TLS certificate from the Certificate Authority.",
	"error_update_tls_cert": "One or more nodes failed to update the TLS certificate information.",
	"restart_ca_desc": "This Certificate Authority will be temporarily unavailable while it is being restarted.",
	"restart_orderer_desc": "This ordering node will be temporarily unavailable while it is being restarted.",
	"restart_peer_desc": "This peer will be temporarily unavailable while it is being restarted.",
	"manage_peer_certs_desc": "Specify if you need to enroll or reenroll the enrollment certificate or TLS certificate for the peer.",
	"manage_orderer_certs_desc": "Specify if you need to enroll or reenroll the enrollment certificate or TLS certificate for the ordering node.",
	"manage_orderer_certs_important": "After updating the TLS certificate, be sure to update the system channel and application channel consenter sets with the new certificate information.",
	"ekey": "Enrollment private key",
	"ecert": "Enrollment certificate",
	"do_not_update": "Do not update",
	"enroll_cert": "Enroll certificate",
	"reenroll_cert": "Re-enroll certificate",
	"orderer_update_tls_cert_important_box": "After updating the TLS certificate for the ordering node, be sure to update the consenter sets for the system channel and any application channels.",
	"orderer_update_tls_cert_important_link": "{{DOC_PREFIX}}?cert-mgmt#cert-mgmt-enroll-tls-ordering-node",
	"orderer_update_version_important_box": "The version of Fabric installed does not support enrolling or re-enrolling the TLS certificate.",
	"peer_information": "Peer information",
	"orderer_node_information": "Orderer node information",
	"operations_url": "Operations url",
	"enabled": "Enabled",
	"disabled": "Disabled",
	"not_used": "Not used",
	"api_url": "API url",
	"fabric_up_to_date": "You are up to date with the latest version of Hyperledger Fabric",
	"intermediate_ca": "Intermediate CA",
	"tls_intermediate_ca": "TLS Intermediate CA",
	"fabric_update_available": "Upgrade to {{upgrade_version}} available",
	"update_version": "Upgrade version",
	"hsm_desc": "Use a Hardware Security Module (HSM) to generate and store the private keys used by your Fabric nodes.",
	"enable_hsm": "Enable HSM",
	"update_hsm": "Update HSM",
	"remove_hsm": "Remove HSM",
	"manage_hsm": "Manage HSM",
	"hsm_config": "Hardware Security Module (HSM)",
	"systemless_config": "Without a system channel",
	"system_config": "With a system channel",
	"systemless_tooltip": "In Fabric v2.4+ orderers no longer need to belong to a system channel to manage application channels. This simplifies the lifecycle of a channel, lowers the node's load, and increases security.",
	"systemless_title": "Configuration",
	"hsm_config_short": "Hardware Security Module (HSM)",
	"hsm_peer_desc": "You can use a Hardware Security Module (HSM) to generate and store the private keys used by your Fabric nodes. An HSM protects your private keys and handles cryptographic operations, which allows your peers to sign transactions without exposing your private keys. The node uses the PKCS #11 cryptographic standard to communicate with an HSM.",
	"hsm_orderer_desc": "You can use a Hardware Security Module (HSM) to generate and store the private keys used by your Fabric nodes. An HSM protects your private keys and handles cryptographic operations, which allows your ordering nodes to endorse transactions without exposing your private keys. The node uses the PKCS #11 cryptographic standard to communicate with an HSM.",
	"hsm_ca_desc": "You can use a Hardware Security Module (HSM) to generate and store the private keys used by your Fabric nodes. An HSM protects your private keys and handles cryptographic operations, which allows your nodes to sign transactions without exposing your private keys. The node uses the PKCS #11 cryptographic standard to communicate with an HSM.",
	"hsm_label": "HSM label",
	"hsm_pin": "HSM PIN",
	"pkcs11endpoint": "Proxy endpoint",
	"hsm_pkcs11endpoint": "HSM proxy endpoint (deprecated)",
	"hsm_label_placeholder": "Enter the HSM Label",
	"hsm_pin_placeholder": "Eg. 9174685153",
	"hsm_pkcs11endpoint_placeholder": "Eg. tcp://proxy:2345",
	"hsm_important": "Before you can use an HSM with your node, you have to configure an HSM and publish an HSM client image to a container registry. The PKCS #11 proxy is deprecated.",
	"hsm_important_link": "{{DOC_PREFIX}}?topic=tutorials-hardware-security-module-hsm",
	"hsm_endpoint_tooltip": "Enter the ClusterIP address for the HSM proxy. The address must begin with tcp://",
	"hsm_label_tooltip": "Enter the name of the HSM partition",
	"hsm_pin_tooltip": "Enter the PIN of the HSM slot",
	"use_hsm_settings": "Use HSM client image",
	"finish": "Finish",
	"prerequisites": "Prerequisites",
	"organization_creating_channel": "Organization creating channel",
	"organization_updating_channel": "Organization updating channel",
	"consenter_set": "Consenter set",
	"ordering_service_organization": "Ordering service signature",
	"review_channel_info": "Review channel information",
	"osn_join_channel": "Create Genesis",
	"prerequisites_para1": "Each transaction on a network is executed on a channel, a private sub-network where two or more organizations can communicate and conduct transactions only the authenticated members of the channel know about. [[For prerequisites help click here]](create_channel_prereq_link).",
	"prerequisites_para1.5": "[[For addtional channel creation help click here]](create_channel_link)",
	"prerequisites_para2": "Before creating a channel:",
	"prerequisites_bullet1": "Orderers must exist",
	"prerequisites_bullet2": "A peer organization must be created and joined to the consortium controlled by that ordering service",
	"prerequisites_impbox": "If a peer organization is not part of the ordering service consortium, it will not be able to create a channel.",
	"required_step": "Required setup",
	"advanced_configuration": "Advanced configuration",
	"review": "Review",
	"advanced_channel_config_desc": "Contains channel configuration parameters which will not be of interest to most users. Note that changing some of these fields will require the signature of an ordering admin for the channel to be created.",
	"channel_details_desc": "The channel name cannot be changed after it has been created.",
	"invalid_characters": "Invalid characters",
	"channel_organizations_desc": "Because the console gives a single user the ability to own and control several organizations and identities, you must specify the organization and identity you are using when you sign the channel creation request.",
	"block_cutting_params_desc": "Use the fields on this panel to customize transaction batch size for this channel. These parameters control the number and size of transactions in a block. The timeout field specifies the amount of time to wait after the first transaction arrives for additional transactions before cutting a block. Modification of these values will affect the throughput and performance of your channel. [[Find out more here]](block_parameters_link).",
	"block_parameters_link": "https://hyperledger-fabric.readthedocs.io/en/latest/config_update.html",
	"ordering_service_organization_desc": "Specify an ordering service MSP to send this channel request to. The organization represented by this MSP must be one of the ordering administrators.",
	"review_create_channel_info_desc": "Review the channel information here before creating your channel.",
	"review_update_channel_info_desc": "Review the channel information here before updating your channel.",
	"channel_creator_identity": "Identity for organization creating channel",
	"channel_updator_identity": "Identity for organization updating channel",
	"application_channel_capability_version": "Application capability level",
	"orderer_channel_capability_version": "Orderer capability level",
	"review_orderer_error": "Navigate to the channel details section and fix the ordering service error before submitting the request.",
	"review_channel_name_error": "Navigate to the channel details section and fix error with the channel name before submitting the request.",
	"review_organization_error": "Navigate to the organizations section and fix error(s) in that section before submitting the request.",
	"review_policy_error": "Navigate to the policy section and fix error(s) in that section before submitting the request.",
	"review_creator_error": "Navigate to the organization creating channel section and fix error(s) in that section before submitting the request.",
	"review_identity_error": "Navigate to the organization creating channel section and fix error(s) in that section before submitting the request.",
	"override_defaults": "Override defaults",
	"override_raft_defaults": "Override defaults for ordering parameters",
	"bytes": "bytes",
	"review_consenter_error": "The consenters are missing or invalid. Navigate to the consenter set section and add valid consenters to continue.",
	"review_acl_error": "Navigate to the ACL section and fix error(s) in that section before submitting the request.",
	"review_orderer_msp_error": "Navigate to the ordering service organization section and fix error(s) in that section before submitting the request.",
	"review_application_capability_error": "Navigate to the capabilities section and select application capability before submitting the request.",
	"review_channel_capability_error": "Navigate to the capabilities section and select channel capability before submitting the request.",
	"review_orderer_capability_error": "Navigate to the capabilities section and select orderer capability before submitting the request.",
	"channel_configuration": "Channel configuration",
	"channel_capabilities_desc": "Capabilities ensure that tasks are handled deterministically by all of the nodes performing the task and must be coordinated with the Fabric version of the nodes in a channel. Note that in order to use the latest features, it may be necessary to upgrade to the latest Fabric version and set the latest level of capabilities.",
	"create_channel_signing_org_message": "The identity you specify must be an admin of the organization whose MSP you selected.",
	"config_override": "Configuration JSON",
	"edit_config_override": "Edit configuration JSON (Advanced)",
	"error_config_override_json": "The text is not valid JSON.",
	"current_config_json": "Current configuration",
	"config_override_json": "Configuration updates",
	"config_override_delta": "Only a subset of the fields in the Current configuration can be overridden. Paste in only the fields you want to update.",
	"_LINK_CA_CONFIG_OVERRIDE_UPDATE": "{{DOC_PREFIX}}?ibp-console-adv-deployment#ibp-console-adv-deployment-ca-modify-json",
	"_LINK_PEER_CONFIG_OVERRIDE_UPDATE": "{{DOC_PREFIX}}?ibp-console-adv-deployment#ibp-console-adv-deployment-peer-modify-json",
	"_LINK_ORDERER_CONFIG_OVERRIDE_UPDATE": "{{DOC_PREFIX}}?ibp-console-adv-deployment#ibp-console-adv-deployment-orderer-modify-json",
	"config_override_update_ca": "See [[Documentation]](_LINK_CA_CONFIG_OVERRIDE_UPDATE) for more information.",
	"config_override_update_peer": "See [[Documentation]](_LINK_PEER_CONFIG_OVERRIDE_UPDATE) for more information.",
	"config_override_update_orderer": "See [[Documentation]](_LINK_ORDERER_CONFIG_OVERRIDE_UPDATE) for more information.",
	"clear_prefix": "Clear prefix",
	"error_removing_enroll_ids": "There was an error removing the template enroll ids.",
	"error_downloading_enroll_ids": "There was an error downloading the template identities.",
	"template_orderer_timeout_message": "Could not communicate with the ordering Service",
	"retry_orderer_status_check": "Retry ordering service status check",
	"resume_template_deployment": "Resume deploying template",
	"template_requires_attention": "Template deployment requires attention",
	"ca_required": "Please create or import a Certificate Authority",
	"msp_required": "Please create or import an MSP definition",
	"no_ca_warning": "No Certificate Authorities available",
	"no_peer_warning": "No Peers available",
	"no_orderer_warning": "No Ordering services available",
	"no_msp_warning": "No MSPs available",
	"select_identity_description": "Select identity to sign the request",
	"msp_definition": "MSP Definition",
	"msp_definition_updates": "Orderer MSP definition updated for {{channel}}, including fields: {{fields}}",
	"go_further": "Go further",
	"api_doc_header": "API documentation",
	"api_doc_desc": "Build on top of the Fabric Operations Console",
	"search": "Search",
	"find_audit_logs": "Search activity logs",
	"error_component_delete_title": "Component delete failed",
	"error_component_delete": "An error occurred when deleting the component \"{{id}}\".",
	"error_component_removal_title": "Component removal failed",
	"error_component_removal": "Removal of the component \"{{id}}\" failed.",
	"error_tagged_component_removal_title": "Tagged component removal failed",
	"error_tagged_component_removal": "Removal of components with the tag \"{{tag}}\" failed.",
	"error_get_orderers_after_removal_title": "Could not get orderers",
	"error_get_orderers_after_removal": "The list of orderers could not be retrieved after removing orderer.",
	"error_remove_node_from_sys_channel_title": "System channel node remove failed",
	"error_remove_node_from_sys_channel": "The node could not be removed from the system channel.  Nodes must be removed from the system channel before they can be deleted.",
	"error_remove_from_sys_channel_os_identity": "The node could not be removed from the system channel.  An associated identity must be given for the ordering service.",
	"cancel_channel_creation": "Cancel channel creation",
	"empty_consortium_title": "No consortium members available",
	"empty_orderer_admins_title": "No ordering administrators available",
	"msp_def_details": "MSP definition details",
	"generate_msp_identity_tooltip": "Choose an existing identity",
	"review_msp_information": "Review MSP information",
	"do_not_associate": "Do not associate",
	"associate_orderer_desc": "Select an admin identity for each organization used by the ordering service.",
	"do_not_translate": "{{text}}",
	"consenter_mapping_required": "All of the ordering nodes that were used as consenters when the channel was created are no longer part of the channel.  Ensure that all of the peers that are joining the channel contain an address override from one of the original ordering nodes to one of the current ordering nodes.",
	"peer_mapping_saas": "The following managed peers may require an address override:",
	"peer_mapping_imported": "The following imported peers may require an address override:",
	"address_override_example": "Example override section to update the peer so that it can map the old orderer nodes to the new ones:",
	"in_val_generic_title": "Request input validation failed",
	"in_val_generic": "The request was not completed because {{violations}} input violations were detected.",
	"in_val_missing_required_title": "Missing required parameter",
	"in_val_missing_required": "Expected parameter '{{property_name}}' to exist.",
	"in_val_invalid_type_title": "Invalid parameter type",
	"in_val_invalid_type": "Expected parameter '{{property_name}}' to be of type: '{{property_type}}', but instead was: '{{type}}'.",
	"in_val_too_large_title": "Parameter too large",
	"in_val_too_large": "Parameter '{{property_name}}' is out of range. Must be <= {{max}}.",
	"in_val_too_small_title": "Parameter too small",
	"in_val_too_small": "Parameter '{{property_name}}' is out of range. Must be >= {{min}}.",
	"in_val_invalid_value_title": "Unexpected parameter value",
	"in_val_invalid_value": "Expected parameter '{{property_name}}' to be one of these values: {{values}}.",
	"in_val_illegal_value_title": "Illegal parameter value",
	"in_val_illegal_value": "Parameter '{{property_name}}' cannot be any of these values: {{values}}.",
	"in_val_illegal_key_title": "Illegal parameter key",
	"in_val_illegal_key": "Parameter '{{property_name}}' is automatically populated. It can not be set.",
	"in_val_illegal_key_edit_title": "Parameter illegal key edit",
	"in_val_illegal_key_edit": "Parameter '{{property_name}}' is either never editable or not editable via this api.",
	"in_val_str_too_small_title": "Parameter too short",
	"in_val_str_too_small": "Expected parameter '{{property_name}}' is too short. Must be >= {{min}} characters.",
	"in_val_str_too_large_title": "Parameter too long",
	"in_val_str_too_large": "Expected parameter '{{property_name}}' is too long. Must be <= {{max}} characters.",
	"in_val_invalid_certificate_title": "Invalid certificate parameter",
	"in_val_invalid_certificate": "Expected parameter '{{property_name}}' to be a Base64 encoded PEM-string.",
	"in_val_invalid_cpu_units_title": "Invalid CPU units",
	"in_val_invalid_cpu_units": "Parameter '{{property_name}}' must be a valid CPU value. Try values similar to '250m' or '0.25'.",
	"in_val_invalid_memory_units_title": "Invalid memory units",
	"in_val_invalid_memory_units": "Parameter '{{property_name}}' must be a valid memory value. Try values similar to '1GiB' or '1024MiB'.",
	"in_val_invalid_storage_units_title": "Invalid storage units",
	"in_val_invalid_storage_units": "Parameter '{{property_name}}' must be a valid storage value. Try values similar to '128GiB' or '131072MiB'.",
	"in_val_invalid_requests_for_limits_title": "Requests exceed limit",
	"in_val_invalid_requests_for_limits": "Parameter '{{property_name}}' is invalid. 'requests' must be <= 'limits'. requests: '{{requests}}', limits: '{{limits}}'.",
	"in_val_invalid_duration_units_title": "Invalid duration string",
	"in_val_invalid_duration_units": "Parameter '{{property_name}}' must be a valid duration string. Try values similar to '1h' or '3600s'.",
	"in_val_regex_fail_full_url_title": "Invalid URL",
	"in_val_regex_fail_full_url": "The field '{{property_name}}' must be a url including the protocol, hostname/ip and port.",
	"in_val_regex_invalid_msp_id_title": "Invalid MSP ID",
	"in_val_regex_invalid_msp_id": "The field '{{property_name}}' can only contain alphanumerics characters, dashes, and dots.",
	"in_val_regex_invalid_enroll_chars_title": "Invalid enrollment characters",
	"in_val_regex_invalid_enroll_chars": "The field '{{property_name}}' cannot contain white space or these characters :\\<>#{}%`[]\\^~|\"",
	"in_val_regex_fail_host_and_port_title": "Invalid host and port",
	"in_val_regex_fail_host_and_port": "The field '{{property_name}}' must be a url including the hostname/ip and port. Do not include the protocol.",
	"in_val_regex_fail_start_non_letter_title": "Invalid first letter",
	"in_val_regex_fail_start_non_letter": "The field '{{property_name}}' must start with a letter.",
	"in_val_regex_generic_error_title": "Malformed parameter",
	"in_val_regex_generic_error": "Parameter '{{property_name}}' is malformed.",
	"in_val_no_extra_keys_title": "Unknown parameter",
	"in_val_no_extra_keys": "Parameter '{{property_name}}' is an unknown key for object.",
	"in_val_missing_type_title": "Missing component type",
	"in_val_missing_type": "Expected the component 'type' to be in the body or route.",
	"in_val_invalid_query_param_arr_str_title": "Invalid query parameter",
	"in_val_invalid_query_param_arr_str": "Expected the query parameter '{{property_name}}' to be an array of strings. Try values similar to ?filter_orderers=['my-orderer.ibp.us-south.containers.appdomain.cloud']",
	"in_val_arr_too_small_title": "Query parameter too small",
	"in_val_arr_too_small": "Expected parameter '{{property_name}}' is too small. Array must have >= {{min}} element(s).",
	"in_val_arr_too_large_title": "Query parameter too large",
	"in_val_arr_too_large": "Expected parameter '{{property_name}}' is too large. Array must have <= {{max}} element(s).",
	"in_val_unsupported_query_title": "Unsupported query parameter",
	"in_val_unsupported_query": "The query parameter '{{property_name}}' is not supported by this route.",
	"in_val_unmatched_lengths_title": "Unmatched parameter lengths",
	"in_val_unmatched_lengths": "Expected array '{{property_name}}' to be the same length as '{{master_property}}' ({{len}} elements).",
	"in_val_invalid_combo_not_exist_title": "Incompatible parameters",
	"in_val_invalid_combo_not_exist": "Parameter '{{property_name}}' is incompatible with parameter '{{conflict_property}}'. Only one of these fields can exist.",
	"in_val_invalid_combo_missing_title": "Missing companion parameter",
	"in_val_invalid_combo_missing": "Parameter '{{property_name}}' is missing companion parameter '{{companion_property}}'. Both fields must exist.",
	"in_val_invalid_key_on_free_plan_title": "Paid-only parameter",
	"in_val_invalid_key_on_free_plan": "A *free* IBM Cloud Kubernetes cluster cannot use the parameter '{{property_name}}'.",
	"in_val_display_format": "{{title}}: {{message}}",
	"in_val_invalid_query_param_import_title": "Invalid query parameter",
	"in_val_invalid_query_param_import": "The query parameter '{{property_name}}' with value '{{value}}' is not applicable on imported components.",
	"error_component_create_title": "Could not create component",
	"error_component_create": "Component {{component}} could not be created.",
	"error_component_edit_title": "Could not edit component",
	"error_component_edit": "Component {{component}} could not be edited.",
	"error_component_import_title": "Could not import component",
	"error_component_import": "Component {{component}} could not be imported.",
	"identities_from_chosen_ca": "The following identities were created from the selected CA, {{name}}.",
	"use_default": "Use default",
	"selected_ca": "Selected CA",
	"panel": "panel",
	"data_table_toolbar": "data table toolbar",
	"remove_cert": "Remove certificate",
	"orderer_unavailable_consenter_update": "Please wait until the previous consenter has finished being added before attempting to add another consenter.",
	"label_name": "name",
	"label_value": "value",
	"delete_acl": "Delete ACL",
	"close_get_started_menu": "Close Getting started menu",
	"channel_orderer_not_imported_desc": "Please import the ordering service associated with the channel.",
	"imported": "Imported",
	"find_peers": "Find peers",
	"find_cas": "Find certificate authorities",
	"find_orderers": "Find ordering services",
	"orderer_admin_set": "Ordering administrators",
	"update_channel_admin_set_desc": "By adding and removing ordering service administrators from this channel, you can change the ordering service organizations who have the ability to sign channel updates that require the approval of the ordering service. Before a consenter can be added to the channel, the ordering service organization that owns it must be an administrator.",
	"update_channel_admin_set_desc2": "By adding and removing orderer MSPs from this channel, you can change the organizations who have the ability to sign off on edits to the channel's config-block 'Orderer' section. This section includes settings like the consenters set, orderer capabilities, and others.",
	"create_channel_orderer_org_desc": "Select the orderer MSPs that will govern this channel.\n\nThe orgs with an \"Administrator\" role are organizations who have the ability to sign off on edits to the channel's config-block 'Orderer' section. This section includes settings like the consenters set, orderer capabilities, and others. The default policy will allow any orderer admin to make admin level changes.",
	"channel_admin": "Administrators",
	"select_admin": "Select administrator",
	"channel_admins_empty_error": "At least one administrator must be specified.",
	"remove_consenter": "Remove consenter",
	"remove_orderer_admin": "Remove orderer admin",
	"build": "Build",
	"grow": "Grow",
	"current": "Current",
	"operate_and_govern": "Operate and govern",
	"getting_started_monitor_desc": "Add monitoring with Sysdig to keep track of your resources",
	"getting_started_monitor_title": "Monitor your resources",
	"getting_started_channel_docs_desc": "Deploy Hyperledger Fabric components in IBM Cloud",
	"getting_started_channel_docs_title": "Channel governance",
	"channelDocs": "{{DOC_PREFIX}}?topic=console-advanced-channel-deployment-management",
	"channel_doc_hlfoc": "{{DOC_PREFIX}}/create_channel/create_channel_overview.html",
	"getting_started_grow_desc": "Grow your network by inviting other organizations",
	"getting_started_grow_title": "Grow your network",
	"growDocs": "{{DOC_PREFIX}}?topic=console-managing-organizations",
	"hlfoc_arch": "{{DOC_PREFIX}}/architecture.html",
	"getting_started_grow_resources_desc": "Add worker nodes so you don't run out of compute",
	"getting_started_grow_resources_title": "Grow your resources",
	"growResourcesDocs": "{{DOC_PREFIX}}?topic=console-upgrading-deleting-deployed-nodes",
	"notification_count": "Notification count",
	"max_notification_count": "99+",
	"invalid_consenter_error": "One or more of the consenters is no longer valid.",
	"review_orderer_admin_error": "Navigate to the ordering administrators section and fix error(s) in that section before submitting the request.",
	"legacy_sc": "Fabric v1.4 (legacy)",
	"add_certificate": "Add certificate",
	"add_private_key": "Add Private key",
	"admin_certs_expiry": "Admin Cert Expiration",
	"tls_cert_expiry": "TLS Cert Expiration",
	"ecert_expiry": "Enrollment Cert Expiration",
	"tls_ca_root_cert_expiry": "TLS CA Root Cert Expiration",
	"show_cert_details": "Show details",
	"hide_cert_details": "Hide details",
	"cert_warning": "Certificate Expiration",
	"peer_warning_title": "Certificate Expiration Warning",
	"peer_warning_text": "The certificates used by the peer will expire soon and cannot be automatically renewed.",
	"orderer_warning_title": "Certificate Expiration Warning",
	"orderer_warning_text": "The certificates used by the orderer will expire soon and cannot be automatically renewed.",
	"cert_expiry_error": "Some of your peer and orderer enrollment certs are expiring within the next 60 days and those would be automatically renewed 30 days before expiration",
	"cert_update_expire_warn": "The TLS certificate of a component was recently updated. Remember to also update your admin and enrollment certificates before they expire. \n\n Certificates: \n {{upCompList}}",
	"certs_expire_details": "Remember the admin and enrollment certificates in your new component have expirations. You must plan to update them before they expire. \n\n Certificates: \n {{details}}",
	"use_default_consenters": "Include all orderer nodes from the system channel consenter set.",
	"use_default_consenters2": "Include all orderer nodes from the selected ordering organizations.",
	"chaincode_policy": "Chaincode policy",
	"channel_lifecycle_policy": "Lifecycle endorsement policy",
	"channel_endorsement_policy": "Smart contract endorsement policy",
	"lifecycle_policy_desc": "The lifecycle endorsement policy specified here is the criteria by which the organizations on the channel collaboratively decide to commit a smart contract definition. [[Find out more information here.]](_CHAINCODE_POLICY_LINK1)",
	"lifecycle_policy_tooltip": "Choose how many organizations must approve a smart contract definition before it can be committed to the channel.",
	"endorsement_policy_desc": "The endorsement policy specified here becomes the default smart contract endorsement policy for the channel, but can be overridden for a smart contract by specifying a different smart contract endorsement policy in the smart contract definition. [[Find out more information here.]](_CHAINCODE_POLICY_LINK1)",
	"_CHAINCODE_POLICY_LINK1": "{{DOC_PREFIX}}?topic=console-advanced-channel-deployment-management",
	"endorsement_policy_tooltip": "Choose how many organizations are required to endorse a smart contract transaction.",
	"chaincode_policy_majority": "Majority of the organizations must approve (default)",
	"chaincode_policy_all": "All channel organizations must approve",
	"chaincode_policy_any": "Any channel organization must approve",
	"chaincode_policy_specific": "Specific channel organizations must approve",
	"chaincode_policy_specific_label": "Channel organizations that must approve",
	"chaincode_specific_policy": "{{n}} out of {{members}}",
	"review_endorsement_policy_error": "Navigate to the Smart contract endorsement policy section and select policy before submitting the request.",
	"duplicate_mspid_error_title": "Duplicate MSP ID",
	"review_lifecycle_policy_error": "Navigate to the Lifecycle endorsement policy section and select policy before submitting the request.",
	"duplicate_mspid_error_desc": "Same MSP ID already exists for selected CA. Please enter a different MSP ID or choose another CA to continue.",
	"channel_warning_20_single_application": "Node {{nodes}} in the cluster is not at Fabric version v2.x. Upgrade this node to v2.x before updating the Application capability.",
	"channel_warning_20_single_orderer": "Node {{nodes}} in the cluster is not at Fabric version v2.x. Upgrade this node to v2.x before updating the Orderer capability.",
	"channel_warning_20_single_channel": "Node {{nodes}} in the cluster is not at Fabric version v2.x. Upgrade this node to v2.x before updating the Channel capability.",
	"channel_warning_20_multi_application": "Nodes {{nodes}} in the cluster are not at Fabric version v2.x. Upgrade all of the nodes to v2.x before updating the Application capability.",
	"channel_warning_20_multi_orderer": "Nodes {{nodes}} in the cluster are not at Fabric version v2.x. Upgrade all of the nodes to v2.x before updating the Orderer capability.",
	"channel_warning_20_multi_channel": "Nodes {{nodes}} in the cluster are not at Fabric version v2.x. Upgrade all of the nodes to v2.x before updating the Channel capability.",
	"channel_consenter_orderer_msp_tooltip": "Ordering service admins will be prompted with this field as the proposed channel configuration update requires their signature.",
	"select_orderer_msp": "Select Orderer MSP",
	"update_tls_certs": "Update orderer certificates",
	"update_consenter_application_channel_desc": "This action will update the orderer TLS certificate for {{name}} on channel {{channelId}} with the following TLS certificate:",
	"update_consenter_system_channel_desc": "This action will update the orderer TLS certificate for {{name}} on system channel with the following TLS certificate:",
	"tls_root_cert": "",
	"confirm_consenter_name": "Consenter Name",
	"unsupported_cluster": "Unsupported cluster",
	"unsupported_cluster_error": "Current cluster level {{clusterVersion}} isn't supported by IBP. Supported cluster level range is {{min}} - {{max}}. Update your cluster at the earliest to avoid service disruption. [[Find out more information here.]](_IKS_VERSION_LINK)",
	"_IKS_VERSION_LINK": "https://cloud.ibm.com/docs/containers?topic=containers-cs_versions",
	"update_consenter": "Update consenter",
	"consenter_certs": "Consenter Certs",
	"consenter_updates": "Consenter certs updated for {{channel}}",
	"update_consenter_confirm": "Please type the name of the consenter to confirm:",
	"confirm_remove_consenter_name_placeholder": "Enter the name of the consenter to be removed",
	"confirm_update_consenter_name_placeholder": "Enter the name of the consenter to be updated",
	"channel_warning_20_join_peer_application": "{{peer}} is at Fabric version {{peerVersion}} while the Application capability for the channel is at {{channelVersion}}. Ensure that the peer Fabric version is updated to v2.x before joining the channel.",
	"channel_warning_20_join_peer_orderer": "{{peer}} is at Fabric version {{peerVersion}} while the Orderer capability for the channel is at {{channelVersion}}. Ensure that the peer Fabric version is updated to v2.x before joining the channel.",
	"channel_warning_20_join_peer_channel": "{{peer}} is at Fabric version {{peerVersion}} while the Channel capability for the channel is at {{channelVersion}}. Ensure that the peer Fabric version is updated to v2.x before joining the channel.",
	"duplicate_msp_id_warning": "An organization with MSP ID \"{{msp_id}}\" has already been added. It is recommended that you do not use the same MSP ID.",
	"remove_ca_user": "Remove CA User",
	"remove_ca_user_desc": "This action will remove registered user {{enroll_id}} from the Certificate Authority. Use with caution. While the existing certificate and private key for this user continue to be valid and can be used to transact on the network, you will not be able to enroll or reenroll (renew) the certificate.",
	"remove_ca_user_confirm": "Please type the Enroll ID of the user to confirm:",
	"remove_ca_user_tooltip": "Remove registered user from CA",
	"delete_user": "Delete user",
	"identity_removal_disabled": "Identity removal is disabled for this Certificate Authority. Ensure that it is enabled by updating the CA configuration.",
	"duplicate_mspid_import_error_desc": "MSP already exists",
	"no_capability_changes": "The orderer already has the selected capabilities.",
	"in_val_too_many_keys": "Too many fields in body. This API only supports 1 field in the body. Use multiple apis to update multiple fields.",
	"smart_contract_20_lifecycle_notice_title": "Fabric v2.0 lifecycle is now available under channel details page",
	"smart_contract_20_lifecycle_notice_desc": "Good news. The process to deploy smart contracts on a channel has been updated to support the Fabric v2.0 lifecycle. You can continue to use this page to install and instantiate your legacy smart contracts in .cds format. Find out more about the new smart contract lifecycle with this how-to topic. If your channel application capability is set to v2.0, open the channel and select the Smart contract management tab to get started.",
	"how_to_topic": "How-to topic",
	"how_to_link": "{{DOC_PREFIX}}?topic=contracts-deploy-smart-fabric-v2x",
	"go_to_channel": "Go to channels page",
	"tooltip_manage_ecert": "Select Enroll certificate if the existing enrollment certificate has already expired. Otherwise select Re-enroll certificate.",
	"tooltip_manage_tls_cert": "Select Enroll certificate if the existing TLS certificate has already expired. Otherwise select Re-enroll certificate.",
	"channel_exists": "Channel is already created. Refresh the page to view the pending tile for the channel.",
	"all_cert_refresh_successful": "Certs for all nodes refreshed successfully.",
	"cert_refresh_successful": "Certs refreshed successfully.",
	"cert_refresh_error": "An error occurred when refreshing certs.",
	"refresh_certs": "Refresh Certs",
	"non_admin_identity": "A Non-Admin identity is associated.",
	"channel_nodeou_warning": "Please enable NodeOU for {{msps}} before updating channel capability to v2.x",
	"missing_endorsement_policy_title": "Endorsement policy missing for some organizations",
	"missing_endorsement_policy_desc": "The organization-level endorsement policies needed to run the v2.x smart contract lifecycle are missing from <strong>{{orgs}}</strong> in this Consortium. To add the endorsement policies, remove and then re-add the MSPs.",
	"peer_v1_v2_chaincode_title": "Existing Smart Contracts",
	"peer_v1_v2_chaincode_desc": "Existing smart contracts that are installed on the peer may need to be updated in order to run correctly after the upgrade.",
	"peer_v1_v2_chaincode_link": "{{DOC_PREFIX}}?topic=contracts-deploy-smart-fabric-v2x",
	"alert": "Alert",
	"import_only_peer": "Import peer",
	"import_only_ca": "Import Certificate Authority",
	"import_only_orderer": "Import ordering service",
	"mustgather_heading": "Mustgather",
	"mustgather_description": "Use the mustgather tool to collect configuration information and logs from your Fabric Console installation.",
	"mustgather_description_2": "To start the mustgather process use the buttons below. Once finished the collected information will be available to download.",
	"mustgather_description_3": "Note that the collected data is not persisted outside the mustgather pod and will not be available when the pod is deleted.",
	"mustgather_disabled": "The mustgather tool is disabled. See the documentation link above for how to enable this feature.",
	"mustgather_enabled": "The mustgather tool is enabled.",
	"mustgather_button_start": "Start mustgather",
	"mustgather_button_delete": "Delete mustgather pod and service",
	"mustgather_button_download": "Download results",
	"mustgather_button_restart": "Run again",
	"mustgather_button_retry": "Retry",
	"mustgather_error": "Error from mustgather:",
	"mustgather_last_run": "Last run:",
	"mustgather_timeout": "Mustgather tool has timed out. Please reload the page to retry.",
	"review_orderers": "Orderer Orgs",
	"transaction_identity": "Identity for join:",
	"join": "Join",
	"clusters": "Clusters",
	"skip": "Skip",
	"unselect_all": "Deselect all",
	"download_gen": "Download Config Block",
	"consenter": "Consenter",
	"follower": "Follower",
	"osnadmin_url": "OSN Admin url",
	"clusters_title": "Orderers to join '{{channel}}':",
	"already_joined": "Already joined",
	"not_joined": "Not joined",
	"failed_join": "Failed to join",
	"restarting": "Restarting...",
	"migration": "Migration",
	"migration_wiz_title": "Setup migration",
	"migration_why1": "IBM Blockchain Platform as a Service is being sunset on July 31, 2023. Users must migrate to [[IBM Support for Hyperledger Fabric]](https://www.ibm.com/docs/en/hlf-support/1.0.0) or unrecoverable data loss could occur.",
	"migration_what1": "The migration process will copy this console's data to a new console deployed on your cluster and update the base image of your Fabric nodes. There are no limitations or functional losses to your environment or capabilities.",
	"migration_details1": "This console is hosted by IBM Blockchain Platform. The new console will be hosted on your cluster, which also hosts your peers, orderers, and CAs.",
	"migration_details2": "You are currently logged into this console using IBMid credentials. New (local) credentials will be created for the new console using an email/password you define.",
	"migration_details2.1": "- If you are using console APIs for custom integrations the IBM IAM API keys used to authenticate will need to be changed to use [[local API keys]](https://www.ibm.com/docs/en/hlf-support/1.0.0?topic=console-administering-your#console-icp-manage-api-key).",
	"migration_details4": "Once migration is started this console will be put into a read-only state (this console will be unable to create or edit nodes/channels/chaincode).",
	"migration_details3": "The new console will use a self-signed TLS certificate to communicate with your web browser. On your first visit the browser will display a TLS warning. Follow the browser prompts to accept the new TLS certificate.",
	"migration_details6": "The name of this product will change from IBM Blockchain Platform to IBM Support for Hyperledger Fabric",
	"migration_details5": "- The documentation to reference will move from IBM Cloud to [[IBM Support for Hyperledger Fabric]](https://www.ibm.com/docs/en/hlf-support/1.0.0)",
	"migration_resources": "1.2 CPU - 2.25 GiB memory - 10 GiB storage",
	"migration_resources1": "The new console will consume an estimated 1.2 CPU, 2.25GiB memory and 10GiB storage from your cluster. However consoles managing many nodes may require more resources to perform smoothly.",
	"migration_resources2": "Before beginning the migration, verify that your cluster has enough free cpu, memory and storage.",
	"migration_min_versions_title": "Minimum Versions:",
	"migration_min_versions": "All Fabric versions and the cluster version must be equal to or higher than the versions listed below. These versions will be checked before migration begins. Note that Fabric nodes that were *imported* to this console are exempt.",
	"openshift_txt": "Red Hat OpenShift",
	"migration_fabric_ca": "Hyperledger Fabric CAs",
	"migration_fabric_peer": "Hyperledger Fabric Peers",
	"migration_fabric_2.2": "if at v2.2 or lower",
	"migration_fabric_2.4": "if at v2.4 or higher",
	"migration_fabric_orderer": "Hyperledger Fabric Orderers",
	"migration_k8s": "Kubernetes",
	"migration_start1": "To start click the \"Setup\" button below. Migration of your console will require about {{estimate}} minutes.",
	"migration_start1.1": "Once started most Fabric nodes will become unreachable, thus prepare for a blockchain outage for the duration of the migration. ",
	"migration_start1.2": "During migration you may close the browser or continue to use this console. However you will not be able to edit channels, chaincode, or Fabric nodes.",
	"migration_start2": "Migration consists of:",
	"migration_start_details1": "- Checking version compatibility",
	"migration_start_details2": "- Creating your new login credentials",
	"migration_start_details3": "- Redeploying Fabric nodes (CAs, orderers, and peers)",
	"migration_start_details4": "- Deploying a new console & copying console data",
	"migration_start_details5": "- Exporting your wallet and importing it on the new console",
	"migration_title": "Migration to IBM Support for Hyperledger Fabric is available",
	"migration_title_warn": "Migration to IBM Support for Hyperledger Fabric is required",
	"migration_title_error": "Action Required: Migrate to IBM Support for Hyperledger Fabric",
	"migration_action_button": "Migrate",
	"migration_done_title": "Congratulations! You have Migrated to IBM Support for Hyperledger Fabric",
	"migration_done_button": "Migration Details",
	"migration_console_title": "Console",
	"migration_completion_title": "This console is in read only mode",
	"migration_completion_subtitle": "This console has been migrated and is now in read only mode. You are no longer able to manage your nodes using this console. To manage your nodes use your new console.",
	"migration_outage_warning": "[Disruption Warning] Fabric nodes are unreachable during migration",
	"migration_completion_action": "Go to New Console",
	"migration_info": "Information",
	"migration_done": "Done",
	"migration_in-progress": "In progress",
	"migration_not-started": "Not started",
	"migration_failed": "Timed Out",
	"new_console_url": "new console URL:",
	"overall_status_title": "Overall Status:",
	"component_status_title": "Components Status:",
	"why_migrate_title": "Why migrate?",
	"what_changes_title": "What changes?",
	"mig_warnings": "Any warnings?",
	"what_prereq_title": "What are the prerequisites?",
	"mig_button_text": "Setup",
	"mig_header1": "Ready to migrate?",
	"mig_header_in_progress": "Status",
	"mig_details_title": "Details:",
	"mig_warning_txt": "- Note that there is breaking behavior when multiple IBP instances have been deployed to the same cluster. Migrating the first console will break Fabric functionality on the other consoles. The remaining consoles will no longer be able to show Fabric related content or perform Fabric functions, but will otherwise work. Migrate the remaining consoles and use the migrated (new) console to resolve this issue.",
	"mig_warning_txt2": "- Once migration has started Fabric nodes will become unreachable. You should expect a blockchain outage for the duration of migration.",
	"mig_tooltip1": "Please read the migration information above before starting migration. There are important considerations before starting.",
	"mig_tooltip_in_progress": "This page will report the status of your migration. You may leave this page or close your browser. Migration will continue in the background.",
	"mig_description": "Migration for your IBM Blockchain Platform console is enabled. Proceed after reading the information above by clicking \"Setup\".",
	"mig_description_in_progress": "Migration of your IBM Blockchain Platform console is in progress.",
	"mig_description_disabled": "Migration for your IBM Blockchain Platform console is not yet enabled. This feature is coming soon. Contact support if you have any questions.",
	"mig_complete": "Migration is complete",
	"mig_missing_perms": "You do not have permission to initiate migration. The IBM Cloud role of \"Manager\" is needed on this resource.",
	"mig_timed_out": "The migration attempt has timed out. Try again. Contact support if this message persists.",
	"mig_error": "The migration was not successful. You may try again. Contact support if this message persists.",
	"mig_error_msg": "Error\n    {{migration_error_msg}} \n    {{jupiter_error_msg}}",
	"mig_elapsed_msg": "Migration started {{time}} ago",
	"mig_in_progress": "Migration is in progress. It can take up to {{estimate}} minutes.",
	"start_migration_txt": "Start migration (~{{estimate}} mins)",
	"new_console_pass": "New console default password",
	"new_console_pass_tooltip": "This password will be used to login to your new console. This is also the default password for any new usernames until they change their password.",
	"new_console_pass_placeholder": "Enter a new password",
	"new_console_user": "New console username",
	"new_console_user_tooltip": "This username will be used to login to your new console. It has already been chosen and cannot be changed.",
	"migration_wiz_txt": "The new console will use local usernames and passwords for login. The migration process will create one \"Manager\" level user. Additional users can be created on the new console later. The password entered below is also the default password. Which means this password will also be used by new users on their first login to the new console.",
	"advanced_mig_options": "Advanced migration options",
	"new_console_pass_confirm": "Confirm default password",
	"new_console_pass_confirm_placeholder": "Enter the same password",
	"mig_option_clean": "Clean migration",
	"mig_option_clean_tooltip": "A clean migration will overwrite any previous migration console data from previous attempts.",
	"invalid_mig_versions_title": "Incompatible versions detected!",
	"invalid_mig_title": "Missing or expired tokens",
	"invalid_mig_title_free": "Invalid Cluster",
	"invalid_mig_fab_versions_txt": "One or more of your nodes is not using a Fabric version that can migrate. Update all the red nodes below to a newer version.",
	"invalid_mig_k8s_version_txt": "Your {{type}} cluster is not using a version that can migrate. Update the cluster to the newest version.",
	"invalid_mig_k8s_free_txt": "Migration is not available on clusters using the IKS free plan.",
	"invalid_mig_txt": "This error needs to be corrected before you can migrate.",
	"invalid_iam_tokens_txt": "IBM Cloud IAM tokens are required to migrate. They should be passed automatically, but may have expired. Try launching the console again from the [[IBM Cloud]](https://cloud.ibm.com/resources) resources page.",
	"migration_node_k8s_version_txt": "Kubernetes version:",
	"cluster": "Cluster",
	"deployed_nodes_title": "Deployed nodes",
	"migration_required_version_txt": "-> requires {{min}}+",
	"migration_version_valid_txt": "valid",
	"migration_nodes_not_found": "No deployed nodes were found",
	"checking_txt": "Checking migration compatibility...",
	"checking_txt_valid": "Prerequisites have been verified!",
	"checking_txt_valid2": "There's one more step before migrating. Choose a new password.",
	"how_migrate_title": "How do I migrate?",
	"mig_wallet_title": "It's now your turn",
	"mig_wallet_instructions": "The new console is ready. Migrate your wallet now.",
	"mig_wallet_instructions1.5": "The last step is to migrate your wallet (identities), which is a step you must do.",
	"mig_wallet_instructions2": "1. Go to the Settings tab (gear icon) on this console and click the blue \"Export\" button. Then select the \"Identities\" checkbox and unselect all others. Click \"Export\" and save this file to a secure location.",
	"mig_wallet_instructions3": "2. Next open a tab to your new console, login with your <strong>new</strong> username/password, go to the Settings tab and import the file from the previous step.",
	"migration_cleanup": "Migration cleanup",
	"mig_complete_txt": "Thank you for completing migration! Next is to verify the new console is working and then to delete this console.",
	"mig_warn_txt": "1. Verify that you and any users of your console have exported their wallets from this console and imported them to the new console. Below is a list of usernames that have exported their wallet. <strong>This console cannot tell if they are imported in the new console</strong>. Ask the users of your console if they have imported their wallet. Once this console is deleted, all wallets held within will be unrecoverable.",
	"mig_complete_txt2": "Visit your new console at ",
	"mig_exported_wallets": "Usernames that have exported their wallet:",
	"mig_exported_txt": "exported on",
	"mig_users_text": "2. (optional step) Additional local users can be created by using the \"Users\" tab on your new console. You should create a user on the new console for every active user of this console.",
	"mig_test_txt": "3. Next check if the new console is working. Open a new tab to the new console and click on each of the node tiles. You will need to associate each node with an appropriate identity from your wallet. Compare the Fabric related content seen on the new console with the content seen on this console, it should be identical. If the new console is missing content double check that the correct identity was associated and switch the identity if needed.",
	"mig_delete_txt": "4. If the new console is working well it's time to delete this console. Go to your [[IBM Cloud resources]](https://cloud.ibm.com/resources) dashboard, under \"Blockchain\" find the row for this console, and open it. You should be on the page which used to have the blue button that launches the console. This page should now have the text \"This console has been migrated!\". If so then it is safe to delete by using the \"Actions...\" dropdown and the \"Delete service\" option. If this text is missing contact support.",
	"audit_logs": "Audit",
	"audit_table_desc": "These logs form a record of many actions performed on the console by a console user or console API key. Most console activity is recorded such as user login/logout, node creation/deletion, enroll ID registration, channel creation and more.",
	"audit_table_tile_empty": "No results",
	"audit_table_text_empty": "No logs found",
	"audit_table_tooltip": "Log/event activity",
	"audit_logs_title": "Activity logs",
	"date": "Date",
	"log": "Log",
	"response_code": "Response code",
	"api_title": "HTTP Details",
	"outcome_title": "Outcome",
	"transaction_id_title": "Id",
	"log_title": "Activity log",
	"view_details": "View details",
	"by_title": "By",
	"tx_id_title": "API Id",
	"download_page_button_txt": "Export current page",
	"download_all_button_txt": "Export all",
	"copy_log_button_tooltip": "Copy log data to clipboard",
	"log_detail_desc": "The full details of this activity log are below.",
	"log_columns_desc": "Display",
	"audit_no_access_msg": "Only users with the \"Manager\" role can view this page.",
	"threat_message": "This service instance must be deleted by Oct 31, 2023 to avoid a blockchain network outage",
	"version_debug_msg": "Version summary",
	"version_debug_tooltip": "Use this tool to export a version summary which shows the versions of each of your component. This summary is helpful for debugging/support purposes.",
	"hide_archived_channels": "Hide Archived Channels",
	"show_archived_channels": "Show Archived Channels",
	"channel_not_found_under_orderer": "Channel '{{channelId}}' not found within selected orderer '{{ordererId}}'",
	"orderer_tls_admin_identity_not_found_in_selected_clusters": "Unable to join channel because cluster {{clusters}} does not have TLS Identity",
	"orderer_org_not_found_in_channel": "Orderer Org not found",
	"orderer_org_not_found_in_selected_nodes": "Unable to join channel because msp not exists for selected node {{nodes}}"
}
