{
	"mod_policy": "Admins",
	"policies": {
		"Admins": {
			"mod_policy": "Admins",
			"policy": {
				"type": 1,
				"value": {
					"identities": [
						{
							"principal": {
								"msp_identifier": "ORG_TEMPLATE_NAME",
								"role": "ADMIN"
							}
						}
					],
					"rule": {
						"n_out_of": {
							"n": 1,
							"rules": [
								{
									"signed_by": 0
								}
							]
						}
					}
				}
			}
		},
		"Readers": {
			"mod_policy": "Admins",
			"policy": {
				"type": 1,
				"value": {
					"identities": [
						{
							"principal": {
								"msp_identifier": "ORG_TEMPLATE_NAME"
							}
						}
					],
					"rule": {
						"n_out_of": {
							"n": 1,
							"rules": [
								{
									"signed_by": 0
								}
							]
						}
					}
				}
			}
		},
		"Writers": {
			"mod_policy": "Admins",
			"policy": {
				"type": 1,
				"value": {
					"identities": [
						{
							"principal": {
								"msp_identifier": "ORG_TEMPLATE_NAME"
							}
						}
					],
					"rule": {
						"n_out_of": {
							"n": 1,
							"rules": [
								{
									"signed_by": 0
								}
							]
						}
					}
				}
			}
		},
		"Endorsement": {
			"mod_policy": "Admins",
			"policy": {
				"type": 1,
				"value": {
					"identities": [
						{
							"principal": {
								"msp_identifier": "ORG_TEMPLATE_NAME",
								"role": "PEER"
							},
							"principal_classification": "ROLE"
						}
					],
					"rule": {
						"n_out_of": {
							"n": 1,
							"rules": [
								{
									"signed_by": 0
								}
							]
						}
					}
				}
			}
		}
	},
	"values": {
		"MSP": {
			"mod_policy": "Admins",
			"value": {
				"config": {
					"crypto_config": {
						"identity_identifier_hash_function": "SHA256",
						"signature_hash_family": "SHA2"
					},
					"name": "ORG_TEMPLATE_NAME",
					"admins": [],
					"intermediate_certs": [],
					"root_certs": [],
					"tls_root_certs": []
				}
			}
		}
	}
}
